import React, { memo } from "react";
import styles from "./RedeemGiftCardHeader.scss";
import { FormattedMessage } from "react-intl";
import { ReactComponent as MintrouteLogo } from "img/checkout_options/mintroute.svg";

const RedeemGiftCardHeader = () => (
  <div className={styles.title}>
    <MintrouteLogo className={styles.titleIcon} />
    <FormattedMessage id="buy-coins.gift-card" defaultMessage="Tango voucher" />
  </div>
);

export default memo(RedeemGiftCardHeader);
