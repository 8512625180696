import React, { memo, useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Avatar from "ui/common/avatar/Avatar";
import { makeLinkToProfile } from "ui/navigation/links";
import { AvatarSize, MiniProfileEntranceSource, ProfileType } from "src/enums";
import { profilesCacheSelectors } from "state/selectors";
import { RootState } from "state/delegate";
import { useProfileEvent } from "@analytics/profile/useProfileEvent";
import { emitOpenProfileEvent } from "@analytics/profile/emitOpenProfileEvent";
import { EventFields } from "@analytics/enums";
import styles from "./StreamerAvatar.scss";

interface StreamerAvatarProps {
  broadcasterId: string;
}

const StreamerAvatar: React.FC<StreamerAvatarProps> = ({ broadcasterId }) => {
  const basicProfile = useSelector(
    useCallback(
      (state: RootState) =>
        profilesCacheSelectors.getBasicProfile(state, broadcasterId),
      [broadcasterId]
    ),
    shallowEqual
  );

  const getProfileBIEvent = useProfileEvent(
    MiniProfileEntranceSource.PROFILE_ICON_AT_TAB,
    ProfileType.PROFILE
  );

  const onClick = useCallback(() => {
    emitOpenProfileEvent(
      getProfileBIEvent(broadcasterId, {
        [EventFields.FROM_MINI_PROFILE]: 0,
      })
    );
  }, [broadcasterId, getProfileBIEvent]);

  return (
    <div className={styles.root}>
      <Avatar
        to={makeLinkToProfile(broadcasterId, basicProfile)}
        size={AvatarSize.SMALL_PLUS}
        basicProfile={basicProfile}
        onClick={onClick}
      />
    </div>
  );
};

export default memo(StreamerAvatar);
