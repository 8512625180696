import React, { memo, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { RegistrationSource } from "@analytics/enums";
import { LoginModalTitleType } from "src/enums";
import { openLoginView } from "src/features/signin/exports/state/flows";
import { ComponentWithClassName } from "src/types/common";
import { loginSelectors } from "state/selectors";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import useEnableBroadcast from "ui/hooks/useEnableBroadcast";
import { linkToBroadcast } from "ui/navigation/links";
import { ReactComponent as CameraIcon } from "img/camera_on_small.svg";
import { ReactComponent as StartBroadcastIcon } from "img/nav/start_broadcast_active.svg";
import styles from "./StartBroadcastButton.scss";

interface WithTitleStartBroadcastProps {
  dataTestId?: string;
  forcePrimary?: boolean;
  size?: never;
  withTitle?: true;
}

interface CircleStartBroadcastProps {
  dataTestId?: string;
  forcePrimary?: boolean;
  size?:
    | ButtonSize.CIRCLE_BIG_48
    | ButtonSize.CIRCLE_EXTRABIG_56
    | ButtonSize.CIRCLE_MEDIUM_40;
  withTitle?: false;
}

const StartBroadcastButton: ComponentWithClassName<
  CircleStartBroadcastProps | WithTitleStartBroadcastProps
> = ({
  className,
  withTitle,
  size = ButtonSize.CIRCLE_EXTRABIG_56,
  dataTestId = "broadcast-button",
  forcePrimary = false,
}) => {
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const dispatch = useDispatch();
  const guardBroadcastLink = useCallback(
    (event) => {
      if (isLoggedIn) {
        return;
      }
      event.preventDefault();
      dispatch(
        openLoginView({
          registrationSource: RegistrationSource.START_STREAM,
          isShowPhoneNumberLoginFlow: false,
          title: LoginModalTitleType.BROADCAST,
        })
      );
    },
    [dispatch, isLoggedIn]
  );

  const broadcastEnabled = useEnableBroadcast();

  const isSecondary = !forcePrimary && size === ButtonSize.CIRCLE_MEDIUM_40;

  const btnConfig = useMemo(
    () =>
      withTitle
        ? ({
            size: ButtonSize.BIG_48,
            leftIcon: StartBroadcastIcon,
            children: (
              <FormattedMessage
                id="start.broadcast"
                defaultMessage="Start broadcast"
              />
            ),
          } as const)
        : ({
            variant: isSecondary
              ? ButtonVariant.SECONDARY
              : ButtonVariant.PRIMARY,
            active: isSecondary,
            size,
            children: isSecondary ? <CameraIcon /> : <StartBroadcastIcon />,
          } as const),
    [withTitle, size, isSecondary]
  );

  if (!broadcastEnabled) {
    return null;
  }

  return (
    <Button
      variant={isSecondary ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY}
      onClick={guardBroadcastLink}
      to={linkToBroadcast}
      className={classnames(
        styles.root,
        {
          [styles.shadow]: !withTitle && !isSecondary,
          [styles.secondary]: isSecondary,
        },
        className
      )}
      data-testid={dataTestId}
      active={isSecondary}
      {...btnConfig}
    />
  );
};

export default memo(StartBroadcastButton);
