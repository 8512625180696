import React, { FC, memo } from "react";
import classnames from "classnames";
import { HTMLDivProps } from "src/types/common";
import CircularProgress, {
  CircularProgressProps,
} from "ui/common/circularProgress/CircularProgress";
import styles from "./Spinner.scss";

interface SpinnerProps extends HTMLDivProps {
  circularProps?: CircularProgressProps;
  size?: number;
}

const Spinner: FC<SpinnerProps> = ({
  className,
  size,
  circularProps = {},
  ...rest
}) => (
  <div
    className={classnames(styles.root, className)}
    data-testid="spinner"
    {...rest}
  >
    <CircularProgress size={size} spinner {...circularProps} />
  </div>
);

export default memo(Spinner);
