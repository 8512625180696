import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import sortBy from "lodash.sortby";
import { useGenerateInteractionId } from "@analytics/AnalyticsUtils";
import {
  BI_LANDING_FIELD,
  EventFields,
  PERSONAL_OFFER_TYPE,
  PERSONAL_OFFERS_SOURCE,
  PURCHASE_ORIGIN,
  SCREEN_NAME,
} from "@analytics/enums";
import { MINTROUTE } from "enums/cashier";
import { Breakpoints, Currency } from "src/enums";
import { Nullable, VoidCallback } from "src/types/common";
import { PaymentType } from "src/types/payment";
import {
  getIsUseBasePriceFromOfferCurrentPriceEnabled,
  getIsVouchersPersonalOffersEnabled,
} from "state/abTests";
import { RootState } from "state/delegate";
import {
  loadMintrouteShopOffers,
  updateMintrouteSuspendedStatus,
} from "state/flows/mintroute";
import {
  loginSelectors,
  mintrouteShopSelectors,
  serverOwnedConfigSelectors,
} from "state/selectors";
import { themeLockClassnames } from "ui/Theme";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useCurrencies, { useCurrenciesWhiteList } from "ui/hooks/useCurrencies";
import usePersonalOffersState from "ui/hooks/usePersonalOffersState";
import PaymentMethodsInfo from "ui/modal/modalViews/buyCoins/PaymentMethodsInfo";
import PaymentMethodsResults from "ui/modal/modalViews/buyCoins/PaymentMethodsResults";
import PurchaseToasterContext from "ui/modal/modalViews/buyCoins/PurchaseToasterContext";
import { SUMMARY_STATE } from "ui/modal/modalViews/buyCoins/Summary";
import { ShopOffer } from "ui/scenes/landingPage/types";
import { toOffer } from "ui/scenes/landingPagePhoenix/landingPageMapperUtils";
import ApplyPinButton from "./ApplyPinButton";
import Footer from "./Footer";
import OfferPicker from "./OfferPicker";
import PinInput from "./PinInput";
import RedeemGiftCardHeader from "./RedeemGiftCardHeader";
import SuspendedView from "./SuspendedView";
import { ReactComponent as CloseIcon } from "img/close-rounded.svg";
import styles from "./RedeemGiftCardView.scss";

const SORT_OFFERS_BY = "price";

const selector = (state: RootState) => ({
  isLoggedIn: loginSelectors.isLoggedIn(state),
  oldOffers: mintrouteShopSelectors.getListOfPurchasableItems(state),
  isLoadingOffers: mintrouteShopSelectors.getIsLoadInProgress(state),
  isVouchersPersonalOffersEnabled: getIsVouchersPersonalOffersEnabled(state),
  isServerConfigReady: serverOwnedConfigSelectors.getIsServerConfigReady(state),
  suspended: mintrouteShopSelectors.getIsSuspended(state),
  isUseBasePriceFromOfferCurrentPriceEnabled:
    getIsUseBasePriceFromOfferCurrentPriceEnabled(state),
});

interface RedeemGiftCardProps {
  className: string;
  onClose: VoidCallback;
  purchaseSource: PURCHASE_ORIGIN | number;
}

const RedeemGiftCardView = ({
  className,
  onClose,
  purchaseSource,
}: RedeemGiftCardProps) => {
  const { showSuccessPurchaseToaster, showPendingPurchaseToaster } = useContext(
    PurchaseToasterContext
  );
  useGenerateInteractionId();
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();
  const isMobile = breakpoint !== Breakpoints.DESKTOP;
  const {
    isLoggedIn,
    oldOffers,
    isVouchersPersonalOffersEnabled,
    isServerConfigReady,
    isLoadingOffers,
    suspended,
    isUseBasePriceFromOfferCurrentPriceEnabled,
  } = useSelector(selector, shallowEqual);
  const [selectedOffer, setSelectedOffer] = useState<Nullable<ShopOffer>>(null);

  const { config } = usePersonalOffersState({
    viewType: MINTROUTE,
    isLoadingEnabled: isVouchersPersonalOffersEnabled,
  });

  const offers = useMemo(() => {
    if (isVouchersPersonalOffersEnabled) {
      return sortBy(config?.pricePoints, SORT_OFFERS_BY).map((pricePoint) =>
        toOffer({
          pricePoint,
          purchaseWithWheel: !!pricePoint.stwTemplate,
          personalOfferId: config?.personalOfferId,
          isUseBasePriceFromOfferCurrentPriceEnabled,
        })
      );
    }

    return oldOffers;
  }, [
    config,
    isVouchersPersonalOffersEnabled,
    oldOffers,
    isUseBasePriceFromOfferCurrentPriceEnabled,
  ]);

  const [summaryState, setSummaryState] = useState(
    offers?.length ? SUMMARY_STATE.SUMMARY : SUMMARY_STATE.PROCESSING
  );
  const [errorSummaryMessage, setErrorSummaryMessage] = useState();
  const [pin, setPin] = useState("");

  const btnConfig = useMemo(
    () =>
      isMobile
        ? {
            size: ButtonSize.MEDIUM_32,
            variant: ButtonVariant.ICON_ONLY,
          }
        : {
            size: ButtonSize.CIRCLE_BIG_48,
            variant: ButtonVariant.SECONDARY_ON_SHADER,
          },
    [isMobile]
  );

  useEffect(() => {
    if (offers?.length) {
      setSummaryState(SUMMARY_STATE.SUMMARY);
    }
  }, [isLoadingOffers]);

  useEffect(() => {
    if (isServerConfigReady && !isVouchersPersonalOffersEnabled) {
      dispatch(loadMintrouteShopOffers());
    }
  }, [isServerConfigReady, isVouchersPersonalOffersEnabled]);

  useLayoutEffect(() => {
    dispatch(updateMintrouteSuspendedStatus());
  }, [isLoggedIn]);

  const whitelist = useCurrenciesWhiteList(offers, selectedOffer);
  useCurrencies({ whitelist });

  const setSummaryStateFromPurchase = useCallback(
    (payload) => {
      if (typeof payload === "string") {
        setSummaryState(payload);
        setErrorSummaryMessage(undefined);
        if (payload === SUMMARY_STATE.SUCCESS) {
          showSuccessPurchaseToaster({
            onShow: onClose,
          });
        } else if (payload === SUMMARY_STATE.PENDING) {
          showPendingPurchaseToaster({
            onShow: onClose,
          });
        }
      } else {
        setSummaryState(payload.summaryType);
        setErrorSummaryMessage(payload.errorMessage);
      }
    },
    [onClose, showSuccessPurchaseToaster]
  );
  const redirectToSummary = useCallback(() => {
    setSummaryState(SUMMARY_STATE.SUMMARY);
  }, []);

  useEffect(() => {
    setSummaryState(SUMMARY_STATE.SUMMARY);
  }, [selectedOffer]);

  const analyticsParams = useMemo(
    () => ({
      [EventFields.SHARE_SOURCE_ID]: PERSONAL_OFFERS_SOURCE.MINTROUTE,
      [BI_LANDING_FIELD.TRIGGER_ID]: config?.triggerId,
      [EventFields.PERSONAL_OFFER_TYPE]: PERSONAL_OFFER_TYPE.PERSONAL_OFFER,
      [EventFields.SCREEN_NAME]: SCREEN_NAME.VOUCHERS,
      [BI_LANDING_FIELD.SERVER_OFFER_ID]: config?.personalOfferId,
      [BI_LANDING_FIELD.CURRENCY_CODE]: Currency.USD,
    }),
    [config]
  );

  return (
    <div
      className={classnames(
        styles.root,
        themeLockClassnames.dark,
        isMobile && styles.mobile,
        className
      )}
      data-testid="redeem-gift-card-view"
    >
      <div className={styles.content}>
        {suspended ? (
          <SuspendedView onClose={onClose} />
        ) : (
          <div className={styles.wrapper}>
            {isMobile && <RedeemGiftCardHeader />}
            <div className={styles.container}>
              {!isMobile && <RedeemGiftCardHeader />}
              <div className={styles.contentContainer}>
                <OfferPicker
                  className={classnames(
                    styles.offersPicker,
                    summaryState !== SUMMARY_STATE.SUMMARY && styles.none
                  )}
                  offers={offers}
                  selectedOffer={selectedOffer}
                  onOfferClick={setSelectedOffer}
                  isMobile={isMobile}
                  analyticsParams={analyticsParams}
                />
                <div className={classnames(styles.bottomPart)}>
                  <div
                    className={classnames(
                      styles.bottomPart,
                      summaryState !== SUMMARY_STATE.SUMMARY && styles.none
                    )}
                  >
                    {!!selectedOffer && (
                      <>
                        <PinInput
                          pin={pin}
                          setPin={setPin}
                          className={styles.pinInput}
                        />
                        <ApplyPinButton
                          className={styles.paymentButton}
                          offer={selectedOffer}
                          purchaseSource={purchaseSource}
                          pin={pin}
                          setPin={setPin}
                          summaryState={summaryState}
                          setSummaryState={setSummaryStateFromPurchase}
                          currency={Currency.USD}
                          analyticsParams={analyticsParams}
                        />
                      </>
                    )}
                    <PaymentMethodsInfo
                      optionType={PaymentType.MINTROUTE}
                      className={styles.paymentInfo}
                    />
                  </div>
                  <PaymentMethodsResults
                    redirectToSummary={redirectToSummary}
                    summaryState={summaryState}
                    setSummaryState={setSummaryStateFromPurchase}
                    errorSummaryMessage={errorSummaryMessage}
                    className={styles.paymentResults}
                    optionType={PaymentType.MINTROUTE}
                    isDark
                  />
                </div>
              </div>
              {isMobile && <Footer />}
            </div>
            {!isMobile && <Footer />}
          </div>
        )}
      </div>
      <Button {...btnConfig} onClick={onClose} className={styles.closeButton}>
        <CloseIcon />
      </Button>
    </div>
  );
};

export default RedeemGiftCardView;
