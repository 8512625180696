import React, { MouseEvent, memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { EventFields } from "@analytics/enums";
import { BottomScreenType, Breakpoints, ModalType } from "src/enums";
import { showBottomScreen } from "state/actionCreators/bottomScreen";
import { openModal } from "state/actionCreators/modal";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useUiAction from "ui/hooks/useUiAction";
import CardVideo from "ui/nft/cardVideo/CardVideo";
import { Card } from "ui/nft/types";
import styles from "./OwnedCards.scss";

interface OwnedCardsProps {
  cards: Card[];
  className?: string;
  onShader?: boolean;
  screenName?: string;
}

const breakpointToResolutionRate = {
  [Breakpoints.DESKTOP]: 2,
  [Breakpoints.TABLET]: 1,
  [Breakpoints.MOBILE]: 0,
  [Breakpoints.SMALL_MOBILE]: 0,
};

const OwnedCards = ({
  className,
  cards,
  screenName,
  onShader,
}: OwnedCardsProps) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();

  const openCardModal = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const id = event.currentTarget.dataset.id;
      const card = cards.find((c) => c.details.nft.id === id);

      dispatch(
        breakpoint === Breakpoints.MOBILE ||
          breakpoint === Breakpoints.SMALL_MOBILE
          ? // @ts-ignore
            showBottomScreen({
              screenType: BottomScreenType.NFT_CARD_BOTTOM_SCREEN,
              screenData: card,
              onDismiss: undefined,
              redirectOnDismiss: undefined,
            })
          : openModal({
              modalType: ModalType.NFT_CARD_MODAL,
              modalData: card,
            })
      );
    },
    [cards, breakpoint]
  );

  const handleCardClick = useUiAction({
    callback: openCardModal,
    target: "nft_card_full_view",
    additionalParams: (event) => ({
      [EventFields.ITEM_ID]: event.currentTarget.dataset.id,
      [EventFields.SCREEN_NAME]: screenName,
    }),
  });

  return (
    <div
      className={classnames(styles.root, styles[breakpoint], className)}
      data-testid="nft-cards-owned"
    >
      {cards.map((ownedCard) => (
        <div
          key={ownedCard.details.nft.id}
          className={classnames(styles.cardContainer, {
            [styles.shader]: onShader,
          })}
        >
          <CardVideo
            className={styles.card}
            resolutionRate={breakpointToResolutionRate[breakpoint]}
            media={ownedCard.details.nft.videos?.[0]}
            onClick={handleCardClick}
            data-id={ownedCard.details.nft.id}
          />
        </div>
      ))}
    </div>
  );
};

export default memo(OwnedCards);
