import React, { useCallback, memo } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { HTMLDivProps } from "src/types/common";
import styles from "./Tabs.scss";

interface TabConfig {
  id: string;
  message: MessageDescriptor;
  to: string;
  dataTestId?: string;
}

interface TabClasses {
  tab?: string;
  active?: string;
  inactive?: string;
}

interface TabProps extends TabConfig {
  onClick: (id: string) => void;
  tabClasses?: TabClasses;
  selected?: boolean;
}

interface TabsProps extends HTMLDivProps {
  tabsConfig: TabConfig[];
  activeId?: string;
  onItemSelected?: (id: string) => void;
  tabClasses?: TabClasses;
  showSeparator?: boolean;
}

const Tab: React.FC<TabProps> = ({
  message,
  selected,
  tabClasses = {},
  onClick,
  id,
  dataTestId,
  to,
}) => {
  const activeTabClassName = tabClasses.active || styles.textActive;
  const inactiveTabClassName = tabClasses.inactive || styles.textInactive;
  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);
  return (
    <Link
      className={classnames(
        tabClasses.tab,
        selected ? activeTabClassName : inactiveTabClassName
      )}
      to={to}
      onClick={handleClick}
      data-testid={dataTestId}
    >
      <FormattedMessage {...message} />
    </Link>
  );
};

const MemoizedTab = memo(Tab);

const Tabs: React.FC<TabsProps> = ({
  className,
  tabsConfig,
  activeId,
  onItemSelected = emptyFunction,
  tabClasses,
  showSeparator = true,
  ...rest
}) => (
  <div className={classnames(styles.root, className)} {...rest}>
    {showSeparator && <div className={styles.separator} />}
    {tabsConfig.map(({ id, message, to, dataTestId }) => (
      <MemoizedTab
        key={id}
        id={id}
        message={message}
        selected={id === activeId}
        onClick={onItemSelected}
        to={to}
        tabClasses={tabClasses}
        data-testid={dataTestId}
      />
    ))}
  </div>
);

export default memo(Tabs);
