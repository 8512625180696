import React, { FC } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { WatermarkComponentProps } from "ui/common/personalOffer/Watermark/Watermark";
import imStarsLeft from "src/img/watermarks/stars-left.png";
import imStarsRight from "src/img/watermarks/stars-right.png";
import styles from "./RegularPopularWatermark.scss";

const RegularPopularWatermark: FC<WatermarkComponentProps> = ({
  size,
  className,
}) => (
  <div className={classnames(className, styles.root, styles[size])}>
    <img src={imStarsLeft} className={styles.starsLeft} alt="stars" />
    <img src={imStarsRight} className={styles.starsRight} alt="stars" />
    <FormattedMessage id="buy-coins.ribbon.popular" defaultMessage="POPULAR" />
  </div>
);

export default RegularPopularWatermark;
