import React, { forwardRef, memo } from "react";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";

const GrowingTextArea = forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>(
  (props, ref) => (
    <TextareaAutosize ref={ref} tabIndex={1} maxRows={4} {...props} />
  )
);

GrowingTextArea.displayName = "GrowingTextArea";

export default memo(GrowingTextArea);
