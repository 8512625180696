import React, { ChangeEvent, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import { Nullable } from "src/types/common";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import DeleteAccountReasonOption from "ui/common/deleteAccountView/DeleteAccountReasonOption";
import {
  deleteAccountReasonDescriptionMaxLength,
  deleteAccountReasonOptions,
} from "ui/common/deleteAccountView/constants";
import { DeleteAccountReasonType } from "ui/common/deleteAccountView/enums";
import { deleteAccountReasonMessages } from "ui/common/deleteAccountView/messages";
import { DeleteAccountRequest } from "ui/common/deleteAccountView/types";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import { ReactComponent as DeleteIcon } from "img/ic_delete.svg";
import styles from "ui/common/deleteAccountView/DeleteAccountReasonView.scss";

interface DeleteAccountReasonViewProps {
  onCancel: VoidFunction;
  onSubmit: (deleteAccountRequest: DeleteAccountRequest) => void;
}

const DeleteAccountReasonView = (props: DeleteAccountReasonViewProps) => {
  const { onSubmit, onCancel } = props;

  const breakpoint = useBreakpoint();
  const { formatMessage } = useIntl();

  const [reason, setReason] = useState<Nullable<DeleteAccountReasonType>>(null);
  const [description, setDescription] = useState("");

  const handleReasonChange = useCallback((reason: DeleteAccountReasonType) => {
    setReason(reason);
    setDescription("");
  }, []);

  const handleDescriptionChange = useCallback(
    (event: ChangeEvent<{ value: string }>) =>
      setDescription(event.target.value),
    []
  );

  const handleSubmit = useCallback(() => {
    if (!reason) return;

    const deleteAccountRequest: DeleteAccountRequest = {
      reason,
      delayed: false,
    };

    if (description) {
      deleteAccountRequest.description = description;
    }

    onSubmit(deleteAccountRequest);
  }, [reason, description, onSubmit]);

  return (
    <div data-testid="delete-account-reason-view">
      <Typography type={TYPOGRAPHY_TYPE.HEADLINE2} className={styles.title}>
        <FormattedMessage {...deleteAccountReasonMessages.header} />
      </Typography>

      <div className={classNames(styles.controlsContainer, styles[breakpoint])}>
        {deleteAccountReasonOptions.map((option) => (
          <DeleteAccountReasonOption
            key={option.type}
            option={option}
            checked={reason === option.type}
            className={styles.control}
            onChange={handleReasonChange}
          />
        ))}

        {reason === DeleteAccountReasonType.OTHER && (
          <input
            className={classNames(styles.control, styles.nativeInput)}
            maxLength={deleteAccountReasonDescriptionMaxLength}
            placeholder={formatMessage(
              deleteAccountReasonMessages.otherInputPlaceholder
            )}
            onChange={handleDescriptionChange}
          />
        )}
      </div>

      <div className={classNames(styles.buttonsContainer, styles[breakpoint])}>
        <Button
          size={ButtonSize.BIG_48}
          variant={ButtonVariant.PRIMARY}
          leftIcon={DeleteIcon}
          className={styles.button}
          onClick={handleSubmit}
        >
          <FormattedMessage {...deleteAccountReasonMessages.submitButton} />
        </Button>

        <Button
          size={ButtonSize.BIG_48}
          variant={ButtonVariant.SECONDARY}
          className={styles.button}
          onClick={onCancel}
        >
          <FormattedMessage {...sharedMessages.cancel} />
        </Button>
      </div>
    </div>
  );
};

export default DeleteAccountReasonView;
