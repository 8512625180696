import React, { memo, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { SCREEN_STATES } from "@analytics/enums";
import { Breakpoints } from "src/enums";
import CarouselButton from "ui/common/buttons/CarouselButton";
import useBetLotAction from "ui/hooks/useBetLotAction";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useMedia from "ui/hooks/useMedia";
import AuctionCard from "ui/nft/auctionCard/AuctionCard";
import { Card as CardType, Lot } from "ui/nft/types";
import Card from "./Card";
import styles from "./CardsCarousel.scss";

interface CardsCarouselProps {
  accountId: string;
  creatorCards: CardType[] | undefined;
  forceMobileLayout?: boolean;
  lots: Lot[];
  onShader?: boolean;
  screenName?: string;
  uiComponent?: string;
}

const CardsCarousel = ({
  forceMobileLayout,
  lots,
  creatorCards,
  accountId,
  onShader,
  screenName,
  uiComponent,
}: CardsCarouselProps) => {
  const breakpoint = useBreakpoint();
  const location = useLocation<{ source?: string }>();
  const isMobile = breakpoint !== Breakpoints.DESKTOP;
  const isSmallDesktop = useMedia(["(max-width: 1280px)"], [true], false);
  const isCarouselEnabled = lots.length + (creatorCards?.length || 0) > 1;
  const [viewportRef, embla] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    watchDrag: (isMobile || forceMobileLayout) && isCarouselEnabled,
  });

  const onBet = useBetLotAction();

  const handleBet = useCallback(
    (data) =>
      onBet({
        ...data,
        screenName,
        source: location.state?.source,
        screenState: forceMobileLayout
          ? SCREEN_STATES.MINI_VIEW
          : SCREEN_STATES.FULL_VIEW,
      }),
    [location, screenName, onBet]
  );

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  useEffect(() => {
    if (!embla || isMobile || forceMobileLayout) {
      return;
    }

    const updateEmblaNavigation = () => {
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    };

    embla.on("select", updateEmblaNavigation);
    embla.on("reInit", updateEmblaNavigation);

    updateEmblaNavigation();

    return () => {
      embla.off("select", updateEmblaNavigation);
      embla.on("reInit", updateEmblaNavigation);
    };
  }, [embla, isMobile, forceMobileLayout]);

  return (
    <div
      className={classnames(
        styles.root,
        forceMobileLayout
          ? styles.mobileLayoutForced
          : {
              [styles[breakpoint]]: true,
              [styles.smallDesktop]: !isMobile && isSmallDesktop,
            }
      )}
      data-testid="nft-cards-carousel"
    >
      <div className={styles.cardsContainer} ref={viewportRef}>
        <div className={styles.cards}>
          {lots?.map((lot) => (
            <AuctionCard
              className={styles.card}
              lotId={lot.id}
              key={lot.id}
              onBet={handleBet}
              uiComponent={uiComponent}
              screenName={screenName}
              isCreatorVisible={false}
              accountId={accountId}
              forceMobileLayout={forceMobileLayout}
              onShader={onShader}
            />
          ))}
          {creatorCards?.map((card) => (
            <Card
              key={card.details.nft.id}
              className={styles.card}
              data={card}
              screenName={screenName}
              onShader={onShader}
              forceMobileLayout={forceMobileLayout}
            />
          ))}
        </div>
      </div>
      {!isMobile && !forceMobileLayout && (
        <>
          {prevBtnEnabled && (
            <CarouselButton
              type="prev"
              onClick={scrollPrev}
              className={styles.btnPrev}
            />
          )}
          {nextBtnEnabled && (
            <CarouselButton
              type="next"
              onClick={scrollNext}
              className={styles.btnNext}
            />
          )}
        </>
      )}
    </div>
  );
};

export default memo(CardsCarousel);
