import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { loginSelectors } from "state/selectors";
import Divider from "ui/common/divider/Divider";
import AuthorizedUserItems from "ui/common/dropdownMenu/menuList/components/AuthorizedUserItems";
import CustomerSupportItem from "ui/common/dropdownMenu/menuList/components/CustomerSupportItem";
import useWindowDimensions from "ui/hooks/useWindowDimensions";
import styles from "ui/common/dropdownMenu/menuList/MenuList.scss";

interface MenuListProps {
  breakpoint: Breakpoints;
}

const AuthorizedExploreNavigationItems: React.FC<MenuListProps> = ({
  breakpoint,
}) => {
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const { height } = useWindowDimensions();

  return (
    <>
      <div
        className={classnames({
          [styles.menuItemsWrap]: isDesktop && height <= 700,
        })}
      >
        {isLoggedIn && <AuthorizedUserItems />}
        <CustomerSupportItem />
      </div>
      <Divider />
    </>
  );
};

export default AuthorizedExploreNavigationItems;
