import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import { useRect } from "@reach/rect";
import classnames from "classnames";
import { Nullable } from "src/types/common";
import { useBreakpoint } from "src/ui/hooks/useBreakpoint";
import useWindowScroll from "ui/hooks/useWindowScroll";
import styles from "./ColoredTabs.scss";

interface TabState {
  left: number;
  to: string;
  width: number;
}

interface TabConfig {
  color: string;
  dataTestId?: string;
  id: string;
  message: MessageDescriptor;
  to: string;
}

interface TabProps extends Omit<TabConfig, "id"> {
  isActiveTab: boolean;
  setTabsState: (tabState: TabState) => void;
}

interface ColoredTabsProps {
  className?: string;
  config: TabConfig[];
}

const Tab: React.FC<TabProps> = ({
  to,
  message,
  setTabsState,
  color,
  isActiveTab,
  ...rest
}) => {
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const rect = useRect(ref);
  const match = useRouteMatch(to);
  const active = !!match;

  useLayoutEffect(() => {
    if (active && rect) {
      setTabsState({
        left: rect.left,
        width: rect.width,
        to,
      });
    }
  }, [rect?.left, rect?.width, active, to]);

  return (
    <Link
      className={classnames(styles.link, {
        [styles.activeLink]: isActiveTab,
      })}
      to={to}
      {...rest}
    >
      <div ref={ref}>
        <FormattedMessage {...message} />
      </div>
    </Link>
  );
};

const ColoredTabs: React.FC<ColoredTabsProps> = ({ className, config }) => {
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const rect = useRect(ref);
  const breakpoint = useBreakpoint();
  const { scrollTo } = useWindowScroll();

  const [activeTabState, setActiveTabState] =
    useState<Nullable<TabState>>(null);

  useLayoutEffect(() => {
    if (activeTabState) {
      scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [activeTabState?.to]);

  return (
    <div className={classnames(className, styles.root, styles[breakpoint])}>
      <div className={styles.content} ref={ref}>
        {config.map(({ to, dataTestId, message, color, id }) => (
          <Tab
            key={id}
            to={to}
            setTabsState={setActiveTabState}
            data-testid={dataTestId}
            message={message}
            color={color}
            isActiveTab={activeTabState?.to === to}
          />
        ))}
      </div>
      {activeTabState?.left != null && activeTabState?.width != null && (
        <div
          className={styles.activeIndicator}
          style={{
            background: styles.activeIndicator,
            left: activeTabState.left - (rect ? rect.left : 0),
            width: activeTabState.width,
          }}
        />
      )}
    </div>
  );
};

export default memo(ColoredTabs);
