import React, { useCallback } from "react";
import { VoidCallback } from "src/types/common";
import Button from "ui/common/button/Button";
import { ButtonProps } from "ui/common/button/types";
import useShowGiftsDrawer from "ui/hooks/useShowGiftsDrawer";

interface GiftActionButtonProps {
  disabled?: boolean;
  onClick?: VoidCallback;
}
const GiftActionButton: React.FC<ButtonProps & GiftActionButtonProps> = ({
  children,
  onClick,
  disabled = false,
  ...rest
}) => {
  const showGiftsDrawer = useShowGiftsDrawer();

  const handleClick = useCallback(() => {
    onClick?.();
    showGiftsDrawer();
  }, [showGiftsDrawer]);

  return (
    <Button
      disabled={disabled}
      onClick={disabled ? undefined : handleClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default GiftActionButton;
