import React, { FC } from "react";
import { useSelector } from "react-redux";
import { getIsBannersOnPersonalOffersEnabled } from "state/abTests";
import { Breakpoints } from "src/enums";
import { BannerType } from "src/types/banner";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import BannersByType from "ui/common/banner/BannersByType";
import styles from "./PersonalOfferBannersDesktop.scss";

interface PersonalOfferBannersDesktopProps {
  isCashier: boolean;
  onBannersReady?: VoidFunction;
}
export const PersonalOfferBannersDesktop: FC<
  PersonalOfferBannersDesktopProps
> = ({ isCashier, onBannersReady }) => {
  const isDesktop = useBreakpoint() === Breakpoints.DESKTOP;
  const isBannersEnabled = useSelector(getIsBannersOnPersonalOffersEnabled);

  if (!isDesktop || !isBannersEnabled) {
    return null;
  }

  return (
    <BannersByType
      type={isCashier ? BannerType.CASHIER_NEW : BannerType.REFILL}
      onBannersReady={onBannersReady}
      className={styles.banners}
      classes={{
        ConfiguredBanner: {
          root: styles.banner,
        },
      }}
    />
  );
};

PersonalOfferBannersDesktop.displayName = "PersonalOfferBannersDesktop";
