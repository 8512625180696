import React, { Dispatch, SetStateAction, useCallback } from "react";
import { batch, useDispatch } from "react-redux";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { Text } from "src/features/getTheApp/components/Text";
import { ComponentWithClassName, VoidCallback } from "src/types/common";
import { saveSettings } from "state/flows/settings";
import { actionCreators as deviceInfoActionCreators } from "state/tree/deviceInfo";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import LanguageMenuItem from "ui/common/dropdownMenu/menuList/components/languageSwitcher/LanguageMenuItem";
import { useLanguagesList } from "ui/common/dropdownMenu/menuList/components/languageSwitcher/useLanguagesList";
import { menuMessages } from "ui/common/dropdownMenu/menuList/messages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import useExclusiveClickHandler from "ui/hooks/useExclusiveClickHandler";
import { ReactComponent as BackIcon } from "img/user_menu_icons/chevron-left.svg";
import styles from "ui/common/dropdownMenu/menuList/components/languageSwitcher/LanguageMenu.scss";

interface LanguageMenuListMenuListProps {
  breakpoint: Breakpoints;
  closeMenu: VoidCallback;
  setIsLanguageActive: Dispatch<SetStateAction<boolean>>;
}

const LanguageMenuList: ComponentWithClassName<
  LanguageMenuListMenuListProps
> = ({ closeMenu, className, breakpoint, setIsLanguageActive }) => {
  const { locale, options } = useLanguagesList();
  const dispatch = useDispatch();
  const closeLanguageMenu = useExclusiveClickHandler(() =>
    setIsLanguageActive(false)
  );
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  const onLanguageChange = useCallback(
    (option) => {
      batch(() => {
        dispatch(deviceInfoActionCreators.setLocale(option.value));
        dispatch(saveSettings({ locale: option.value }));
      });
    },
    [dispatch]
  );

  return (
    <Typography
      type={isDesktop ? TYPOGRAPHY_TYPE.PARAGRAPH2 : TYPOGRAPHY_TYPE.PARAGRAPH1}
      onClick={isDesktop ? closeMenu : undefined}
      className={classnames(
        styles.root,
        styles[breakpoint],
        styles.right,
        className
      )}
      as="div"
      data-testid="explore-menu-languages-list"
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Button
            className={styles.back}
            size={ButtonSize.MEDIUM_32}
            variant={ButtonVariant.ICON_ONLY}
            onClick={closeLanguageMenu}
            data-testid="languages-back"
          >
            <BackIcon />
          </Button>
          <div className={styles.title}>
            <Text
              message={menuMessages.language}
              type={TYPOGRAPHY_TYPE.ALL_CAPS}
            />
          </div>
        </div>

        <div className={styles.options}>
          {options.map((option) => (
            <LanguageMenuItem
              key={option.value}
              option={option}
              onClick={onLanguageChange}
              isSelected={option.value === locale}
            />
          ))}
        </div>
      </div>
    </Typography>
  );
};

export default LanguageMenuList;
