import React, {
  useRef,
  useEffect,
  useMemo,
  MutableRefObject,
  memo,
  ForwardedRef,
} from "react";
import lottie, { AnimationItem } from "lottie-web";
import {
  VoidCallback,
  LottieConfigWithPathOrData,
  HTMLDivProps,
  Nullable,
} from "src/types/common";

export interface LottieAnimationProps extends HTMLDivProps {
  lottieConfig: LottieConfigWithPathOrData;
  onStart?: VoidCallback;
  onComplete?: VoidCallback;
  forwardedRef?: ForwardedRef<HTMLDivElement>;
  getInstance?: (instance: Nullable<AnimationItem>) => void;
}

// http://airbnb.io/lottie/#/web?id=usage
const LottieAnimation: React.FC<LottieAnimationProps> = ({
  lottieConfig,
  onComplete,
  onStart,
  forwardedRef,
  getInstance,
  ...rest
}) => {
  const animationContainerRef = useRef<HTMLDivElement>(null);
  const resolvedRef = useMemo(
    () => forwardedRef || animationContainerRef,
    [forwardedRef]
  );
  useEffect(() => {
    if ((resolvedRef as MutableRefObject<HTMLDivElement>).current) {
      const animation = lottie.loadAnimation({
        ...lottieConfig,
        container: (resolvedRef as MutableRefObject<HTMLDivElement>).current,
      });
      getInstance?.(animation);
      if (onComplete) {
        animation.addEventListener("complete", onComplete);
      }

      if (onStart) {
        animation.addEventListener("DOMLoaded", onStart);
      }
      return () => {
        getInstance?.(null);
        if (onStart) {
          animation.removeEventListener("DOMLoaded", onStart);
        }
        if (onComplete) {
          animation.removeEventListener("complete", onComplete);
        }
        animation.destroy();
      };
    }
  }, [lottieConfig, getInstance]);
  return <div ref={resolvedRef} {...rest} />;
};

export default memo(LottieAnimation);
