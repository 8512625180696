import React, {
  MouseEvent,
  MutableRefObject,
  memo,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { useRect } from "@reach/rect";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { Breakpoints } from "src/enums";
import { Nullable } from "src/types/common";
import { useBreakpoint } from "src/ui/hooks/useBreakpoint";
import useWindowScroll from "ui/hooks/useWindowScroll";
import styles from "./CenteredTabs.scss";

interface TabState {
  left: number;
  to: string;
  width: number;
}

interface TabConfig {
  activeIcon?: string;
  "data-testid"?: string;
  id: string;
  inactiveIcon?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  message: MessageDescriptor;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

interface TabProps
  extends Omit<TabConfig, "activeIcon" | "inactiveIcon" | "message"> {
  setTabsState: (tabState: TabState) => void;
  useTheme?: boolean;
}

export interface CenteredTabsProps {
  activeIndicatorColor?: string;
  className?: string;
  config: TabConfig[];
  postsFeedPanelRef: MutableRefObject<HTMLDivElement>;
  useTheme?: boolean;
  withLine?: boolean;
}

const Tab: React.FC<TabProps> = ({
  id,
  children,
  setTabsState,
  isActive = false,
  isDisabled = false,
  onClick,
  useTheme = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRect(ref);

  useLayoutEffect(() => {
    if (isActive && rect) {
      setTabsState({
        left: rect.left,
        width: rect.width,
        to: id,
      });
    }
  }, [isActive, rect?.left, rect?.width]);

  return (
    <div
      className={classnames(
        styles.link,
        useTheme && styles.themeDefault,
        isActive && styles.active,
        isDisabled && styles.disabled
      )}
      onClick={isDisabled ? emptyFunction : onClick}
      data-testid={`link-to-${id}`}
    >
      <div className={styles.tab} ref={ref}>
        {children}
      </div>
    </div>
  );
};

const CenteredTabs: React.FC<CenteredTabsProps> = ({
  className,
  config,
  postsFeedPanelRef,
  activeIndicatorColor = "",
  withLine = false,
  useTheme = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRect(ref);
  const breakpoint = useBreakpoint();
  const { scrollToTop } = useWindowScroll();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  const [activeTabState, setActiveTabState] =
    useState<Nullable<TabState>>(null);

  useLayoutEffect(() => {
    if (!activeTabState) {
      return;
    }

    if (isDesktop && postsFeedPanelRef?.current) {
      postsFeedPanelRef?.current.scrollTo(0, 0);
    } else {
      scrollToTop();
    }
  }, [activeTabState, postsFeedPanelRef?.current]);

  if (!config || !config.length) {
    return null;
  }

  return (
    <>
      <div className={classnames(className, styles[breakpoint])}>
        <div className={styles.content} ref={ref}>
          {config.map(
            ({
              id,
              message,
              isActive,
              isDisabled,
              onClick,
              activeIcon,
              inactiveIcon,
              ...rest
            }) => (
              <Tab
                key={id}
                id={id}
                isActive={isActive}
                isDisabled={isDisabled}
                setTabsState={setActiveTabState}
                onClick={onClick}
                useTheme={useTheme}
                {...rest}
              >
                {isActive
                  ? activeIcon && (
                      <img className={styles.icon} src={activeIcon} alt="" />
                    )
                  : inactiveIcon && (
                      <img className={styles.icon} src={inactiveIcon} alt="" />
                    )}
                <FormattedMessage {...message} />
              </Tab>
            )
          )}
        </div>
        {withLine && <div className={styles.line} />}
        {activeTabState?.left != null && activeTabState?.width != null && (
          <div
            className={classnames(
              styles.activeIndicator,
              activeIndicatorColor && styles[activeIndicatorColor]
            )}
            style={{
              left: activeTabState.left - (rect ? rect.left : 0),
              width: activeTabState.width,
            }}
          />
        )}
      </div>
      <div className={styles.placeholder} />
    </>
  );
};

export default memo(CenteredTabs);
