import React, { memo, MouseEvent } from "react";
import classnames from "classnames";
import { ReactComponent as ViewersDotIcon } from "img/ic_dot.svg";
import styles from "./LiveButton.scss";

const liveTitle = "live";

interface LiveButtonProps {
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const LiveButton: React.FC<LiveButtonProps> = ({ className, onClick }) => (
  <button
    onClick={onClick}
    data-testid="live"
    className={classnames(styles.isLive, className)}
  >
    <ViewersDotIcon />
    {liveTitle}
  </button>
);

export default memo(LiveButton);
