import React, { Children, ReactNode, forwardRef, useCallback } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { mobileTypes } from "src/constants";
import { HTMLDivProps, Nullable, VoidCallback } from "src/types/common";
import { deviceInfoSelectors } from "state/selectors";
import Spinner from "ui/common/Spinner";
import BottomMarker from "../BottomMarker";
import FailedView from "./components/FailedView";
import styles from "./InfiniteScroller.scss";

interface InfiniteScrollerProps extends HTMLDivProps {
  bottomMarkerIntersectionObserverProps?: IntersectionObserverInit;
  canLoadMore: boolean;
  children?: ReactNode | undefined;
  className?: string;
  hasLoadError: boolean;
  isLoading: boolean;
  loadMore: VoidCallback;
  placeholders?: Nullable<React.ReactElement>;
  refresh: VoidCallback;
  showSpinner?: boolean;
}

const InfiniteScroller = forwardRef<HTMLDivElement, InfiniteScrollerProps>(
  (
    {
      children,
      isLoading,
      hasLoadError,
      refresh,
      placeholders,
      className,
      loadMore,
      canLoadMore,
      showSpinner = true,
      bottomMarkerIntersectionObserverProps,
      ...rest
    },
    outerRef
  ) => {
    const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
    const onReachedBottom = useCallback(() => {
      if (canLoadMore && !isLoading) {
        loadMore();
      }
    }, [canLoadMore, isLoading, loadMore]);

    return (
      <div
        className={classnames(
          styles.root,
          !mobileTypes.includes(deviceType) && styles.rootDesktop,
          isLoading &&
            Children.toArray(children).length === 0 &&
            !placeholders &&
            styles.rootEmptyLoading,
          className
        )}
        ref={outerRef}
        {...rest}
      >
        {children}
        {hasLoadError ? (
          <FailedView onRetryClick={refresh} isLoading={isLoading} />
        ) : (
          showSpinner &&
          isLoading &&
          (placeholders !== undefined ? (
            placeholders
          ) : (
            <Spinner
              className={styles.spinner}
              data-testid="infinite-scroller-spinner"
            />
          ))
        )}
        {canLoadMore && !isLoading && !hasLoadError && (
          <BottomMarker
            onReached={onReachedBottom}
            intersectionObserverProps={bottomMarkerIntersectionObserverProps}
          />
        )}
      </div>
    );
  }
);

InfiniteScroller.displayName = "InfiniteScroller";

export default InfiniteScroller;
