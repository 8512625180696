import React, { useCallback, useMemo, forwardRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styles from "./GiftTabs.scss";
import classnames from "classnames";
import { giftsCacheSelectors } from "state/selectors";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";
import emptyFunction from "fbjs/lib/emptyFunction";

const Tab = forwardRef(({ id, selectCategory, selected, tabIndex }, ref) => {
  const { name, icon, activeIcon } = useSelector(
    (state) => giftsCacheSelectors.getCategoryById(state, id) || {}
  );
  const updateCategory = useCallback(() => {
    selectCategory(id);
  }, [id, selectCategory]);

  const initTab = useCallback((e) => {
    ref.current.push(e);
  }, []);

  const { activeIconStyle, defaultIconStyle } = useMemo(
    () => ({
      activeIconStyle: {
        "--mask-image-url": `url(${activeIcon})`,
      },
      defaultIconStyle: {
        "--mask-image-url": `url(${icon})`,
      },
    }),
    [activeIcon, icon]
  );

  return (
    <div
      ref={initTab}
      data-testid={id}
      data-selected={selected}
      data-tabcategory={id}
      data-tabindex={tabIndex}
      onClick={updateCategory}
      className={classnames(styles.tab, {
        [styles.selected]: selected,
      })}
      tabIndex={0}
      role="button"
      onTouchStart={emptyFunction}
    >
      {window.Modernizr.cssmask ? (
        <div className={styles.iconContainer}>
          {activeIcon && (
            <div
              className={classnames(styles.maskedIcon, styles.activeIcon)}
              style={activeIconStyle}
            />
          )}
          <div
            className={classnames(
              styles.maskedIcon,
              activeIcon && selected && styles.hidden
            )}
            style={defaultIconStyle}
          />
        </div>
      ) : (
        <img className={styles.fallbackIcon} src={icon} alt={name} />
      )}
    </div>
  );
});

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number,
};

const GiftTabs = forwardRef(
  ({ categoryIdsList, selectCategory, selectedCategoryId }, ref) => {
    const isMobile = useBreakpointMobileLayout();

    return (
      <div
        data-testid="tabs"
        className={classnames(styles.root, isMobile && styles.mobile)}
      >
        {categoryIdsList.map((x, i) => (
          <Tab
            ref={ref}
            key={x}
            id={x}
            selected={selectedCategoryId === x}
            selectCategory={selectCategory}
            tabIndex={i}
          />
        ))}
      </div>
    );
  }
);

GiftTabs.propTypes = {
  categoryIdsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectedCategoryId: PropTypes.string,
};

export default GiftTabs;
