import React, { memo, ElementType } from "react";
import classnames from "classnames";
import { ComponentWithClassName } from "src/types/common";
import styles from "./ToggleIcon.scss";

interface IconProps {
  isActive: boolean;
  InactiveIconComponent: ElementType;
  ActiveIconComponent: ElementType;
}

const ToggleIcon: ComponentWithClassName<IconProps> = ({
  isActive,
  className,
  InactiveIconComponent,
  ActiveIconComponent,
}) => (
  <div className={styles.root}>
    <div
      className={classnames(styles.iconWrapper, {
        [styles.hidden]: !isActive,
      })}
    >
      <ActiveIconComponent className={className} />
    </div>
    <div
      className={classnames(styles.iconWrapper, {
        [styles.hidden]: isActive,
      })}
    >
      <InactiveIconComponent className={className} />
    </div>
  </div>
);

export default memo(ToggleIcon);
