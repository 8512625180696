import React, {
  ComponentPropsWithRef,
  ElementType,
  PropsWithChildren,
  ReactElement,
  forwardRef,
  useMemo,
} from "react";
import classnames from "classnames";
import type { TypographyProps } from "ui/common/typography/types";
import { Nullable } from "src/types/common";
import isValueInStringEnum from "src/utils/isValueInStringEnum";
import {
  ParagraphTypographyType,
  TYPOGRAPHY_TYPE,
} from "ui/common/typography/types";
import styles from "./Typography.scss";

export type { TypographyProps };
export { TYPOGRAPHY_TYPE };

const DEFAULT_TYPE = "span";

type BaseProps<T extends ElementType> = {
  as?: T;
} & TypographyProps;

type TypographyComponentProps<T extends ElementType> = Omit<
  ComponentPropsWithRef<T>,
  keyof BaseProps<T>
> &
  PropsWithChildren<BaseProps<T>>;

type TypographyComponent = {
  <T extends ElementType = typeof DEFAULT_TYPE>(
    props: TypographyComponentProps<T>,
    ref?: ComponentPropsWithRef<T>["ref"]
  ): Nullable<ReactElement>;
  displayName?: string;
};

// https://github.com/ohansemmanuel/polymorphic-react-component great guide about polymorphic react components

const Typography: TypographyComponent = forwardRef(
  <T extends ElementType = typeof DEFAULT_TYPE>(
    {
      className,
      children,
      as,
      type,
      italic,
      lineThrough,
      underline,
      ...rest
    }: TypographyComponentProps<T>,
    ref?: ComponentPropsWithRef<T>["ref"]
  ) => {
    const Component = as || DEFAULT_TYPE;

    const classes = useMemo(
      () =>
        classnames(className, styles.base, styles[type], {
          [styles.regular]: isValueInStringEnum(ParagraphTypographyType, type),
          [styles.italic]: italic,
          [styles.lineThrough]: lineThrough,
          [styles.underline]: underline,
        }),
      [className, type, italic, lineThrough, underline]
    );

    return (
      <Component ref={ref} className={classes} {...rest}>
        {children}
      </Component>
    );
  }
);

Typography.displayName = "Typography";

export default Typography;
