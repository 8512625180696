import React, { forwardRef } from "react";
import classnames from "classnames";
import SimpleBar, { Props } from "simplebar-react";

import "simplebar-react/dist/simplebar.min.css";
import styles from "./Scrollbar.scss";

export enum ScrollbarVariant {
  ON_SHADER = "onShader",
  THEMED = "themed",
  LIGHT = "light",
}

interface ScrollbarProps extends Omit<Props, "classNames"> {
  className?: string;
  wrapperClassName?: string;
  variant?: ScrollbarVariant;
}

const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>(
  (
    {
      className,
      children,
      wrapperClassName,
      autoHide = false,
      variant = ScrollbarVariant.THEMED,
      ...rest
    },
    ref
  ) => (
    <div className={classnames(styles.root, wrapperClassName)}>
      <SimpleBar
        className={classnames(
          styles.scrollContainer,
          styles[variant],
          className
        )}
        autoHide={autoHide}
        scrollableNodeProps={{
          ref,
        }}
        {...rest}
      >
        {children}
      </SimpleBar>
    </div>
  )
);

Scrollbar.displayName = "Scrollbar";

export default Scrollbar;
