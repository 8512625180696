import React, { memo } from "react";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import { linkToSubscriptions } from "ui/navigation/links";
import { ReactComponent as SubscriptionsIcon } from "img/new-ui/burger_menu/ic-subscriptions.svg";
import sharedMessages from "ui/common/intl/sharedMessages";
import { FormattedNumber } from "ui/common/Formatted";
import { shallowEqual, useSelector } from "react-redux";
import { subscriptionPerStreamerListSelectors } from "state/selectors";
import useUiAction from "ui/hooks/useUiAction";
import { VoidCallback } from "src/types/common";
import { SUBSCRIBE_TARGET } from "@analytics/enums";
import styles from "./common.scss";

interface FanZoneItemsProps {
  onClick?: VoidCallback;
}

const FanZoneItem: React.FC<FanZoneItemsProps> = ({ onClick }) => {
  const subscriptionsCount = useSelector(
    subscriptionPerStreamerListSelectors.getUserSubscribedStreamerIds,
    shallowEqual
  ).length;

  const reportGoSubscriptionsUiAction = useUiAction({
    target: SUBSCRIBE_TARGET.SUBSCRIPTIONS_TARGET,
    callback: onClick,
  });

  if (!subscriptionsCount) {
    return null;
  }

  return (
    <DropdownListItem
      to={linkToSubscriptions}
      data-testid="subscriptions"
      onClick={reportGoSubscriptionsUiAction}
      Icon={SubscriptionsIcon}
      titleMessage={sharedMessages.subscriptions}
    >
      <span className={styles.count}>
        <FormattedNumber value={subscriptionsCount} />
      </span>
    </DropdownListItem>
  );
};

export default memo(FanZoneItem);
