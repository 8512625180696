import React, { useMemo, memo } from "react";
import { FormattedMessage } from "react-intl";

interface TimeLeftProps {
  duration: number;
}

const TimeLeft: React.FC<TimeLeftProps> = ({ duration }) => {
  const { hours, minutes } = useMemo(() => {
    const secondsLeft = duration / 1000;
    const minutesLeft = Math.floor(secondsLeft / 60);
    const hoursLeft = Math.floor(minutesLeft / 60);

    return {
      minutes: minutesLeft - hoursLeft * 60,
      hours: hoursLeft,
    };
  }, [duration]);

  return (
    <>
      {hours > 0 && (
        <>
          <FormattedMessage
            id="guardian.duration.hours"
            defaultMessage="{hours} hour(s)"
            values={{ hours }}
          />
          &nbsp;
        </>
      )}
      <FormattedMessage
        id="guardian.duration.minutes"
        defaultMessage="{minutes} minute(s)"
        values={{ minutes }}
      />
    </>
  );
};

export default memo(TimeLeft);
