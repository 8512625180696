import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { PopoverPosition } from "src/enums";
import styles from "./Popover.scss";

interface PopoverProps {
  children: ReactNode;
  content: ReactElement;
  contentClassName?: string;
  isOpen: boolean;
  onChange: (open: boolean) => void;
  position?:
    | "bottom"
    | "bottomLeft"
    | "bottomRight"
    | "left"
    | "leftBottom"
    | "leftTop"
    | "right"
    | "rightBottom"
    | "rightTop"
    | "top"
    | "topLeft"
    | "topRight";
  wrapperClassName?: string;
}

/**
 * `Popover` component.
 * The floating card popped by clicking.
 *
 * Props:
 * - `children`: The trigger element that toggles the popover's visibility. This can be any ReactNode.
 * - `content`: The content displayed within the popover. This is a ReactElement.
 * - `contentClassName`: (Optional) A CSS class name for styling the popover content specifically.
 * - `isOpen`: A boolean indicating the current visibility state of the popover. True shows the popover, while false hides it.
 * - `onChange`: A callback function invoked with the updated visibility state (open or closed) as its argument.
 * - `position`: (Optional) Specifies the preferred position of the popover relative to the trigger element. It supports several directional options like "top", "right", "bottom", "left", and combinations thereof (e.g., "bottomRight"). Defaults to "top" if not specified.
 * - `wrapperClassName`: (Optional) A CSS class name for styling the overall container of the popover, including the trigger and the content.
 *
 * @returns JSX.Element - The rendered popover component.
 *
 * @example
 * <Popover
 *   isOpen={isPopoverOpen}
 *   onChange={setIsPopoverOpen}
 *   content={<div>Popover Content</div>}
 *   position={PopoverPosition.TOP_RIGHT}
 * >
 *   <button>Toggle Popover</button>
 * </Popover>
 */

export const Popover: FC<PopoverProps> = ({
  children,
  content,
  position = PopoverPosition.TOP,
  contentClassName,
  wrapperClassName,
  isOpen,
  onChange,
}) => {
  const [isInteracting, setIsInteracting] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [updatedContent, setUpdatedContent] = useState<ReactElement>(content);

  const handleInteractionStart = useCallback(() => setIsInteracting(true), []);
  const handleInteractionEnd = useCallback(() => setIsInteracting(false), []);
  const onClick = useCallback(() => onChange(!isOpen), [isOpen, onChange]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        !isInteracting &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onChange(false);
      }
    },
    [onChange, isInteracting]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setUpdatedContent(
      React.cloneElement(content, { key: new Date().getTime() })
    );
  }, [content]);

  return (
    <div
      ref={wrapperRef}
      onMouseEnter={handleInteractionStart}
      onMouseLeave={handleInteractionEnd}
      className={classNames(styles.root, wrapperClassName)}
    >
      <div onClick={onClick} className={styles.trigger}>
        {children}
      </div>
      <div
        hidden={!isOpen}
        className={classNames(
          styles.contentWrapper,
          styles[position],
          contentClassName
        )}
      >
        <div className={styles.content}>{updatedContent}</div>
      </div>
    </div>
  );
};

Popover.displayName = "Popover";
