import React, { memo } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { mobileTypes } from "src/constants";
import { deviceInfoSelectors } from "state/selectors";
import useLandscapeOrientation from "ui/hooks/useLandscapeOrientation";
import useLandscapeStream from "ui/hooks/useLandscapeStream";
import usePortalElement from "ui/hooks/usePortalElement";
import {
  linkToLeaderBoard,
  linkToWebviewPaymentButton,
} from "ui/navigation/links";
import rotationIcon from "img/ic_rotate.svg?url";
import styles from "./DeviceRotationWarning.scss";

const DeviceRotationWarning: React.FC = () => {
  const landscape = useLandscapeOrientation();
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const isWebview = useSelector(deviceInfoSelectors.getWebview);
  const enabledLandscapeStream = useLandscapeStream();
  const leaderBoard = useRouteMatch(linkToLeaderBoard);
  const paymentButton = useRouteMatch(linkToWebviewPaymentButton);

  const show =
    !isWebview &&
    !leaderBoard &&
    !paymentButton &&
    landscape &&
    mobileTypes.includes(deviceType) &&
    !enabledLandscapeStream;
  const el = usePortalElement(show);

  if (!show) {
    return null;
  }

  return createPortal(
    <div className={styles.root}>
      <img className={styles.image} src={rotationIcon} />
      <div className={styles.title}>
        <FormattedMessage
          id="rotation.warning.phone.title"
          defaultMessage="Please turn your device vertically"
        />
      </div>
      <div className={styles.body}>
        <FormattedMessage
          id="rotation.warning.phone.body"
          defaultMessage="Tango’s best experience on the phone is in portrait mode only"
        />
      </div>
    </div>,
    el
  );
};

export default memo(DeviceRotationWarning);
