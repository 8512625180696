import React, { memo } from "react";
import Spinner from "./Spinner";
import { useRouteMatch } from "react-router-dom";
import { linkToStreamMatch } from "ui/navigation/links";
import StreamSkeleton from "ui/scenes/stream/Skeleton";
import styles from "./LoadingComponent.scss";

interface LoadingComponentProps {
  pastDelay?: boolean;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ pastDelay }) => {
  const isInStream = !!useRouteMatch(linkToStreamMatch);

  if (isInStream) {
    return <StreamSkeleton />;
  }

  return pastDelay ? <Spinner className={styles.root} /> : null;
};

export default memo(LoadingComponent);
