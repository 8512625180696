import React, { memo, useCallback } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import classnames from "classnames";
import { emitUiAction } from "@analytics/emitUiAction";
import { EventFields, SCREEN_NAME } from "@analytics/enums";
import { GetTheAppSource, GetTheAppTarget } from "@analytics/getTheApp";
import { VoidCallback } from "src/types/common";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import styles from "./Buttons.scss";

interface ButtonsProps {
  className?: string;
  onPrimaryClick?: VoidCallback;
  primaryLabel: MessageDescriptor;
  primaryTarget: GetTheAppTarget;
  screenSource: GetTheAppSource;
}

const Buttons: React.FC<ButtonsProps> = ({
  className,
  onPrimaryClick,
  primaryLabel,
  primaryTarget,
  screenSource,
}) => {
  const handlePrimaryClick = useCallback(() => {
    emitUiAction({
      target: primaryTarget,
      tango_screen: {
        reportedName: SCREEN_NAME.INSTALL_TANGO_APP,
      },
      additionalParams: {
        [EventFields.SOURCE]: screenSource,
      },
    });
    onPrimaryClick?.();
  }, [onPrimaryClick, screenSource, primaryTarget]);

  return (
    <div className={classnames(styles.root, className)}>
      <Button
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.PRIMARY}
        onClick={handlePrimaryClick}
        className={styles.primary}
      >
        <FormattedMessage {...primaryLabel} />
      </Button>
    </div>
  );
};

export default memo(Buttons);
