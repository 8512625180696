import { Breakpoints } from "src/enums";
import { BannerConfig, BannerLiveGridTabType } from "src/types/banner";
import { bannerWebBreakpointArtworkMapping } from "ui/common/banner/constants";

export const filterBannersByLiveGridTab = (
  banners: BannerConfig[],
  liveGridTab: BannerLiveGridTabType
) =>
  banners.filter((banner) =>
    banner.displaySection.liveGridTabs.includes(liveGridTab)
  );

export const filterBannersByBreakpoint = (
  banners: BannerConfig[],
  breakpoint: Breakpoints
) =>
  banners.filter((banner) => {
    const bannerImageField =
      banner[bannerWebBreakpointArtworkMapping[breakpoint]];

    return typeof bannerImageField === "string" && !!bannerImageField;
  });
