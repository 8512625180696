import React, { useCallback, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { useSnackbar } from "notistack";
import { UNSUBSCRIBE_TARGET } from "@analytics/enums";
import ScreenViewReportingContext, {
  ScreenViewReporter,
} from "@analytics/screenView/ScreenViewReportingContext";
import { unsubscribeScreenNameForBottomScreen } from "@analytics/screenView/screenName";
import { AvatarSize } from "src/enums";
import { VoidCallback } from "src/types/common";
import {
  hideBottomScreen,
  setAnimation,
} from "state/actionCreators/bottomScreen";
import actions from "state/actionCreators/subscriptionPerStreamerList";
import DisplayName from "ui/common/DisplayName";
import Avatar from "ui/common/avatar/Avatar";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import useProfileById from "ui/hooks/useProfileById";
import useUiAction from "ui/hooks/useUiAction";
import useVipInfo from "ui/hooks/useVipInfo";
import { makeLinkToProfile } from "ui/navigation/links";
import { ReactComponent as StarIcon } from "img/ic_become_a_fan_star.svg";
import styles from "./UnsubscribeToStreamer.scss";

interface UnsubscribeToStreamerProps {
  accountId: string;
  dismissModal: VoidCallback;
  subscriptionId: string;
}

const UnsubscribeToStreamer: React.FC<UnsubscribeToStreamerProps> = ({
  accountId,
  subscriptionId,
  dismissModal,
}) => {
  const dispatch = useDispatch();
  const { getStack } = useContext(ScreenViewReportingContext);
  const [screen] = getStack();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const {
    basicProfile: streamerProfile,
    liveStats: { vipStatus },
  } = useProfileById(accountId, true);
  const { vipLabel } = useVipInfo({
    vipLevel: vipStatus,
  });

  const reportCloseUiAction = useUiAction({
    target: UNSUBSCRIBE_TARGET.CANCEL_POPUP_TARGET,
    callback: emptyFunction,
  });
  const reportUnsubscribeUiAction = useUiAction({
    // @ts-ignore
    screen: "unsubscribe",
    target: UNSUBSCRIBE_TARGET.POPUP_TARGET,
  });

  const dismiss = () => {
    dismissModal();
    dispatch(setAnimation(true));
    setTimeout(() => {
      dispatch(hideBottomScreen());
    }, 1000);
  };

  const close = useCallback(() => {
    reportCloseUiAction();
    dismiss();
  }, [reportCloseUiAction, dismiss]);

  const unsubscribe = useCallback(() => {
    reportUnsubscribeUiAction();
    dispatch(
      actions.cancelSubscription({
        subscriptionId,
        onError: () =>
          enqueueSnackbar(
            intl.formatMessage(sharedMessages.somethingWentWrong),
            {
              autoHideDuration: 1500,
              variant: "warning",
            }
          ),
        onSuccess: () => dispatch(actions.unsubscribeStreamer({ accountId })),
      })
    );
    dismiss();
  }, [subscriptionId, accountId]);

  const link = accountId ? makeLinkToProfile(accountId, streamerProfile) : "";

  return (
    <div className={styles.root}>
      <ScreenViewReporter
        nested={false}
        // @ts-ignore
        name={unsubscribeScreenNameForBottomScreen(screen.reportedName)}
      />
      <Avatar
        to={link}
        imageUrl={streamerProfile.profilePictureUrl}
        size={AvatarSize.MEDIUM}
        className={styles.avatar}
        vipLabel={vipLabel}
      />
      <Typography
        type={TYPOGRAPHY_TYPE.HEADLINE2}
        className={styles.profileName}
      >
        <DisplayName basicProfile={streamerProfile} />
      </Typography>

      <Typography
        type={TYPOGRAPHY_TYPE.PARAGRAPH2}
        className={styles.unsubscribeMessage}
      >
        <FormattedMessage
          id="subscriptions.streamer.unsubscribeMessage.desc11"
          defaultMessage="will be very sad if you leave :("
        />
      </Typography>
      <Button
        className={classnames(styles.cancelButton, styles.button)}
        onClick={close}
        variant={ButtonVariant.SUBSCRIPTION_PRIMARY}
        size={ButtonSize.BIG_48}
        leftIcon={StarIcon}
      >
        <FormattedMessage
          id="subscription_cancel_stay_fan"
          defaultMessage="Stay a Fan"
        />
      </Button>
      <Button
        variant={ButtonVariant.SUBSCRIPTION_SECONDARY}
        size={ButtonSize.BIG_48}
        className={classnames(styles.unsubscribeButton, styles.button)}
        onClick={unsubscribe}
      >
        <FormattedMessage
          id="subscription_cancel_cancel_support"
          defaultMessage="Cancel Support"
        />
      </Button>
    </div>
  );
};

export default UnsubscribeToStreamer;
