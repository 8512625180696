import React, { memo, useEffect, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import { BannerLiveGridTabType, BannerType } from "src/types/banner";
import { ComponentWithClassName, WithClasses } from "src/types/common";
import { getBannersByTypeModeEnabled } from "state/abTests";
import { bannerConfigsSelectors } from "state/selectors";
import BannersCarousel, {
  BannersCarouselClasses,
  Skeleton,
} from "ui/common/banner/BannersCarousel";
import {
  filterBannersByBreakpoint,
  filterBannersByLiveGridTab,
} from "ui/common/banner/utils";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";

interface BannersByTypeProps {
  liveGridTabType?: BannerLiveGridTabType;
  onBannersReady?: VoidFunction;
  type: BannerType;
}

const BannersByType: ComponentWithClassName<
  WithClasses<BannersByTypeProps, BannersCarouselClasses>
> = (props) => {
  const { className, type, classes, onBannersReady, liveGridTabType } = props;

  const breakpoint = useBreakpointPrecise();

  const isBannersByTypeModeEnabled = useSelector(getBannersByTypeModeEnabled);

  const selectedBanners = useSelector(
    bannerConfigsSelectors.data,
    shallowEqual
  );

  const fallbackBanners = useMemo(
    () => selectedBanners[BannerType.WEB_BANNER],
    [selectedBanners]
  );

  const isLiveGridType = useMemo(() => type === BannerType.LIVE_GRID, [type]);

  const bannerConfigs = useMemo(() => {
    const liveGridTabEnabled = isLiveGridType && !!liveGridTabType;
    const bannersByType = liveGridTabEnabled
      ? filterBannersByLiveGridTab(selectedBanners[type], liveGridTabType)
      : selectedBanners[type];

    const filteredBannersByBreakpoint = filterBannersByBreakpoint(
      bannersByType,
      breakpoint
    );

    if (!isBannersByTypeModeEnabled || !filteredBannersByBreakpoint?.length) {
      return fallbackBanners;
    }

    return filteredBannersByBreakpoint;
  }, [
    fallbackBanners,
    isBannersByTypeModeEnabled,
    isLiveGridType,
    breakpoint,
    liveGridTabType,
    selectedBanners,
    type,
  ]);

  const { loading } = useSelector(bannerConfigsSelectors.meta, shallowEqual);

  useEffect(() => {
    if (bannerConfigs.length) {
      onBannersReady?.();
    }
  }, [bannerConfigs.length, onBannersReady]);

  if (loading && !bannerConfigs.length) {
    return (
      <Skeleton
        breakpoint={breakpoint}
        className={classnames(className, classes?.skeletonRoot)}
        viewportClassName={classes?.skeletonViewport}
      />
    );
  }

  if (!bannerConfigs.length) {
    return null;
  }

  return (
    <BannersCarousel
      className={className}
      data={bannerConfigs}
      breakpoint={breakpoint}
      classes={classes}
    />
  );
};

export default memo(BannersByType);
