import React from "react";
import classnames from "classnames";
import { themeLockClassnames } from "ui/Theme";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { CheckboxProps } from "./types";
import { ReactComponent as CheckedCheckboxIcon } from "img/common/checkbox/checked-checkbox.svg";
import { ReactComponent as CheckedRadioIcon } from "img/common/checkbox/checked-radio.svg";
import { ReactComponent as UncheckedCheckboxIcon } from "img/common/checkbox/unchecked-checkbox.svg";
import { ReactComponent as UncheckedRadioIcon } from "img/common/checkbox/unchecked-radio.svg";
import styles from "./Checkbox.scss";

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  description,
  radio,
  shader,
  checked,
  disabled,
  className,
  markClassName,
  dataTestId,
  readOnly,
  ...rest
}) => {
  const CheckedIcon = radio ? CheckedRadioIcon : CheckedCheckboxIcon;
  const UncheckedIcon = radio ? UncheckedRadioIcon : UncheckedCheckboxIcon;
  const Icon = checked ? CheckedIcon : UncheckedIcon;

  return (
    <label
      className={classnames(
        styles.root,
        {
          [styles.disabled]: disabled,
          [styles.checked]: checked,
          [themeLockClassnames.dark]: shader,
          [styles.readOnly]: readOnly,
        },
        className
      )}
      data-testid={dataTestId}
    >
      <input
        {...rest}
        disabled={disabled}
        checked={checked}
        readOnly={readOnly}
        type={radio ? "radio" : "checkbox"}
      />
      <div className={styles.labelContainer}>
        <Icon className={classnames(styles.mark, markClassName)} />
        {label && (
          <Typography
            className={styles.label}
            type={TYPOGRAPHY_TYPE.PARAGRAPH3}
          >
            {label}
          </Typography>
        )}
      </div>
      {description && (
        <Typography
          className={styles.description}
          type={TYPOGRAPHY_TYPE.PARAGRAPH5}
        >
          {description}
        </Typography>
      )}
    </label>
  );
};

export default Checkbox;
