import React, { memo, useEffect } from "react";
import { emitEvent } from "src/analytics/emit";
import { BI_LANDING_FIELD, EventNames } from "src/analytics/enums";
import MintrouteCardView from "ui/common/personalOffer/card/views/MintrouteCardView";
import RegularCardView from "ui/common/personalOffer/card/views/RegularCardView";
import { DEFAULT_DISCOUNT_PERCENT } from "ui/common/personalOffer/constants";
import { PersonalOffersCardProps } from "ui/common/personalOffer/types";
import { useValueAndCurrencyForPersonalOffers } from "ui/hooks/useCurrencies";
import {
  DEFAULT_OFFER_CARD_SIZE,
  DEFAULT_OFFER_CARD_VARIANT,
  OfferCardSizes,
  OfferCardVariants,
} from "ui/modal/modalViews/buyCoins/offers/card/enums";

const componentsMap = {
  [OfferCardVariants.REGULAR]: RegularCardView,
  [OfferCardVariants.MINTROUTE]: MintrouteCardView,
};

interface BaseProps {
  personalOfferId?: string;
  size?: OfferCardSizes;
  triggerId?: string;
}

export enum Tag {
  HOT = "hot",
  POPULAR = "popular",
}

interface BaseCardProps extends PersonalOffersCardProps {
  variant?: OfferCardVariants.MINTROUTE | OfferCardVariants.REGULAR;
}

export type OfferCardProps = BaseCardProps & BaseProps;

const OfferCard: React.FC<OfferCardProps> = ({
  variant = DEFAULT_OFFER_CARD_VARIANT,
  size = DEFAULT_OFFER_CARD_SIZE,
  loading,
  offer,
  type,
  triggerId,
  biCardIndex,
  personalOfferId,
  analyticsParams,
  ...rest
}) => {
  const { offers, id: pricePointId, isPopular, isHot } = offer;
  const [{ marketOfferId, currencyPrice, bonus }] = offers;

  const priceOffer = {
    currencyPrice,
    discountPercent: bonus || DEFAULT_DISCOUNT_PERCENT,
    usdPrice: offer.price,
  };
  const priceProps = useValueAndCurrencyForPersonalOffers(priceOffer);

  useEffect(() => {
    if (!loading) {
      let tag;
      if (isHot) {
        tag = Tag.HOT;
      }
      if (isPopular) {
        tag = Tag.POPULAR;
      }

      emitEvent(EventNames.OFFER_DISPLAYED, {
        [BI_LANDING_FIELD.TRIGGER_ID]: triggerId,
        [BI_LANDING_FIELD.MARKET_OFFER_ID]: marketOfferId,
        [BI_LANDING_FIELD.POSITION]: biCardIndex,
        [BI_LANDING_FIELD.PRICE_POINT_ID]: pricePointId,
        [BI_LANDING_FIELD.SERVER_OFFER_ID]: personalOfferId,
        [BI_LANDING_FIELD.SOURCE]: type,
        [BI_LANDING_FIELD.TAG]: tag,
        [BI_LANDING_FIELD.CURRENCY_CODE]: priceProps.currency,
      });
    }
  }, [loading, triggerId, offer, priceProps.currency, personalOfferId]);

  const Component = componentsMap[variant];

  return (
    <Component
      type={type}
      offer={offer}
      biCardIndex={biCardIndex}
      loading={loading}
      size={size}
      analyticsParams={{
        ...analyticsParams,
        [BI_LANDING_FIELD.PRICE_POINT_ID]: pricePointId,
      }}
      {...rest}
    />
  );
};

export default memo(OfferCard);
