import React, { memo, useCallback } from "react";
import { defineMessages } from "react-intl";
import copy from "copy-to-clipboard";
import { ToastType } from "src/enums";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import Typography from "ui/common/typography/Typography";
import { TYPOGRAPHY_TYPE } from "ui/common/typography/types";
import { useToast } from "ui/hooks/useToast";
import { ReactComponent as CheckMarkIcon } from "img/check-mark_32.svg";
import styles from "./AppVersionMenuItem.scss";

const messages = defineMessages({
  versionTitle: {
    id: "version.title",
    defaultMessage: "App Version",
  },
  copied: {
    id: "version.copied",
    defaultMessage: "Version copied to clipboard",
  },
});

const AppVersionMenuItem = () => {
  const { notify } = useToast();
  const handleCopyAppVersion = useCallback(() => {
    copy(window.twcVersion);
    notify({
      IconComponent: CheckMarkIcon,
      type: ToastType.REGULAR,
      title: messages.copied,
    });
  }, [notify]);

  return (
    <DropdownListItem
      className={styles.appVersion}
      messageClassName={styles.message}
      titleMessage={messages.versionTitle}
      onClick={handleCopyAppVersion}
      data-testid="app-version"
    >
      <Typography
        className={styles.description}
        type={TYPOGRAPHY_TYPE.PARAGRAPH5}
      >
        {window.twcVersion}
      </Typography>
    </DropdownListItem>
  );
};

export default memo(AppVersionMenuItem);
