import React, { FC, InputHTMLAttributes, RefObject } from "react";
import classnames from "classnames";
import { Field, FieldProps } from "ui/common/textField/Field";
import styles from "./TextField.scss";

type TextFieldProps = {
  inputClassName?: string;
  inputRef?: RefObject<HTMLInputElement>;
} & FieldProps &
  InputHTMLAttributes<HTMLInputElement>;

export const TextField: FC<TextFieldProps> = (props) => {
  const { type = "text", inputRef, inputClassName, ...rest } = props;

  return (
    <Field {...rest}>
      <input
        {...rest}
        ref={inputRef}
        type={type}
        className={classnames(styles.inputBase, inputClassName)}
      />
    </Field>
  );
};
