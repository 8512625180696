import React, { useCallback, useLayoutEffect, useMemo, memo } from "react";
import classnames from "classnames";
import useTimerParts, {
  PartsWithPrefix,
  Target,
  CalculatedParts,
} from "ui/hooks/useTimerParts";
import differenceInDays from "date-fns/differenceInDays";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import differenceInSeconds from "date-fns/differenceInSeconds";
import { pad } from "src/utils/pad";
import { VoidCallback } from "src/types/common";
import styles from "./PromoTimer.scss";

const calculatePartsWithFreeze = (
  target: Target,
  freeze: string | undefined
): CalculatedParts<PartsWithPrefix> => {
  const timerParts: PartsWithPrefix = [];
  if (target) {
    const date = new Date();
    const days = differenceInDays(target, date);
    const hours = differenceInHours(target, date);
    const minutes = differenceInMinutes(target, date);
    const seconds = differenceInSeconds(target, date);

    timerParts.push(
      { amount: pad(Math.max(days, 0)), prefix: "days" },
      {
        amount: pad(`${Math.max(days > 0 ? hours % 24 : hours, 0)}`),
        prefix: "hrs",
      },
      { amount: pad(Math.max(minutes, 0) % 60), prefix: "min" },
      { amount: pad(Math.max(seconds, 0) % 60), prefix: "secs" }
    );

    const preparedFreeze = freeze == null ? 0 : Number(freeze);

    return {
      remainingSeconds: seconds + preparedFreeze,
      timerParts,
    };
  }
  return {
    remainingSeconds: 0,
    timerParts,
  };
};

interface PromoTimerProps {
  ts: number;
  duration: string;
  freeze: string;
  className?: string;
  onEnd?: VoidCallback;
}

const PromoTimer: React.FC<PromoTimerProps> = ({
  className,
  ts,
  duration,
  freeze,
  onEnd,
}) => {
  const target = useMemo(
    () => (ts && duration ? new Date(ts + Number(duration) * 1000) : null),
    [ts, duration]
  );
  const calculator = useCallback(
    () => calculatePartsWithFreeze(target, freeze),
    [target, freeze]
  );
  const { timerParts, remainingSeconds } = useTimerParts(target, calculator);

  const isEnd = remainingSeconds <= 0;

  useLayoutEffect(() => {
    if (onEnd != null && isEnd) {
      onEnd();
    }
  }, [isEnd]);
  return (
    <div className={classnames(styles.timer, className)}>
      {timerParts.map(({ amount, prefix }, index) => (
        <div
          key={prefix}
          className={classnames(
            styles.timerPartContainer,
            index < timerParts.length - 1 && styles.delimiter
          )}
        >
          <div className={styles.timerPart}>
            <span className={styles.timerCount}>{amount}</span>
            <span className={styles.timerUnit}>{prefix}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(PromoTimer);
