import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import {
  BI_LANDING_FIELD,
  EventFields,
  UIActionTarget,
} from "@analytics/enums";
import { currencyToSymbol } from "src/constants";
import { Currency } from "src/enums";
import { ComponentWithClassName, Nullable } from "src/types/common";
import { Select, SelectProps } from "src/ui/common/select/Select";
import { currenciesSelectors, userSelectors } from "state/selectors";
import sharedMessages from "ui/common/intl/sharedMessages";
import useChangeCurrency from "ui/hooks/useChangeCurrency";
import useUiAction from "ui/hooks/useUiAction";
import useIsOnStream from "ui/navigation/useIsOnStream";
import { LandingPageConfig } from "ui/scenes/landingPage/types";

type StylesProps = {
  borderRadius?: number;
  padding?: number;
  whitePicker?: boolean;
};

type CurrencyPickerProps = {
  isDarkTheme?: boolean;
  isShowLabel?: boolean;
  landingPageId?: LandingPageConfig["landingPageId"];
  trackingId?: Nullable<string>;
  triggerId?: Nullable<LandingPageConfig["triggerId"]>;
} & Pick<SelectProps, "variant"> &
  StylesProps;

const formatOption = (value: Currency) =>
  `${currencyToSymbol[value] ? `${currencyToSymbol[value]}, ` : ""}${value}`;

const CurrencyPicker: ComponentWithClassName<CurrencyPickerProps> = ({
  landingPageId,
  triggerId,
  trackingId,
  isShowLabel,
  className,
  variant,
}) => {
  const accountId = useSelector(userSelectors.getMyAccountId);

  const isOnStream = useIsOnStream();

  const currentCurrency = useSelector(currenciesSelectors.getCurrentCurrency);
  const currencies = useSelector(
    currenciesSelectors.getAllCurrencies,
    shallowEqual
  );

  const changeCurrency = useChangeCurrency();
  const enrichUiAction = useCallback(
    (currency: Currency) => ({
      position: currencyToSymbol[currency] || -1,
      [EventFields.USER_ID]: accountId,
      [BI_LANDING_FIELD.PAGE_TITLE]: landingPageId,
      [BI_LANDING_FIELD.PAGE_TYPE]: "regular_personal",
      [EventFields.TANGO_SCREEN]: `buy_coins_landing.${landingPageId}.offers_page`,
      [BI_LANDING_FIELD.CURRENCY_CODE]: currency,
      [BI_LANDING_FIELD.PREVIOUS_CURRENCY_CODE]: currentCurrency,
      [BI_LANDING_FIELD.TRIGGER_ID]: triggerId,
      [BI_LANDING_FIELD.TRACKING_ID]: trackingId,
    }),
    [accountId, landingPageId, triggerId, currentCurrency, trackingId]
  );
  const reportUiAction = useUiAction({
    target: UIActionTarget.CURRENCY_PICKER,
    additionalParams: enrichUiAction,
  });

  const onChangeCurrency = useCallback(
    (option) => {
      changeCurrency(option.value);
      reportUiAction(option.value);
    },
    [changeCurrency, reportUiAction]
  );
  if (currencies?.length <= 1) {
    return null;
  }

  const options = currencies.map((currency) => ({
    value: currency,
    label: formatOption(currency),
  }));
  const currentOption = {
    value: currentCurrency,
    label: formatOption(currentCurrency),
  };

  return (
    <Select
      containerClassName={className}
      label={isShowLabel && <FormattedMessage {...sharedMessages.currency} />}
      onChange={onChangeCurrency}
      value={currentOption}
      options={options}
      disabled={currencies.length < 2}
      variant={variant ?? isOnStream ? "shader" : "outlined"}
      data-testid="currency-picker"
    />
  );
};

export default CurrencyPicker;
