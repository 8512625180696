import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import { deleteAccountConfirmationMessages } from "ui/common/deleteAccountView/messages";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import styles from "ui/common/deleteAccountView/DeleteAccountConfirmationView.scss";

interface DeleteAccountConfirmationViewProps {
  onCancel: VoidFunction;
  onSubmit: VoidFunction;
  userName?: string;
}

export const DeleteAccountConfirmationView = (
  props: DeleteAccountConfirmationViewProps
) => {
  const { userName, onCancel, onSubmit } = props;

  const breakpoint = useBreakpoint();

  return (
    <div>
      {userName && (
        <Typography type={TYPOGRAPHY_TYPE.HEADLINE2} className={styles.title}>
          {`${userName}, `}
        </Typography>
      )}

      <Typography type={TYPOGRAPHY_TYPE.HEADLINE2} className={styles.title}>
        <FormattedMessage {...deleteAccountConfirmationMessages.header} />
      </Typography>

      <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3} className={styles.text}>
        <FormattedMessage {...deleteAccountConfirmationMessages.info} />
      </Typography>

      <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3} className={styles.text}>
        <FormattedMessage
          {...deleteAccountConfirmationMessages.confirmationText}
        />
      </Typography>

      <div className={classNames(styles.buttonsContainer, styles[breakpoint])}>
        <Button
          size={ButtonSize.BIG_48}
          variant={ButtonVariant.PRIMARY}
          className={classNames(styles.button, styles.submitButton)}
          onClick={onSubmit}
        >
          <FormattedMessage
            {...deleteAccountConfirmationMessages.submitButton}
          />
        </Button>

        <Button
          size={ButtonSize.BIG_48}
          variant={ButtonVariant.SECONDARY}
          className={styles.button}
          onClick={onCancel}
        >
          <FormattedMessage {...sharedMessages.cancel} />
        </Button>
      </div>
    </div>
  );
};
