import React, { useCallback, useContext, useMemo, useState } from "react";
import Lightbox from "react-images";
import { batch } from "react-redux";
import emptyFunction from "fbjs/lib/emptyFunction";
import PropTypes from "prop-types";
import { ScreenViewReporter } from "@analytics/screenView/ScreenViewReportingContext";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";

const Context = React.createContext(emptyFunction.thatReturnsArgument);

export const useLightbox = () => useContext(Context);

export const LightboxProvider = ({ children }) => {
  const isMobileLayout = useBreakpointMobileLayout();
  const [photos, setPhotos] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const theme = useMemo(() => {
    const footerAndHeaderWidth = isMobileLayout ? "100%" : "85%";
    return {
      footer: {
        paddingTop: 10,
        paddingBottom: 20,
        height: "unset",
        margin: "0 auto",
        width: footerAndHeaderWidth,
      },
      footerCaption: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      image: {
        maxHeight: "100%",
      },
      content: {
        // emulate default paddings.
        width: "calc(100% - 20px)",
        height: "calc(100% - 20px)",
        top: "30px",
        position: "absolute",
      },
      figure: {
        height: `calc(100% - 65px)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
      header: {
        position: "absolute",
        margin: "0 auto",
        width: footerAndHeaderWidth,
      },
    };
  }, [isMobileLayout]);

  const openLightbox = useCallback(({ photos = [], index = 0 }) => {
    batch(() => {
      setPhotos(photos.map((x) => (typeof x === "string" ? { src: x } : x)));
      setCurrentImage(index);
      setLightboxIsOpen(photos && photos.length > 0);
    });
  }, []);

  const closeLightbox = useCallback(() => {
    batch(() => {
      setCurrentImage(0);
      setLightboxIsOpen(false);
    });
  }, []);

  const gotoNext = useCallback(() => {
    setCurrentImage((x) => x + 1);
  }, []);

  const gotoPrevious = useCallback(() => {
    setCurrentImage((x) => x - 1);
  }, []);

  return (
    <Context.Provider value={openLightbox}>
      {children}
      {lightboxIsOpen && <ScreenViewReporter name="gallery.photo" />}
      <Lightbox
        images={photos}
        currentImage={currentImage}
        isOpen={lightboxIsOpen}
        onClose={closeLightbox}
        onClickPrev={gotoPrevious}
        onClickNext={gotoNext}
        backdropClosesModal
        theme={theme}
      />
    </Context.Provider>
  );
};

LightboxProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
