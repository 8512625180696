import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { openLoginView } from "src/features/signin/exports/state/flows";
import { loginSelectors } from "state/selectors";
import { defaultRoute } from "ui/navigation/links";

export const NullFallback = () => null;

const LoginGate = ({
  children,
  registrationSource,
  fallbackComponent: FallbackComponent,
  fallbackContent,
  fallbackProps = {},
}) => {
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const dispatch = useDispatch();
  const beginLogin = useCallback(
    () =>
      dispatch(
        openLoginView({
          registrationSource,
        })
      ),
    [registrationSource]
  );

  if (isLoggedIn) {
    return children;
  }
  if (fallbackContent) {
    return fallbackContent;
  }
  if (FallbackComponent) {
    return <FallbackComponent beginLogin={beginLogin} {...fallbackProps} />;
  }

  return <Redirect to={defaultRoute} />;
};

LoginGate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fallbackContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fallbackComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  fallbackProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  registrationSource: PropTypes.number,
};

export default LoginGate;
