import { ShopOffer } from "ui/scenes/landingPage/types";

export enum OfferCardVariants {
  REGULAR = "regular",
  MINTROUTE = "mintroute",
}

export enum OfferCardSizes {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
  EXTRA_SMALL = "xs",
}

export enum OfferCardTypes {
  CASHIER = "cashier",
  REFILL = "refill",
  LANDING = "landing",
  LANDING_LIGHT = "landing_light",
}

export const DEFAULT_OFFER_CARD_SIZE = OfferCardSizes.MEDIUM;
export const DEFAULT_OFFER_CARD_VARIANT = OfferCardVariants.REGULAR;

export type BaseOfferCardProps = {
  offer: ShopOffer;
  type: OfferCardTypes;
  size: OfferCardSizes;
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
  className?: string;
  analyticsParams?: Record<string, unknown>;
  onClick: (offer: ShopOffer) => void;
  onSubscribeClick?: undefined;
  onRenewSubscription?: undefined;
  onCancelSubscription?: undefined;
  biCardIndex?: number;
};
