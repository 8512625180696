import React from "react";
import classnames from "classnames";
import styles from "./Badge.scss";

interface BadgeProps {
  className?: string;
}

const Badge: React.FC<BadgeProps> = ({ className }) => (
  <div className={classnames(styles.root, className)} data-testid="indicator" />
);

export default Badge;
