import React, { memo } from "react";
import classnames from "classnames";
import wheel from "img/wheel.png";
import sasArrow from "img/sas_arrow.png";
import sasCoins from "img/sas_coins.png";
import { FormattedMessage } from "react-intl";
import styles from "./SasBanner.scss";

interface SasBannerProps {
  isMobile?: boolean;
}

const SasBanner: React.FC<SasBannerProps> = ({ isMobile }) => (
  <div className={classnames(styles.root, isMobile && styles.mobile)}>
    <div className={styles.wheelContainer}>
      <img
        width={234}
        height={234}
        src={wheel}
        className={styles.wheel}
        alt="wheel"
      />
      <img
        width={60}
        height={50}
        src={sasArrow}
        className={styles.arrow}
        alt="arrow"
      />
      <img
        width={96}
        height={96}
        src={sasCoins}
        className={styles.coins}
        alt="coins"
      />
    </div>
    <div className={styles.info}>
      <span className={styles.title}>
        <FormattedMessage
          id="sas.get-more-coins"
          defaultMessage="Get more coins!"
        />
      </span>
      <span className={styles.description}>
        <FormattedMessage
          id="sas.spin-the-wheel"
          defaultMessage="Spin The Wheel to get bonus coins after purchasing!"
        />
      </span>
    </div>
  </div>
);

export default memo(SasBanner);
