import React, { forwardRef, memo, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import { GIFT_ORIGIN } from "@analytics/enums";
import { RealTimeRecommendationsGiftFromType } from "src/features/recommendations/common/enums";
import {
  customDrawerSelectors,
  giftingRequestsSelectors,
  giftsCacheSelectors,
  loginSelectors,
} from "state/selectors";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";
import Gift, { RESPONSIVE_TYPE } from "./Gift";
import { ReactComponent as DiamondIcon16 } from "img/ic_diamond_16.svg";
import styles from "./Category.scss";

const emptyArr = [];

const Collection = ({ id, categoryId }) => {
  const { giftIds, rewardPoints, name } =
    useSelector((state) => giftsCacheSelectors.getCollectionById(state, id)) ||
    {};
  const recipientAccountId = useSelector(
    giftingRequestsSelectors.getRecipientAccountId
  );
  const collectedGifts =
    useSelector((state) =>
      giftsCacheSelectors.getCollectedGiftIds(state, recipientAccountId)
    ) || emptyArr;
  const collectedGiftsFromThisCollection = useMemo(
    () => giftIds?.filter((x) => collectedGifts.includes(x)),
    [giftIds, collectedGifts]
  );

  const hasCompletedCollection =
    collectedGiftsFromThisCollection?.length === giftIds?.length;

  if (!giftIds || !name) {
    return null;
  }

  return (
    <div
      className={styles.collection}
      data-testid={`collection-${id}`}
      data-collection={id}
    >
      <Typography
        type={TYPOGRAPHY_TYPE.HEADLINE5}
        className={styles.collectionTitle}
        as="div"
      >
        <span data-testid="name-and-counter">
          {name}
          {!hasCompletedCollection && (
            <span className={styles.counter}>
              {`${collectedGiftsFromThisCollection.length}/${giftIds.length}`}
            </span>
          )}
        </span>
        {!hasCompletedCollection && (
          <span className={styles.reward}>
            <FormattedMessage
              id="gifts.collection.reward"
              defaultMessage="Reward: {reward}"
              values={{
                reward: (
                  <span className={styles.diamondAndPoints}>
                    <DiamondIcon16 className={styles.diamond} />
                    {rewardPoints}
                  </span>
                ),
              }}
            />
          </span>
        )}
      </Typography>
      <div className={styles.giftsGrid}>
        {giftIds.map((x) => (
          <Gift
            key={x}
            id={x}
            collectible
            collected={collectedGifts.includes(x)}
            categoryId={categoryId}
            className={styles.gift}
            responsiveStyleType={RESPONSIVE_TYPE.RESPONSIVE}
          />
        ))}
      </div>
    </div>
  );
};

Collection.propTypes = {
  id: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
};

const Group = ({ id, categoryId }) => {
  const group = useSelector((state) =>
    giftsCacheSelectors.getGroupById(state, id)
  );

  if (!group) {
    return null;
  }

  const { giftIds, name } = group;

  return (
    <div className={styles.collection} data-testid={`group-${id}`}>
      <div className={styles.collectionTitle}>
        <span data-testid="name">{name}</span>
        <span data-testid="name">{name}</span>
      </div>
      <div className={styles.giftsGrid}>
        {giftIds.map((x) => (
          <Gift
            key={x}
            id={x}
            categoryId={categoryId}
            giftSelectionOrigin={GIFT_ORIGIN.GIFT_DRAWER}
            realTimeGiftFromType={
              RealTimeRecommendationsGiftFromType.GIFT_DRAWER
            }
            className={styles.gift}
            responsiveStyleType={RESPONSIVE_TYPE.RESPONSIVE}
          />
        ))}
      </div>
    </div>
  );
};

Group.propTypes = {
  id: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
};

const categoryContentSelector = (categoryId) => (state) => {
  const defCat = giftsCacheSelectors.getCategoryById(state, categoryId);
  const customCat = customDrawerSelectors.getCategoryById(state, categoryId);
  const isLoggedIn = loginSelectors.isLoggedIn(state);
  if (customCat && isLoggedIn) {
    return {
      ...customCat,
      name: customCat.name || defCat.name,
    };
  }

  return defCat;
};

const Category = forwardRef(({ className, categoryId }, ref) => {
  const {
    giftIds = [],
    collectionIds = [],
    groupIds = [],
    name,
  } = useSelector(
    useMemo(() => categoryContentSelector(categoryId), [categoryId]),
    shallowEqual
  ) || {};

  const isMobile = useBreakpointMobileLayout();

  const initCategory = useCallback((e) => {
    ref.current.push(e);
  }, []);

  return (
    <div
      ref={initCategory}
      data-category={categoryId}
      className={classnames(
        styles.root,
        isMobile && styles.autoHeight,
        className
      )}
      data-testid={`content-${categoryId}`}
    >
      {giftIds.length > 0 && (
        <div className={styles.giftsContainer}>
          <div className={styles.collectionTitle}>{name}</div>
          <div className={styles.giftsGrid}>
            {giftIds.map((x) => (
              <Gift
                key={x}
                id={x}
                categoryId={categoryId}
                giftSelectionOrigin={GIFT_ORIGIN.GIFT_DRAWER}
                realTimeGiftFromType={
                  RealTimeRecommendationsGiftFromType.GIFT_DRAWER
                }
                className={styles.gift}
                responsiveStyleType={RESPONSIVE_TYPE.RESPONSIVE}
              />
            ))}
          </div>
        </div>
      )}
      {collectionIds.map((x) => (
        <Collection key={x} id={x} categoryId={categoryId} />
      ))}
      {groupIds.map((x) => (
        <Group key={x} id={x} categoryId={categoryId} />
      ))}
    </div>
  );
});

Category.displayName = "Category";

Category.propTypes = {
  categoryId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default memo(Category);
