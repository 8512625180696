import React, { FC, MouseEvent, memo } from "react";
import classnames from "classnames";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { SelectOption } from "./Select";
import { ReactComponent as IconChecked } from "img/common/select/checked_24.svg";
import styles from "./Select.scss";

interface SelectOptionItemProps {
  inShader: boolean;
  isHighlighted: boolean;
  isSelected: boolean;
  onClick: (e: MouseEvent<HTMLLIElement>) => void;
  onMouseEnter: () => void;
  option: SelectOption;
}

const SelectOptionItem: FC<SelectOptionItemProps> = ({
  option,
  isSelected,
  onClick,
  onMouseEnter,
  inShader,
  isHighlighted,
}) => (
  <li
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    key={option.value}
    data-value={option.label}
    className={classnames(styles.option, {
      [styles.shader]: inShader,
      [styles.highlighted]: isHighlighted,
    })}
  >
    {/* ToDo https://tango-me.atlassian.net/browse/WEB-6126 */}
    <Typography
      type={TYPOGRAPHY_TYPE.PARAGRAPH3}
      className={classnames(styles.optionLabel, "Select-optionLabel", {
        [styles.shader]: inShader,
      })}
    >
      {option.label}
    </Typography>

    {isSelected && <IconChecked className={styles.checkedIcon} />}
  </li>
);

export default memo(SelectOptionItem);
