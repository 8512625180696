import React, { memo, useCallback, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import classnames from "classnames";
import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames, SCREEN_NAME } from "@analytics/enums";
import { getTheAppBannerEnabled } from "environment";
import { Breakpoints } from "src/enums";
import getAppDeeplink, {
  AF_SMART_LINK_BUTTON_NAME,
} from "src/utils/getAppDeeplink";
import { deviceInfoSelectors } from "state/selectors";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useExclusiveClickHandler from "ui/hooks/useExclusiveClickHandler";
import useUiAction from "ui/hooks/useUiAction";
import {
  linkToChat,
  linkToFeed,
  linkToFollowingTab,
  linkToLeaders,
  linkToLiveStream,
} from "ui/navigation/links";
import useBannerStatus from "ui/scenes/live/shared/useBannerStatus";
import { ReactComponent as CloseIcon } from "img/close.svg";
import styles from "./Banner.scss";

const SCREEN_PATH_TO_NAME_MAPPING: Record<string, SCREEN_NAME> = {
  [linkToLiveStream]: SCREEN_NAME.LIVE_TAB,
  [linkToFollowingTab]: SCREEN_NAME.FOLLOWING_TAB,
  [linkToChat]: SCREEN_NAME.CHAT_TAB,
  [linkToFeed]: SCREEN_NAME.TIMELINE,
  [linkToLeaders]: SCREEN_NAME.LEADERS,
};
const Banner: React.FC = () => {
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const breakpoint = useBreakpoint();
  const { isBannerShown, dismissBanner } = useBannerStatus();
  const isEnabled = getTheAppBannerEnabled();
  const match = useRouteMatch([
    linkToLiveStream,
    linkToFollowingTab,
    linkToChat,
    linkToFeed,
    linkToLeaders,
  ]);
  const path = match?.path;
  const screen = path ? SCREEN_PATH_TO_NAME_MAPPING[path] : undefined;
  const goToStore = useCallback(() => {
    window.open(
      getAppDeeplink(deviceType, {
        buttonName: AF_SMART_LINK_BUTTON_NAME.HOME_PAGE,
      })
    );
  }, [deviceType]);

  const dismiss = useExclusiveClickHandler(dismissBanner);

  const typographyType = useMemo(() => {
    if (breakpoint === Breakpoints.TABLET) {
      return TYPOGRAPHY_TYPE.HEADLINE3;
    }

    if (
      breakpoint === Breakpoints.MOBILE ||
      breakpoint === Breakpoints.SMALL_MOBILE
    ) {
      return TYPOGRAPHY_TYPE.HEADLINE4;
    }

    return TYPOGRAPHY_TYPE.HEADLINE5;
  }, [breakpoint]);

  const handleBannerClick = useUiAction({
    target: "get_the_app_click",
    callback: goToStore,
    additionalParams: {
      [EventFields.TANGO_SCREEN]: screen,
    },
  });

  const handleDismissClick = useUiAction({
    target: "get_the_app_close",
    callback: dismiss,
    additionalParams: {
      [EventFields.TANGO_SCREEN]: screen,
    },
  });

  useEffect(() => {
    if (isBannerShown) {
      emitEvent(EventNames.BANNER_SHOWN, {
        item_type: "get_the_app",
        [EventFields.TANGO_SCREEN]: screen,
      });
    }
  }, [isBannerShown]);

  return isEnabled && isBannerShown ? (
    <div
      className={classnames(styles.root, styles[breakpoint])}
      role="button"
      tabIndex={0}
      onClick={handleBannerClick}
    >
      <div className={styles.container}>
        <Typography className={styles.title} type={typographyType}>
          <FormattedMessage
            id="get.the.best.tango.experience"
            defaultMessage="Get the Best Tango Experience!"
          />
        </Typography>
        <Button
          className={styles.install}
          size={ButtonSize.BIG_48}
          variant={ButtonVariant.PRIMARY}
        >
          <FormattedMessage id="install" defaultMessage="Install" />
        </Button>
        <Button
          size={ButtonSize.MEDIUM_32}
          variant={ButtonVariant.ICON_ONLY}
          className={styles.close}
          onClick={handleDismissClick}
        >
          <CloseIcon />
        </Button>
      </div>
    </div>
  ) : null;
};

export default memo(Banner);
