import React, { useRef, useEffect, memo } from "react";
import useIntersectionObserver, {
  Options,
} from "ui/hooks/useIntersectionObserver";
import { VoidCallback } from "src/types/common";

const style = { width: "1px", height: "1px", flexShrink: "0" };

const intersectionObserverDefaultProps = { rootMargin: "300px 0px 300px 0px" };

interface BottomMarkerProps {
  onReached?: VoidCallback;
  onHidden?: VoidCallback;
  intersectionObserverProps?: Options;
}

const BottomMarker: React.FC<BottomMarkerProps> = ({
  onReached,
  onHidden,
  intersectionObserverProps = intersectionObserverDefaultProps,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const isInView = useIntersectionObserver(rootRef, intersectionObserverProps);
  useEffect(() => {
    if (isInView) {
      onReached && onReached();
    } else {
      onHidden && onHidden();
    }
  }, [isInView, onReached, onHidden]);

  return <div style={style} ref={rootRef} />;
};

export default memo(BottomMarker);
