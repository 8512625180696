import React, { memo } from "react";
import classnames from "classnames";
import { VoidCallback } from "src/types/common";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import { ReactComponent as IconArrow } from "img/ic-chev.svg";
import styles from "./CarouselButton.scss";

interface CarouselButtonProps {
  className?: string;
  disabled?: boolean;
  onClick: VoidCallback;
  type?: "next" | "prev";
}

const CarouselButton: React.FC<CarouselButtonProps> = ({
  className,
  onClick,
  type = "prev",
  disabled = false,
}) => (
  <div
    className={classnames(styles.root, styles[type], className)}
    data-testid={`carousel-button-${type}`}
  >
    <Button
      size={ButtonSize.CIRCLE_MEDIUM_40}
      variant={ButtonVariant.SECONDARY}
      onClick={onClick}
      disabled={disabled}
    >
      <IconArrow className={styles.icon} />
    </Button>
  </div>
);

export default memo(CarouselButton);
