import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { MessageDescriptor, defineMessages } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  BI_LANDING_FIELD,
  EventFields,
  PURCHASE_ORIGIN,
} from "@analytics/enums";
import { mintroutePayment } from "api/shop";
import ApiError from "src/api/utils/apiError";
import { Currency } from "src/enums";
import { openLoginView } from "src/features/signin/exports/state/flows";
import { ComponentWithClassName } from "src/types/common";
import { PaymentProvider, PaymentType } from "src/types/payment";
import useEventPurchaseFunctions from "src/ui/hooks/useEventPurchaseFunctions";
import { ShopOffer } from "src/ui/scenes/landingPage/types";
import { getIsVouchersPersonalOffersEnabled } from "state/abTests";
import { updateMintrouteSuspendedStatus } from "state/flows/mintroute";
import { loginSelectors, userSelectors } from "state/selectors";
import { mintrouteShopActionCreators } from "state/tree/shop";
import { SUMMARY_STATE } from "ui/modal/modalViews/buyCoins/Summary";
import PayButton from "ui/modal/modalViews/buyCoins/common/components/PayButton/PayButton";
import { useSourceType } from "ui/modal/modalViews/buyCoins/common/hooks/useSourceType";
import { isMintroutePinValid } from "ui/modal/modalViews/buyCoins/common/validators";
import { AnalyticsParams, Option } from "ui/modal/modalViews/buyCoins/types";
import { getPurchaseAnalyticsParams } from "ui/modal/modalViews/buyCoins/utils/getPurchaseAnalyticsParams";

const CODE_DISABLED = 5;
const CODE_ATTEMPTS_EXCEEDED = 6;
const CODE_MCOINZ_INVALID_PIN = 101;
const CODE_MCOINZ_VOUCHER_CANCELLED = 102;
const CODE_MCOINZ_PREVIOUSLY_REDEEMED = 103;
const CODE_MCOINZ_VOUCHER_EXPIRED = 104;
const CODE_MCOINZ_INCORRECT_AMOUNT_OR_DENOMINATION = 105;

interface MintroutePaymentResponse {
  errorCode: number;
  success: boolean;
}

const customErrorMessages: Record<number, MessageDescriptor> = defineMessages({
  [CODE_DISABLED]: {
    id: "buy-coins.gift-card.transaction-error.disabled",
    defaultMessage:
      "Mintroute payment method is currently unavailable. Please try again later",
  },
  [CODE_ATTEMPTS_EXCEEDED]: {
    id: "buy-coins.gift-card.transaction-error.exceeded",
    defaultMessage:
      "Suspended. Too many failed attempts for voucher activation",
  },
  [CODE_MCOINZ_INVALID_PIN]: {
    id: "buy-coins.gift-card.transaction-error.invalid-pin",
    defaultMessage:
      "Voucher number is invalid. Please check it or contact Mintroute support",
  },
  [CODE_MCOINZ_VOUCHER_CANCELLED]: {
    id: "buy-coins.gift-card.transaction-error.voucher-cancelled",
    defaultMessage:
      "This voucher seems to be canceled. Please contact Mintroute support",
  },
  [CODE_MCOINZ_PREVIOUSLY_REDEEMED]: {
    id: "buy-coins.gift-card.transaction-error.already-redeemed",
    defaultMessage:
      "This voucher has already been activated. Please contact support in case it wasn't you",
  },
  [CODE_MCOINZ_VOUCHER_EXPIRED]: {
    id: "buy-coins.gift-card.transaction-error.expired",
    defaultMessage: "This voucher has expired",
  },
  [CODE_MCOINZ_INCORRECT_AMOUNT_OR_DENOMINATION]: {
    id: "buy-coins.gift-card.transaction-error.wrong-denomination",
    defaultMessage:
      "Offer and voucher are not matched. Please make sure that you selected correct currency.",
  },
});

const mockOption = {
  id: PaymentType.MINTROUTE,
  optionType: PaymentType.MINTROUTE,
} as Option;

interface SummaryStateType {
  errorMessage?: MessageDescriptor;
  summaryType: string;
}

interface ApplyPinButtonParams {
  analyticsParams: AnalyticsParams;
  currency: Currency;
  offer: ShopOffer;
  pin: string;
  purchaseSource: PURCHASE_ORIGIN | number;
  setPin: React.Dispatch<React.SetStateAction<string>>;
  setSummaryState: Dispatch<SetStateAction<SummaryStateType | string>>;
  summaryState: string;
}

const ApplyPinButton: ComponentWithClassName<ApplyPinButtonParams> = ({
  className,
  offer,
  purchaseSource,
  pin,
  setPin,
  summaryState,
  setSummaryState,
  currency,
  analyticsParams,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(userSelectors.getMyAccountId);
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const isVouchersPersonalOffersEnabled = useSelector(
    getIsVouchersPersonalOffersEnabled
  );
  const processPaymentAfterLogin = useRef(false);
  const checkBanOnSummaryStateChange = useRef(false);
  const source = useSourceType();

  const personaOffersAnalyticsParams = useMemo(() => {
    if (isVouchersPersonalOffersEnabled) {
      return {
        ...getPurchaseAnalyticsParams({
          ...analyticsParams,
          [BI_LANDING_FIELD.PRICE_POINT_ID]: offer.pricePointId,
          [EventFields.SHARE_SOURCE_ID]: PURCHASE_ORIGIN.MINTROUTE,
        }),
        source,
      };
    }

    return {};
  }, [
    analyticsParams,
    isVouchersPersonalOffersEnabled,
    offer.pricePointId,
    source,
  ]);

  const { doEmitPurchaseFail, doEmitPurchaseInit } = useEventPurchaseFunctions({
    offer,
    currency,
    purchaseSource,
    provider: PaymentProvider.MINTROUTE,
    userId,
  });

  useLayoutEffect(() => {
    if (
      summaryState === SUMMARY_STATE.SUMMARY &&
      checkBanOnSummaryStateChange.current
    ) {
      // setting immediately cuz 99.9999% is banned, but checking with server just in case
      dispatch(mintrouteShopActionCreators.setSuspendedStatus(true));
      dispatch(updateMintrouteSuspendedStatus());
      checkBanOnSummaryStateChange.current = false;
    }
  }, [summaryState]);

  const executePayment = useCallback(() => {
    setSummaryState(SUMMARY_STATE.PROCESSING);
    mintroutePayment({
      pinCode: pin,
      marketOfferId: offer.marketOfferId,
      currency,
      offerVersion: offer.version,
      ...personaOffersAnalyticsParams,
    })
      .then(({ success, errorCode }: MintroutePaymentResponse) => {
        if (success) {
          setSummaryState(SUMMARY_STATE.SUCCESS);
          setPin("");
          doEmitPurchaseInit("");

          return;
        }

        const errorMessage = customErrorMessages[errorCode];

        setSummaryState({
          summaryType: SUMMARY_STATE.ERROR,
          errorMessage,
        });
        doEmitPurchaseFail("", errorMessage);
        checkBanOnSummaryStateChange.current =
          errorCode === CODE_ATTEMPTS_EXCEEDED;
      })
      .catch((error) => {
        if (error instanceof ApiError) {
          doEmitPurchaseFail("", JSON.stringify(error.body));
        }

        setSummaryState(SUMMARY_STATE.ERROR);
      });
  }, [
    setSummaryState,
    pin,
    offer.marketOfferId,
    offer.version,
    currency,
    personaOffersAnalyticsParams,
    doEmitPurchaseFail,
    setPin,
    doEmitPurchaseInit,
  ]);

  const onClick = useCallback(() => {
    if (isLoggedIn) {
      executePayment();
    } else {
      processPaymentAfterLogin.current = true;
      dispatch(openLoginView());
    }
  }, [executePayment, isLoggedIn]);

  useEffect(() => {
    processPaymentAfterLogin.current = false;
  }, [offer, pin]);
  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    if (processPaymentAfterLogin.current && isMintroutePinValid(pin)) {
      executePayment();
    }
    processPaymentAfterLogin.current = false;
  }, [executePayment, isLoggedIn, pin]);

  return (
    <PayButton
      option={mockOption}
      className={className}
      onClick={onClick}
      disabled={!isMintroutePinValid(pin)}
      offer={offer}
    />
  );
};

export default ApplyPinButton;
