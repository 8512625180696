import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { MessageDescriptor, useIntl } from "react-intl";
import { useWithUnreadMessagesTitle } from "ui/hooks/useWithUnreadMessagesTitle";

interface FormattedMessageTitleProps extends MessageDescriptor {
  isOverrideTitle?: boolean;
}

const FormattedMessageTitle: React.FC<FormattedMessageTitleProps> = (props) => {
  const { isOverrideTitle, ...rest } = props;
  const intl = useIntl();
  const formattedTitle = intl.formatMessage(rest);
  const title = useWithUnreadMessagesTitle(formattedTitle);

  return (
    <Helmet
      defaultTitle={isOverrideTitle ? title : ""}
      title={isOverrideTitle ? undefined : formattedTitle}
    />
  );
};

export default memo(FormattedMessageTitle);
