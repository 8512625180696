import React, { memo } from "react";
import Spinner from "ui/common/Spinner";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import { FormattedMessage } from "react-intl";
import messages from "ui/common/intl/sharedMessages";
import { VoidCallback } from "src/types/common";
import styles from "./FailedView.scss";

interface FailedViewProps {
  onRetryClick: VoidCallback;
  isLoading: boolean;
}

const FailedView: React.FC<FailedViewProps> = ({ onRetryClick, isLoading }) =>
  isLoading ? (
    <Spinner className={styles.spinner} />
  ) : (
    <Button
      size={ButtonSize.MEDIUM_32}
      variant={ButtonVariant.SECONDARY}
      className={styles.retry}
      onClick={onRetryClick}
      data-testid="infinite-scroller-load-failed-retry"
    >
      <FormattedMessage {...messages.retry} />
    </Button>
  );

export default memo(FailedView);
