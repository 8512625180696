import React, { FC, memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { getIsDiamondViewToggleEnabled } from "state/abTests";
import { RootState } from "state/delegate";
import { profilesCacheSelectors } from "state/selectors";
import { FormattedNumber, RoundedNumber } from "ui/common/Formatted";
import { decimalWithRoundingToDown } from "ui/common/stats/constants";
import styles from "./DiamondsCount.scss";

interface DiamondsCountProps {
  accountId: string;
  isDiamondViewToggleEnabled?: boolean;
}

const DiamondsCount: FC<DiamondsCountProps> = ({
  accountId,
  isDiamondViewToggleEnabled,
}) => {
  const [isDiamondViewRounded, setIsDiamondViewRounded] = useState(true);

  const isDiamondViewToggleEnabledSOC = useSelector(
    getIsDiamondViewToggleEnabled
  );
  const liveStats = useSelector((state: RootState) =>
    profilesCacheSelectors.getLiveStats(state, accountId)
  );

  const liveStatsPoints = liveStats?.points || 0;

  const onDiamondViewToggle = useCallback(() => {
    setIsDiamondViewRounded((value) => !value);
  }, []);

  if (!isDiamondViewToggleEnabled || !isDiamondViewToggleEnabledSOC) {
    return (
      <span data-testid="profile-statistics-earned">
        <RoundedNumber
          value={liveStatsPoints}
          options={decimalWithRoundingToDown}
        />
      </span>
    );
  }

  return (
    <span
      className={styles.diamondViewToggle}
      data-testid="profile-statistics-earned"
      onClick={onDiamondViewToggle}
    >
      {isDiamondViewRounded ? (
        <RoundedNumber
          value={liveStatsPoints}
          options={decimalWithRoundingToDown}
        />
      ) : (
        <FormattedNumber value={liveStatsPoints} />
      )}
    </span>
  );
};

export default memo(DiamondsCount);
