import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { useWithUnreadMessagesTitle } from "ui/hooks/useWithUnreadMessagesTitle";

const baseTemplate = "%s | Tango Live";

const BaseTitle = () => {
  const title = useWithUnreadMessagesTitle(baseTemplate);

  return (
    <Helmet
      defaultTitle="Tango Live Streaming Platform | 1x1 Video Calls with Strangers"
      titleTemplate={title}
    />
  );
};

export default memo(BaseTitle);
