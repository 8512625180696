import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { UIActionTarget } from "@analytics/enums";
import { Nullable } from "src/types/common";
import OfferCard from "ui/modal/modalViews/buyCoins/offers/card/OfferCard";
import {
  DEFAULT_OFFER_CARD_SIZE,
  OfferCardTypes,
  OfferCardVariants,
} from "ui/modal/modalViews/buyCoins/offers/card/enums";
import { ShopOffer } from "ui/scenes/landingPage/types";
import styles from "./OfferPicker.scss";

interface OfferPickerProps {
  analyticsParams: Record<string, unknown>;
  className: string;
  isMobile: boolean;
  offers: ShopOffer[];
  onOfferClick: (offer: ShopOffer) => void;
  selectedOffer: Nullable<ShopOffer>;
}

const OfferPicker = ({
  offers,
  selectedOffer,
  onOfferClick,
  className,
  isMobile,
  analyticsParams,
}: OfferPickerProps) => (
  <div
    className={classnames(styles.root, isMobile && styles.mobile, className)}
  >
    <div className={styles.label}>
      <FormattedMessage
        id="buy-coins.gift-card.offer-picker"
        defaultMessage="Select value"
      />
    </div>
    <div className={styles.grid} data-testid="offer-picker">
      {offers.map((x, index) => (
        <OfferCard
          key={x.marketOfferId}
          className={styles.offer}
          offer={x}
          selected={x.offerId === selectedOffer?.offerId}
          onClick={onOfferClick}
          biCardIndex={index}
          analyticsParams={analyticsParams}
          target={UIActionTarget.OFFER_CLICKED}
          variant={OfferCardVariants.MINTROUTE}
          type={OfferCardTypes.LANDING}
          size={DEFAULT_OFFER_CARD_SIZE}
        />
      ))}
    </div>
  </div>
);

export default OfferPicker;
