import { CASHIER, LOYALTY_OFFERS, MINTROUTE, REFILL } from "enums/cashier";
import { PersonalOfferType } from "src/enums";

export const DEFAULT_DISCOUNT_PERCENT = 0.2;

export const VERSION = "personal-offers-version";

export const OFFER_TYPES = {
  [CASHIER]: PersonalOfferType.CASHIER,
  [REFILL]: PersonalOfferType.REFILL,
  [LOYALTY_OFFERS]: PersonalOfferType.LOYALTY_OFFERS,
  [MINTROUTE]: PersonalOfferType.MINTROUTE,
};
