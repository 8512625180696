import React, { memo } from "react";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { ensureHttps } from "src/utils/ensureHttps";
import { FormattedNumber } from "ui/common/Formatted";
import PersonalPriceWithDiscount from "ui/common/PersonalPriceWithDiscount";
import { DEFAULT_DISCOUNT_PERCENT } from "ui/common/personalOffer/constants";
import { PersonalOffersCardProps } from "ui/common/personalOffer/types";
import { SkeletonWrapper } from "ui/common/skeleton";
import { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import useOfferClickAction from "ui/modal/modalViews/buyCoins/offers/useOfferClickAction";
import { ReactComponent as Coin } from "img/ic_coin.svg";
import styles from "./MintrouteCardView.scss";

const MintrouteCardView: React.FC<PersonalOffersCardProps> = ({
  offer,
  onClick,
  analyticsParams,
  className,
  disabled,
  loading,
  selected,
}) => {
  const {
    offers: [{ coins, currencyPrice, bonus, marketOfferId }],
  } = offer;
  const handleClick = useOfferClickAction(offer, onClick, analyticsParams);

  return (
    <SkeletonWrapper className={classnames(styles.skeleton)} loading={loading}>
      <div
        className={classnames(className, styles.root, {
          [styles.disabled]: disabled,
          [styles.selected]: selected,
        })}
        onClick={handleClick}
        onTouchStart={emptyFunction}
        data-testid={marketOfferId}
        role="button"
        tabIndex={0}
      >
        <div className={styles.price}>
          <PersonalPriceWithDiscount
            offer={{
              currencyPrice,
              discountPercent: bonus || DEFAULT_DISCOUNT_PERCENT,
              usdPrice: offer.price,
            }}
            typographyPriceType={TYPOGRAPHY_TYPE.HEADLINE4}
            hideOriginalPrice
          />
        </div>
        <img
          src={ensureHttps(offer.imageUrl || "")}
          className={styles.image}
          alt="offer"
          data-testid="offer-icon"
        />
        <div className={styles.credit}>
          <Coin className={styles.coin} />
          <FormattedNumber value={coins || 0} />
        </div>
      </div>
    </SkeletonWrapper>
  );
};

export default memo(MintrouteCardView);
