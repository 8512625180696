import { FullOffer } from "ui/scenes/landingPage/types";
import { transformOffer } from "state/tree/shop";
import { ModifiedShopOffer } from "ui/common/webviewOffersView/types";

export const transformFullOffer = (offer: FullOffer) =>
  transformOffer({
    image: undefined,
    credits: offer.credit,
    ...offer,
    currencyPrice: [{ currency: offer.currency, price: offer.amount }],
  }) as ModifiedShopOffer;

export const onErrorNotifyPlatform = () => {
  try {
    console.log("Calling android inner function to reload webview"); // eslint-disable-line no-console
    window.tangoAndroidWebview.invalidateToken();
  } catch (e) {
    // noop
  }
};
