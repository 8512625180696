import React, {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { emitEvent } from "@analytics/emit";
import {
  BI_LANDING_FIELD,
  EventNames,
  RegistrationSource,
  SCREEN_NAME,
} from "@analytics/enums";
import { ScreenViewReporter } from "@analytics/screenView/ScreenViewReportingContext";
import { CASHIER, REFILL } from "enums/cashier";
import {
  BottomScreenType,
  Breakpoints,
  LandingPageDismissType,
} from "src/enums";
import { isWebviewEnabled } from "src/environment";
import { openLoginView } from "src/features/signin/exports/state/flows";
import { openSignUpBottomScreen } from "src/state/actionCreators/bottomScreen";
import { RootState } from "src/state/delegate";
import { Nullable } from "src/types/common";
import {
  PersonalOffersPurchaseSource,
  PersonalOffersViewType,
  PricePoint,
} from "src/types/personalOffer";
import { useBreakpoint } from "src/ui/hooks/useBreakpoint";
import {
  loginSelectors,
  showGiftCardBannerByCountryCodeSelector,
  specialOffersSelectors,
} from "state/selectors";
import { ThemeContext } from "ui/Theme";
import GiftCardBanner from "ui/common/banner/GiftCardBanner";
import OffersHeading from "ui/common/personalOffer/OffersHeading";
import OfferCard from "ui/common/personalOffer/card/OfferCard";
import SpecialOffer from "ui/common/specialOffer/SpecialOffer";
import {
  OFFER_CARD_TYPES_MAP,
  OfferCardSizes,
  OfferCardVariants,
} from "ui/modal/modalViews/buyCoins/offers/card/enums";
import { BannerOffer } from "ui/scenes/landingPage/types";
import styles from "./Offers.scss";

interface PersonalOffersProps {
  analyticsParams?: Record<string, unknown>;
  className?: string;
  disabled: boolean;
  loading?: boolean;
  offers: PricePoint[];
  personalOfferId?: string;
  promotionType?: LandingPageDismissType;
  purchaseSource?: PersonalOffersPurchaseSource;
  selectOffer: (offer: BannerOffer | PricePoint) => void;
  startTimestamp?: number;
  triggerId?: string;
  viewType?: PersonalOffersViewType;
}

const preventParentClick = (e: MouseEvent) => {
  e.stopPropagation();
};

const selector = (state: RootState) => ({
  isLoggedIn: loginSelectors.isLoggedIn(state),
  showGiftCardBannerByCC: showGiftCardBannerByCountryCodeSelector(state),
  specialOffer: specialOffersSelectors.getSpecialOffers(state),
});

const PersonalOffers: FC<PersonalOffersProps> = ({
  selectOffer,
  disabled,
  loading,
  className,
  purchaseSource,
  viewType = REFILL,
  promotionType,
  offers,
  triggerId,
  personalOfferId,
  startTimestamp,
  analyticsParams,
}) => {
  const dispatch = useDispatch();
  const { dark } = useContext(ThemeContext);
  const isCashier = viewType === CASHIER;
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);
  const isWebview = isWebviewEnabled();
  const isDesktop = useBreakpoint() === Breakpoints.DESKTOP;
  const { isLoggedIn, showGiftCardBannerByCC, specialOffer } = useSelector(
    selector,
    shallowEqual
  );

  useEffect(() => {
    emitEvent(EventNames.PERSONAL_OFFERS_PAGE_DISPLAYED, {
      [BI_LANDING_FIELD.TRIGGER_ID]: triggerId,
      [BI_LANDING_FIELD.TIME_LOADING]: startTimestamp
        ? Date.now() - startTimestamp
        : null,
      [BI_LANDING_FIELD.SOURCE]: viewType,
    });
  }, [viewType, triggerId]);

  const handleOfferClick = useCallback(
    (offer) => {
      if (isLoggedIn) {
        selectOffer(offer);

        return;
      }

      if (isDesktop) {
        dispatch(
          openLoginView({
            registrationSource: RegistrationSource.SELF_PROFILE_ICON,
          })
        );
      } else {
        dispatch(
          // @ts-ignore - remove this when actionCreators/bottomScreen.js will be typed
          openSignUpBottomScreen({
            screenType: BottomScreenType.SIGN_UP_BOTTOM_SCREEN_WHITE,
            registrationSource: RegistrationSource.UNSUBSCRIBED_CASHIER_OFFER,
          })
        );
      }
    },
    [isLoggedIn, isDesktop, dispatch]
  );

  const specialOffers = useMemo(() => {
    if (!specialOffer) {
      return;
    }

    const {
      pricePoints: [offer],
      expirationDateMs: ts,
      sasTemplate,
      campaignId,
      purchaseLimit,
    } = specialOffer;

    return {
      ...offer,
      ts,
      sasPricePoint: offer.sasPricePoint,
      sasTemplate,
      campaignId,
      purchaseLimit,
    };
  }, [specialOffer]);

  const isBannersHidden = !showGiftCardBannerByCC && !specialOffer;

  return (
    <div
      className={classnames(styles.root, className)}
      onClick={preventParentClick}
    >
      <ScreenViewReporter name={SCREEN_NAME.PAYMENT_OFFERS} nested />
      {!isWebview && (
        <OffersHeading
          isDarkTheme={!isCashier || dark}
          promotionType={promotionType}
          isCashier={isCashier}
        />
      )}
      <div className={styles.offersContainer} ref={containerRef}>
        <div
          className={classnames(styles.banners, {
            [styles.hidden]: isBannersHidden,
          })}
        >
          {specialOffers && (
            <SpecialOffer
              key={specialOffers.campaignId}
              isCashier={isCashier}
              offer={specialOffers}
              onClick={handleOfferClick}
              disabled={disabled}
              className={styles.banner}
            />
          )}
          {showGiftCardBannerByCC && (
            <GiftCardBanner
              className={styles.banner}
              purchaseSource={purchaseSource}
              isCashier={isCashier}
            />
          )}
        </div>

        <div className={styles.offersGrid} data-testid="offers">
          {offers.map((pricePoint, index) => (
            <OfferCard
              key={pricePoint.id}
              className={styles.offer}
              onClick={handleOfferClick}
              offer={pricePoint}
              type={OFFER_CARD_TYPES_MAP[viewType]}
              size={OfferCardSizes.MEDIUM}
              variant={OfferCardVariants.REGULAR}
              disabled={disabled}
              loading={loading}
              triggerId={triggerId}
              personalOfferId={personalOfferId}
              biCardIndex={index}
              analyticsParams={analyticsParams}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonalOffers;
