import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { EventReasons } from "@analytics/enums";
import { deleteUserAccountRequest } from "api/profile";
import { logout } from "src/features/signin/exports/state/flows";
import { Nullable } from "src/types/common";
import logger from "src/utils/logger";
import { DeleteAccountConfirmationView } from "ui/common/deleteAccountView/DeleteAccountConfirmationView";
import DeleteAccountReasonView from "ui/common/deleteAccountView/DeleteAccountReasonView";
import { DeleteAccountRequest } from "ui/common/deleteAccountView/types";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useMyProfile from "ui/hooks/useMyProfile";
import styles from "ui/common/deleteAccountView/DeleteAccountView.scss";

interface DeleteAccountViewProps {
  onDismiss: VoidFunction;
}

const DeleteAccountView = ({ onDismiss }: DeleteAccountViewProps) => {
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const history = useHistory();
  const { basicProfile } = useMyProfile(true);

  const [reasonData, setReasonData] =
    useState<Nullable<DeleteAccountRequest>>(null);

  const handleReasonSubmit = useCallback(
    (deleteAccountRequest: DeleteAccountRequest) =>
      setReasonData(deleteAccountRequest),
    []
  );

  const handleConfirmationSubmit = useCallback(async () => {
    try {
      await deleteUserAccountRequest(reasonData);
      dispatch(
        logout({
          reason: EventReasons.SELF_LOGOUT,
          history,
        })
      );
    } catch (error) {
      logger.error("UserDeleteAccount deleteUserAccountRequest error", error);
    }
  }, [reasonData, history, dispatch]);

  return (
    <div className={classNames(styles.root, styles[breakpoint])}>
      {reasonData ? (
        <DeleteAccountConfirmationView
          userName={basicProfile?.firstName}
          onSubmit={handleConfirmationSubmit}
          onCancel={onDismiss}
        />
      ) : (
        <DeleteAccountReasonView
          onSubmit={handleReasonSubmit}
          onCancel={onDismiss}
        />
      )}
    </div>
  );
};

export default DeleteAccountView;
