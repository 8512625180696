import { shallowEqual, useSelector } from "react-redux";
import { getSupportedTranslationsLanguages } from "state/abTests";
import { RootState } from "state/delegate";
import { deviceInfoSelectors } from "state/selectors";
import { isoCodeToName } from "ui/common/dropdownMenu/menuList/components/languageSwitcher/constants";

const selector = (state: RootState) => ({
  locale: deviceInfoSelectors.getDeviceLocale(state),
  socSupportedLanguages: getSupportedTranslationsLanguages(state),
});

export const useLanguagesList = () => {
  const { locale, socSupportedLanguages } = useSelector(selector, shallowEqual);

  return {
    locale,
    options: socSupportedLanguages.map((language) => ({
      value: language,
      label: isoCodeToName[language] || language,
    })),
  };
};
