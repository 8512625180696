import React, { memo, useCallback } from "react";
import { VoidCallback } from "src/types/common";
import Divider from "ui/common/divider/Divider";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import sharedMessages from "ui/common/intl/sharedMessages";
import useOpenIntercom from "ui/intercom/useOpenIntercom";
import { ReactComponent as CustomerSupportIcon } from "img/new-ui/burger_menu/ic-support.svg";

interface CustomerSupportItemProps {
  onClick?: VoidCallback;
  withDivider?: boolean;
}

const CustomerSupportItem: React.FC<CustomerSupportItemProps> = ({
  onClick,
  withDivider = false,
}) => {
  const openIntercom = useOpenIntercom();
  const handleClick = useCallback(() => {
    openIntercom();
    onClick?.();
  }, [onClick, openIntercom]);

  return (
    <>
      <DropdownListItem
        Icon={CustomerSupportIcon}
        titleMessage={sharedMessages.customerSupport}
        onClick={handleClick}
        data-testid="customer-support-menu-item"
      />
      {withDivider && <Divider />}
    </>
  );
};

export default memo(CustomerSupportItem);
