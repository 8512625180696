import React, { memo } from "react";
import { FormattedDisplayName, FormattedMessage } from "react-intl";
import classnames from "classnames";
import { ComponentWithClassName, ProfileDetails } from "src/types/common";
import { calculateAgeFromDate } from "src/utils/dateUtils";
import Typography, {
  TYPOGRAPHY_TYPE,
  TypographyProps,
} from "ui/common/typography/Typography";
import styles from "./UserCountryAndAge.scss";

interface UserCountryAndAgeProps {
  isDesktop?: boolean;
  isShaderTheme?: boolean;
  profileDetails: ProfileDetails;
  typographyType?: TypographyProps["type"];
}

const UserCountryAndAge: ComponentWithClassName<UserCountryAndAgeProps> = ({
  className,
  profileDetails,
  isShaderTheme,
  typographyType = TYPOGRAPHY_TYPE.PARAGRAPH3,
}) => {
  const { iso2CountryCode, birthday } = profileDetails;

  if (!birthday && !iso2CountryCode) {
    return null;
  }

  return (
    <Typography
      type={typographyType}
      className={classnames(
        styles.root,
        isShaderTheme && styles.shaderTheme,
        className
      )}
      data-testid="asl"
      as="div"
    >
      {!!iso2CountryCode && (
        <span data-testid="profile-country">
          <FormattedDisplayName
            type="region"
            value={iso2CountryCode.toUpperCase()}
            data-testid="asl-country"
          />
        </span>
      )}
      {!!birthday && (
        <span className={styles.age} data-testid="profile-age">
          <FormattedMessage
            id="profile.header.years_old_abbreviation"
            defaultMessage="{age} y.o."
            values={{
              age: calculateAgeFromDate(birthday),
            }}
          />
        </span>
      )}
    </Typography>
  );
};

export default memo(UserCountryAndAge);
