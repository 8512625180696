import React, { Suspense, forwardRef } from "react";
import lazy from "utils/lazyWithPageReload";
import { LottieAnimationProps } from "./LottieAnimationImpl";

const LottieAnimationImpl = lazy(
  () =>
    import(
      /* webpackChunkName: "lottieAnimationImpl" */ "./LottieAnimationImpl"
    )
);

const LazyLottieAnimation = forwardRef<HTMLDivElement, LottieAnimationProps>(
  (props, ref) => (
    <Suspense fallback={null}>
      <LottieAnimationImpl {...props} forwardedRef={ref} />
    </Suspense>
  )
);

LazyLottieAnimation.displayName = "LazyLottieAnimation";

export const fetchLottieAnimationImplChunk = async () => {
  await import(
    /* webpackChunkName: "lottieAnimationImpl" */ "./LottieAnimationImpl"
  );
};

export default LazyLottieAnimation;
