import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";
import { defineMessage, FormattedMessage } from "react-intl";
import { FormattedNumber } from "ui/common/Formatted";
import { useDispatch, useSelector } from "react-redux";
import {
  STREAM_TOP_GIFTERS_DEFAULT_LIMIT,
  STREAM_TOP_GIFTERS_IN_MODAL_LIMIT,
} from "src/constants";
import { setTopGiftersRequestLimit } from "state/actionCreators/viewerSession";
import { viewerSessionSelectors } from "state/selectors";
import { Breakpoints } from "src/enums";
import { ReactComponent as NoViewersIcon } from "img/no_viewers.svg";
import { ReactComponent as GuestIcon } from "img/ic_guest.svg";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import classnames from "classnames";
import TopGiftersListItem from "./TopGiftersListItem";
import batchLoadProfiles from "state/flows/batchLoadProfiles";
import Scrollbar, { ScrollbarVariant } from "ui/common/scrollbar/Scrollbar";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import styles from "./TopGiftersList.scss";

const noViewers = defineMessage({
  id: "no.viewers.yet",
  defaultMessage: "No Viewers yet.",
});

const TopGiftersList = ({
  className,
  wrapperClassName,
  mobile,
  noViewersMessage = noViewers,
}) => {
  const { topGifters, viewers, guestCount } = useSelector(
    useCallback(
      (state) => ({
        topGifters: viewerSessionSelectors.getTopGifters(state),
        viewers: viewerSessionSelectors.getViewerAccountIds(state),
        guestCount: viewerSessionSelectors.getGuestsCount(state),
      }),
      []
    )
  );
  const dispatch = useDispatch();
  const compactCta = useBreakpoint() === Breakpoints.TABLET;
  const guestsIcons = useMemo(
    () => Array.from({ length: Math.min(guestCount, 3) }),
    [guestCount]
  );

  useEffect(() => {
    dispatch(setTopGiftersRequestLimit(STREAM_TOP_GIFTERS_IN_MODAL_LIMIT));
    return () =>
      dispatch(setTopGiftersRequestLimit(STREAM_TOP_GIFTERS_DEFAULT_LIMIT));
  }, []);
  const emptyList = !topGifters.length && !viewers.length;

  useEffect(() => {
    dispatch(
      batchLoadProfiles({
        ids: [...topGifters, ...viewers],
        loadOnlyIfMissing: true,
        params: { basic: true, live: true },
      })
    );
  }, [topGifters, viewers]);

  const classes = classnames(
    styles.root,
    mobile && styles.mobile,
    wrapperClassName
  );

  if (emptyList) {
    return (
      <div className={classes} data-testid="top-gifters-empty-list">
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH3}
          className={styles.emptyList}
          as="div"
        >
          <NoViewersIcon className={styles.icon} />
          <FormattedMessage {...noViewersMessage} />
        </Typography>
      </div>
    );
  }

  return (
    <Scrollbar
      className={className}
      wrapperClassName={classes}
      variant={mobile ? ScrollbarVariant.ON_SHADER : ScrollbarVariant.LIGHT}
      data-testid="top-gifters-list"
    >
      {topGifters.map((accountId) => (
        <TopGiftersListItem
          key={accountId}
          accountId={accountId}
          compactCta={compactCta}
          mobile={mobile}
        />
      ))}
      {viewers.map((accountId) => (
        <TopGiftersListItem
          key={accountId}
          accountId={accountId}
          compactCta={compactCta}
          mobile={mobile}
          viewer
        />
      ))}
      {guestCount > 0 && (
        <div className={styles.guestCount}>
          {guestsIcons.map((_, index) => (
            // eslint-disable-next-line
            <GuestIcon key={index} className={styles.guestIcon} />
          ))}
          <FormattedMessage
            id="modal.top-gifters.guests-count"
            defaultMessage="and {count} guests"
            values={{ count: <FormattedNumber value={guestCount} /> }}
          />
        </div>
      )}
    </Scrollbar>
  );
};

TopGiftersList.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  mobile: PropTypes.bool,
  noViewersMessage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
  }),
};

export default TopGiftersList;
