import React from "react";
import PropTypes from "prop-types";
import GenericSummary from "../genericSummary/GenericSummary";
import { FormattedMessage } from "react-intl";
import errorIcon from "img/ic_error.svg?url";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";

const SuspendedView = ({ onClose }) => (
  <GenericSummary
    data-testid="summary-suspended"
    icon={errorIcon}
    title={
      <FormattedMessage
        id="buy-coins.gift-card.suspended.title"
        defaultMessage="This payment method is disabled due to suspicious actions"
      />
    }
    buttons={
      <Button
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.SECONDARY_ON_SHADER}
        onClick={onClose}
        data-testid="go-back"
      >
        <FormattedMessage
          id="buy-coins.gift-card.suspended.cta"
          defaultMessage="Go Back"
        />
      </Button>
    }
  />
);

SuspendedView.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SuspendedView;
