import React, { memo, useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import Avatar from "ui/common/avatar/Avatar";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import DisplayName from "ui/common/DisplayName";
import { ReactComponent as DiamondIcon16 } from "img/ic_diamond_16.svg";
import { ReactComponent as DiamondIcon12 } from "img/ic_diamond_12.svg";
import { RoundedNumber } from "ui/common/Formatted";
import { Breakpoints } from "src/enums";
import { ComponentWithClassName } from "src/types/common";
import { ExtendedStreamInfo } from "src/types/richFragment/Stream";
import { RootState } from "state/delegate";
import { profilesCacheSelectors } from "state/selectors";
import styles from "./StreamerInfo.scss";

const nameBreakpointToTypography = {
  [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.HEADLINE6,
  [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.HEADLINE6,
  [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.HEADLINE5,
  [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.HEADLINE4,
};

const pointsBreakpointToTypography = {
  [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.MINI,
  [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.MINI,
  [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.HEADLINE6,
  [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.HEADLINE6,
};

const StreamerInfo: ComponentWithClassName<{
  breakpoint: Breakpoints;
  broadcasterId: string;
  stream: ExtendedStreamInfo;
}> = ({ className, breakpoint, broadcasterId, stream }) => {
  const selector = useCallback(
    (state: RootState) => ({
      basicProfile: profilesCacheSelectors.getBasicProfile(
        state,
        broadcasterId
      ),
      points:
        profilesCacheSelectors.getLiveStats(state, broadcasterId)?.points || 0,
    }),
    [broadcasterId]
  );
  const { basicProfile: anchor, points: anchorPoints } = useSelector(
    selector,
    shallowEqual
  );
  const basicProfile = stream?.anchor || anchor;
  const points = stream?.anchorPoints || anchorPoints;

  return (
    <div className={classnames(styles[breakpoint], className)}>
      <Avatar className={styles.avatar} basicProfile={basicProfile} />
      <div className={styles.userInfo}>
        <Typography
          type={nameBreakpointToTypography[breakpoint]}
          className={styles.name}
          data-testid="name"
        >
          <DisplayName basicProfile={basicProfile} />
        </Typography>
        <Typography
          type={pointsBreakpointToTypography[breakpoint]}
          as="div"
          className={styles.points}
          data-testid="points"
        >
          {breakpoint === Breakpoints.DESKTOP ||
          breakpoint === Breakpoints.TABLET ? (
            <DiamondIcon16 className={styles.diamond} />
          ) : (
            <DiamondIcon12 className={styles.diamond} />
          )}
          <RoundedNumber value={points} />
        </Typography>
      </div>
    </div>
  );
};

export default memo(StreamerInfo);
