import React, { FC, memo, useCallback, useLayoutEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import { unlockPost } from "api/feed";
import { FanLevel, PostsFeedType, SubscriptionStatus } from "src/enums";
import { VoidCallback } from "src/types/common";
import { useBreakpoint } from "src/ui/hooks/useBreakpoint";
import { actionCreators as postFeedActionCreators } from "state/actionCreators/postsFeed";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import useExclusiveClickHandler from "ui/hooks/useExclusiveClickHandler";
import usePrevious from "ui/hooks/usePrevious";
import useSubscribeOnStreamer from "ui/hooks/useSubscribeOnStreamer";
import { ReactComponent as ViewersIcon } from "img/ic_eye_32.svg";
import { ReactComponent as FanLockedIcon } from "img/ic_fan_locked.svg";
import styles from "./PostUnlockOverlay.scss";

interface PrivateOverlayProps {
  authorId: string;
  category: PostsFeedType;
  isSubscribeFlowDisabled?: boolean;
  maxAvailableUnlockExclusivePostCount: number;
  onUnlockUnavailable?: VoidCallback;
  postId: string;
  unlocked: boolean;
}

const PostUnlockOverlay: FC<PrivateOverlayProps> = ({
  category,
  unlocked,
  postId,
  authorId,
  maxAvailableUnlockExclusivePostCount,
  isSubscribeFlowDisabled = false,
  onUnlockUnavailable,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const [isAnimationActive, setAnimationActive] = useState(false);
  const prevUnlocked = usePrevious(unlocked);
  const { currentSubscription, showSubscribeView } = useSubscribeOnStreamer({
    accountId: authorId,
  });
  const subscriptionLevel = currentSubscription?.details?.streamer?.level;

  const isUnlockAvailable =
    subscriptionLevel === FanLevel.FAN &&
    !!maxAvailableUnlockExclusivePostCount;

  const handleClick = useExclusiveClickHandler(
    useCallback(() => {
      if (isAnimationActive) {
        return;
      }

      if (isUnlockAvailable) {
        unlockPost({
          streamerId: authorId,
          accountPictureId: postId,
        })
          .then((res) =>
            dispatch(postFeedActionCreators.unlockSuccess(res, category))
          )
          .catch(() => {
            enqueueSnackbar(formatMessage(sharedMessages.somethingWentWrong), {
              autoHideDuration: 1500,
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            });
          });

        return;
      }

      if (onUnlockUnavailable) {
        onUnlockUnavailable();

        return;
      }

      showSubscribeView();
    }, [
      dispatch,
      postId,
      authorId,
      enqueueSnackbar,
      formatMessage,
      showSubscribeView,
      isUnlockAvailable,
      category,
      onUnlockUnavailable,
    ])
  );

  useLayoutEffect(() => {
    if (prevUnlocked !== undefined && !prevUnlocked && unlocked) {
      setAnimationActive(true);
    }
  }, [unlocked, prevUnlocked]);

  if (
    (unlocked && !isAnimationActive) ||
    subscriptionLevel === FanLevel.SUPER_FAN ||
    subscriptionLevel === FanLevel.EXCLUSIVE_FAN ||
    (!(currentSubscription?.status === SubscriptionStatus.ACTIVE) &&
      isSubscribeFlowDisabled)
  ) {
    return null;
  }

  return (
    <div
      className={classnames(
        styles[breakpoint],
        styles.unlockButton,
        isSubscribeFlowDisabled && styles.big,
        isAnimationActive && styles.showAnimation,
        {
          [styles.unlockAvailable]: isUnlockAvailable,
        }
      )}
      onClick={handleClick}
    >
      {isUnlockAvailable ? (
        <ViewersIcon className={styles.icon} />
      ) : (
        <FanLockedIcon className={classnames(styles.icon, styles.fanIcon)} />
      )}
      <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3}>
        <FormattedMessage
          {...sharedMessages.leftMessage}
          values={{
            value: maxAvailableUnlockExclusivePostCount,
          }}
        />
      </Typography>
    </div>
  );
};

export default memo(PostUnlockOverlay);
