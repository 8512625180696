import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import PropTypes from "prop-types";
import { STREAM } from "enums/giftRecipientType";
import { SpecialKind } from "src/types/gift";
import sendGift, { sendGiftSchedule } from "state/flows/sendGift";
import {
  giftingRequestsSelectors,
  giftsCacheSelectors,
  userSelectors,
} from "state/selectors";
import { actionCreators } from "state/tree/giftsCache";
import GiftCoinsView from "ui/common/coinsView/GiftCoinsView";
import GiftPriceContext from "ui/common/giftsDrawer/GiftPriceContext";
import LazyImage from "ui/common/lazyImage/LazyImage";
import { useBreakpointMobileLayout } from "ui/hooks/useBreakpoint";
import GiftRendererContext from "./GiftRendererContext";
import styles from "./Gift.scss";

const isHiddenSelectorFactory = (giftId) => (state) => ({
  gift: giftsCacheSelectors.getGiftById(state, giftId),
  giftBalance:
    userSelectors
      .getGiftsBalance(state)
      .find((balance) => balance.gift === giftId)?.amount || 0,
});

export const RESPONSIVE_TYPE = {
  RESPONSIVE: "responsive",
  ALT_RESPONSIVE: "alternativeResponsive",
};

const Gift = ({
  id: giftId,
  onClick,
  collectible,
  collected,
  className,
  withBackground,
  categoryId,
  showPrice = true,
  showBalance = true,
  responsiveStyleType,
  coinSize,
  coinsViewTypography,
  giftSelectionOrigin,
  realTimeGiftFromType,
}) => {
  const dispatch = useDispatch();
  const giftPriceSettings = useContext(GiftPriceContext);
  const isMobile = useBreakpointMobileLayout();
  const selector = useMemo(() => isHiddenSelectorFactory(giftId), [giftId]);
  const { giftSetRenderPosition, giftGetRenderPosition } =
    useContext(GiftRendererContext);
  const giftCategory = useSelector(
    (state) => giftsCacheSelectors.getCategoryById(state, categoryId),
    shallowEqual
  );
  const { gift, giftBalance } = useSelector(selector, shallowEqual);
  const recipientType = useSelector(
    giftingRequestsSelectors.getRecipientType,
    shallowEqual
  );

  const onGiftClick = useCallback(() => {
    if (!giftId) {
      return;
    }

    batch(() => {
      dispatch(
        actionCreators.setOptionLastClickedGift({
          giftId,
          categoryId,
          positionIndex:
            giftGetRenderPosition !== undefined
              ? giftGetRenderPosition(giftId, giftCategory?.name)
              : 0,
        })
      );
      onClick
        ? onClick(giftId)
        : sendGiftSchedule(() =>
            dispatch(
              sendGift({
                giftId,
                isMobile,
                giftSelectionOrigin,
                realTimeGiftFromType,
              })
            )
          );
    });
  }, [
    onClick,
    giftId,
    isMobile,
    categoryId,
    giftGetRenderPosition,
    giftCategory,
  ]);

  useEffect(() => {
    if (giftSetRenderPosition !== undefined) {
      giftSetRenderPosition(giftId, giftCategory?.name);
    }
  }, [giftId, giftCategory?.name, giftSetRenderPosition]);

  if (
    !gift ||
    (recipientType !== STREAM && gift.special === SpecialKind.AR_GIFT)
  ) {
    return null;
  }

  return (
    <div
      className={classnames(
        styles.gift,
        styles[responsiveStyleType],
        withBackground && styles.withBackground,
        className
      )}
      onClick={onGiftClick}
      data-testid={`gift-${giftId}`}
      role="button"
      tabIndex={0}
      onTouchStart={emptyFunction}
    >
      <LazyImage
        className={classnames(
          styles.icon,
          gift.nonStandardResolution && styles.nonStandard
        )}
        src={
          collectible && !collected && !!gift.notCollectedIcon
            ? gift.notCollectedIcon
            : gift.icon
        }
        alt="gift"
      />
      {showPrice && (
        <span className={styles.title}>
          <GiftCoinsView
            settingsPrice={giftPriceSettings}
            gift={gift}
            giftBalance={giftBalance}
            showBalance={showBalance}
            coinSize={coinSize}
            typography={coinsViewTypography}
          />
        </span>
      )}
    </div>
  );
};

Gift.propTypes = {
  id: PropTypes.string.isRequired,
  collectible: PropTypes.bool,
  collected: PropTypes.bool,
  withBackground: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  showPrice: PropTypes.bool,
  showBalance: PropTypes.bool,
  categoryId: PropTypes.string,
  responsiveStyleType: PropTypes.oneOf(Object.values(RESPONSIVE_TYPE)),
  coinSize: PropTypes.string,
  coinsViewTypography: PropTypes.string,
  giftSelectionOrigin: PropTypes.string,
  realTimeGiftFromType: PropTypes.string,
};

export default memo(Gift);
