import React, { memo } from "react";
import classnames from "classnames";
import { ComponentWithClassName } from "src/types/common";
import styles from "./CircularProgress.scss";

export interface CircularProgressProps {
  indicatorWidth?: number;
  progress?: number;
  size?: number;
  spinner?: boolean;
  trackWidth?: number;
}

const CircularProgress: ComponentWithClassName<CircularProgressProps> = ({
  size = 40,
  progress = 0,
  trackWidth = 2,
  indicatorWidth = 2,
  spinner = false,
  className,
}) => {
  const center = size / 2;
  const radius =
    center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
  const dashArray = 2 * Math.PI * radius;
  const dashOffset =
    dashArray * ((100 - (spinner && !progress ? 75 : progress)) / 100);
  const containerStyle = { width: size, height: size };

  return (
    <div
      className={classnames(styles.wrapper, className)}
      style={containerStyle}
      data-testid="progress"
    >
      <svg className={styles.svg} style={containerStyle}>
        <circle
          className={styles.track}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          strokeWidth={trackWidth}
        />
        <circle
          className={classnames(styles.indicator, {
            [styles.indicatorSpinner]: spinner,
          })}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          strokeWidth={indicatorWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default memo(CircularProgress);
