import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl, defineMessages } from "react-intl";
import Input from "ui/modal/modalViews/buyCoins/common/Input";

const messages = defineMessages({
  voucherNumber: {
    id: "buy-coins.voucher-number",
    defaultMessage: "Voucher Number",
  },
  enterNumber: {
    id: "buy-coins.voucher-enter",
    defaultMessage: "Enter Number",
  },
});

const PinInput = ({ pin, setPin, className }) => {
  const { formatMessage, locale } = useIntl();
  const { label, placeholder } = useMemo(
    () => ({
      label: formatMessage(messages.voucherNumber),
      placeholder: formatMessage(messages.enterNumber),
    }),
    [locale]
  );
  const onPinChange = useCallback((e) => {
    const value = e.target.value.replace(/\W/g, "").toUpperCase();
    setPin(value.slice(0, 12));
  }, []);

  return (
    <Input
      id="mintroute-pin"
      value={pin}
      onChange={onPinChange}
      label={label}
      type="text"
      placeholder={placeholder}
      autoComplete="off"
      className={className}
      autoFocus
    />
  );
};

PinInput.propTypes = {
  pin: PropTypes.string.isRequired,
  setPin: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default PinInput;
