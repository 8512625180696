import React from "react";
import emptyFunction from "fbjs/lib/emptyFunction";
import { VoidCallback } from "src/types/common";
import Button from "ui/common/button/Button";
import { ButtonVariantsProps } from "ui/common/button/types";
import useShare from "ui/hooks/useShare";

interface ShareButtonProps {
  afterLinkCopied?: VoidCallback;
  afterShare?: VoidCallback;
  className?: string;
  emitOnly?: boolean;
  link: string;
  onUnableToShare?: VoidCallback;
  title: string;
}

const ShareButton: React.FC<ButtonVariantsProps & ShareButtonProps> = ({
  className,
  title,
  link,
  afterShare,
  afterLinkCopied,
  onUnableToShare,
  emitOnly,
  ...rest
}) => {
  const share = useShare({
    link,
    title,
    afterShare,
    afterLinkCopied,
    onUnableToShare,
    emitOnly,
  });

  return (
    <Button
      {...rest}
      className={className}
      onClick={share}
      onTouchStart={emptyFunction}
      data-testid="share"
    />
  );
};

export default ShareButton;
