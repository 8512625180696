import React, { memo, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { emitUiAction } from "@analytics/emitUiAction";
import { BI_LANDING_FIELD, EventFields } from "@analytics/enums";
import { ensureHttps } from "src/utils/ensureHttps";
import PersonalPriceWithDiscount from "ui/common/PersonalPriceWithDiscount";
import CoinsAmount from "ui/common/coinsAmount/CoinsAmount";
import EmojiSpan from "ui/common/emoji/EmojiSpan";
import Watermark from "ui/common/personalOffer/Watermark/Watermark";
import { DEFAULT_DISCOUNT_PERCENT } from "ui/common/personalOffer/constants";
import { PersonalOffersCardProps } from "ui/common/personalOffer/types";
import { SkeletonWrapper } from "ui/common/skeleton";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import {
  OfferCardSizes,
  OfferCardTypes,
  OfferCardVariants,
} from "ui/modal/modalViews/buyCoins/offers/card/enums";
import {
  CARD_ORIGINAL_PRICE_TYPOGRAPHY_MAP,
  CARD_PRICE_TYPOGRAPHY_MAP,
  COINS_TYPOGRAPHY_MAP,
  SPIN_TO_WIN_DESCRIPTION_MAP,
} from "ui/modal/modalViews/buyCoins/offers/card/utils";
import { Watermark as WatermarkType } from "ui/modal/modalViews/buyCoins/offers/enums";
import useOfferClickAction from "ui/modal/modalViews/buyCoins/offers/useOfferClickAction";
import spinToWin from "img/spin-to-win.png";
import wheel from "img/wheel_offer.png";
import styles from "./RegularCardView.scss";

const VERSION = "personal-offers-version";

const watermarkTypeConfig = {
  [WatermarkType.POPULAR]: (
    <>
      {"👍 "}
      <FormattedMessage
        id="buy-coins.ribbon.popular"
        defaultMessage="POPULAR"
      />
    </>
  ),
  [WatermarkType.TOP]: (
    <>
      {"🔥 "}
      <FormattedMessage id="buy-coins.ribbon.hot" defaultMessage="HOT" />
    </>
  ),
};

const sizeToWatermarkTypography = {
  [OfferCardSizes.EXTRA_SMALL]: TYPOGRAPHY_TYPE.MICRO,
  [OfferCardSizes.SMALL]: TYPOGRAPHY_TYPE.MICRO,
  [OfferCardSizes.MEDIUM]: TYPOGRAPHY_TYPE.HEADLINE6,
  [OfferCardSizes.LARGE]: TYPOGRAPHY_TYPE.HEADLINE6,
};

const RegularCardView: React.FC<PersonalOffersCardProps> = ({
  offer,
  onClick,
  type,
  size,
  analyticsParams,
  className,
  disabled,
  loading,
  selected,
  biCardIndex,
}) => {
  const {
    offers: [{ coins, currencyPrice, bonus, offerDescription }],
  } = offer;

  const onClickEmit = useCallback(() => {
    emitUiAction({
      target: "lp_offer_click",
      tango_screen: "lpRegular",
      additionalParams: {
        ...analyticsParams,
        [EventFields.POSITION]: biCardIndex,
        [BI_LANDING_FIELD.PRICE]: offer.price,
        [BI_LANDING_FIELD.CREDIT]: coins,
        [BI_LANDING_FIELD.MARKET_OFFER_ID]: coins,
        [EventFields.TANGO_SCREEN]: `buy_coins_landing.${VERSION}.offers_page`,
      },
    });

    return onClick(offer);
  }, [analyticsParams, biCardIndex, coins, offer, onClick]);

  const handleClick = useOfferClickAction(offer, onClickEmit, analyticsParams);
  const isStwOffer = !!offer.stwTemplate;

  const isLightLanding = type === OfferCardTypes.LANDING_LIGHT;

  const offerImage = useMemo(() => {
    if (isStwOffer && !offer.imageUrl) {
      return wheel;
    }

    return ensureHttps(offer.imageUrl || "");
  }, [offer.imageUrl, isStwOffer]);

  let watermark;
  if (offer.isHot) {
    watermark = WatermarkType.TOP;
  }
  if (offer.isPopular) {
    watermark = WatermarkType.POPULAR;
  }

  return (
    <SkeletonWrapper
      className={classnames(styles.skeleton, styles[type], styles[size])}
      loading={loading}
    >
      <div
        className={classnames(
          className,
          styles.root,
          styles[type],
          styles[size],
          styles[OfferCardVariants.REGULAR],
          {
            [styles.disabled]: disabled,
            [styles.selected]: selected,
            [styles.highlighted]: isLightLanding && !!watermark,
          }
        )}
        onClick={handleClick}
        onTouchStart={emptyFunction}
        data-testid={`offer-${offer.id}`}
        role="button"
        tabIndex={0}
      >
        <div className={styles.bgOverlay} />
        <div className={styles.main}>
          {isStwOffer ? (
            <>
              {isLightLanding && (
                <img
                  className={styles.spinToWinIcon}
                  src={spinToWin}
                  alt="spinToWin"
                />
              )}
              <Typography
                type={SPIN_TO_WIN_DESCRIPTION_MAP[type]?.[size]}
                className={styles.spinToWinDescription}
              >
                {offerDescription || (
                  <FormattedMessage
                    id="offers.spin-to-win"
                    defaultMessage="Spin to Win"
                  />
                )}
              </Typography>
            </>
          ) : (
            <CoinsAmount
              className={styles.credit}
              amount={coins || 0}
              type={COINS_TYPOGRAPHY_MAP[type]?.[size]}
            />
          )}
          {type !== OfferCardTypes.LANDING_LIGHT && (
            <img
              src={offerImage}
              className={styles.image}
              alt="offer"
              data-testid="offer-icon"
            />
          )}
          <div className={styles.price}>
            <PersonalPriceWithDiscount
              offer={{
                currencyPrice,
                discountPercent: bonus || DEFAULT_DISCOUNT_PERCENT,
                usdPrice: offer.price,
              }}
              typographyPriceType={CARD_PRICE_TYPOGRAPHY_MAP[type]?.[size]}
              typographyOriginalPriceType={
                CARD_ORIGINAL_PRICE_TYPOGRAPHY_MAP[type]?.[size]
              }
            />
          </div>
        </div>
        {!!watermark && (
          <>
            {isLightLanding ? (
              <Typography
                type={sizeToWatermarkTypography[size]}
                className={styles.watermark}
              >
                <EmojiSpan>{watermarkTypeConfig[watermark]}</EmojiSpan>
              </Typography>
            ) : (
              <Watermark type={watermark} size={size} />
            )}
          </>
        )}
        <div className={styles.border} />
      </div>
    </SkeletonWrapper>
  );
};

export default memo(RegularCardView);
