import React, { FC } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { WatermarkComponentProps } from "ui/common/personalOffer/Watermark/Watermark";
import imFireLeft from "src/img/watermarks/fire-left.png";
import imFireRight from "src/img/watermarks/fire-right.png";
import styles from "./RegularHotWatermark.scss";

const RegularHotWatermark: FC<WatermarkComponentProps> = ({
  size,
  className,
}) => (
  <div className={classnames(className, styles.root, styles[size])}>
    <img src={imFireLeft} className={styles.fireLeft} alt="fire" />
    <img src={imFireRight} className={styles.fireRight} alt="fire" />
    <FormattedMessage id="buy-coins.ribbon.hot" defaultMessage="HOT" />
  </div>
);

export default RegularHotWatermark;
