import React from "react";
import classnames from "classnames";
import { PersonalOffersCurrencyPrice } from "src/types/personalOffer";
import FormattedPrice from "ui/common/intl/FormattedPrice";
import Typography, {
  TYPOGRAPHY_TYPE,
  TypographyProps,
} from "ui/common/typography/Typography";
import { useValueAndCurrencyForPersonalOffers } from "ui/hooks/useCurrencies";
import estimatePriceWithoutBonus from "ui/utils/estimatePriceWithoutBonus";
import styles from "./PriceWithDiscount.scss";

type PriceWithDiscountProps = {
  hideOriginalPrice?: boolean;
  offer: PersonalOffersCurrencyPrice;
  originalPriceStyle?: string;
  priceStyle?: string;
  typographyOriginalPriceType?: TypographyProps["type"];
  typographyPriceType?: TypographyProps["type"];
};

const PersonalPriceWithDiscount: React.FC<PriceWithDiscountProps> = ({
  offer,
  typographyPriceType = TYPOGRAPHY_TYPE.HEADLINE5,
  typographyOriginalPriceType = TYPOGRAPHY_TYPE.HEADLINE6,
  hideOriginalPrice,
  priceStyle,
  originalPriceStyle,
}) => {
  const priceProps = useValueAndCurrencyForPersonalOffers(offer);
  return (
    <>
      <Typography
        type={typographyPriceType}
        className={classnames(styles.price, priceStyle)}
        data-testid="price-with-bonus"
      >
        <FormattedPrice {...priceProps} />
      </Typography>
      {!hideOriginalPrice && (
        <Typography
          type={typographyOriginalPriceType}
          className={classnames(styles.originalPrice, originalPriceStyle)}
          lineThrough
          data-testid="price-without-bonus"
        >
          <FormattedPrice
            value={
              offer.originalPrice ||
              estimatePriceWithoutBonus(priceProps.value, offer.discountPercent)
            }
            currency={priceProps.currency}
          />
        </Typography>
      )}
    </>
  );
};

export default PersonalPriceWithDiscount;
