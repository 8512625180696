import React, { ComponentType, FC, SVGProps } from "react";
import classnames from "classnames";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import styles from "./Select.scss";

interface SelectFooterProps {
  DescriptionIcon?: ComponentType<SVGProps<SVGSVGElement>>;
  description?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  isShader?: boolean;
  limitText?: string;
}

export const SelectFooter: FC<SelectFooterProps> = ({
  description,
  limitText,
  error,
  disabled,
  DescriptionIcon,
  errorMessage,
  isShader,
}) => (
  <div
    className={classnames(styles.footer, {
      [styles.shader]: isShader,
    })}
  >
    <div className={styles.descriptionContainer}>
      {DescriptionIcon && (
        <DescriptionIcon
          className={classnames(styles.descriptionIcon, {
            [styles.textError]: error && !disabled,
          })}
        />
      )}
      {(description || errorMessage) && (
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH4}
          className={classnames(styles.description, {
            [styles.textError]: error && !disabled,
          })}
        >
          {errorMessage || description}
        </Typography>
      )}
    </div>
    {limitText && (
      <Typography
        type={TYPOGRAPHY_TYPE.PARAGRAPH4}
        className={classnames(styles.limitText, {
          [styles.textError]: error && !disabled,
          [styles.shader]: isShader && !error,
        })}
      >
        {limitText}
      </Typography>
    )}
  </div>
);
