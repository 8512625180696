import React from "react";
import { PURCHASE_SOURCE } from "@analytics/enums";
import { Currency } from "src/enums";
import { PaymentProvider } from "src/types/payment";
import { PaymentTypeTab } from "ui/modal/modalViews/buyCoins/types";
import WebviewOffersView from "./WebviewOffersView";

const WebviewApmOffers: React.FC = () => (
  <WebviewOffersView
    suggestedProvider={PaymentProvider.TANGO}
    initialPaymentType={PaymentTypeTab.LOCAL_PAYMENTS}
    defaultCurrency={Currency.INR}
    taxV2
    purchaseSource={PURCHASE_SOURCE.WEBVIEW_APM_OFFERS}
  />
);

export default WebviewApmOffers;
