import React, { memo, useCallback, useMemo } from "react";
import useEmitLinkSharedEvent, {
  RESULT_CANCELLED,
  RESULT_SHARED,
} from "@analytics/emitLinkSharedEvent";
import { createStreamViralityLink } from "ui/utils/viralityLinkUtils";
import ShareButton from "ui/common/buttons/ShareButton";
import { defineMessages, useIntl } from "react-intl";
import useProfileById from "ui/hooks/useProfileById";
import { formatDisplayName } from "ui/common/DisplayName";
import { ButtonSize, ButtonVariant } from "ui/common/button/types";
import { ReactComponent as SmallShareIcon } from "img/ic_share_small.svg";
import { ReactComponent as LargeShareIcon } from "img/ic_share_large.svg";
import { getJoshApi, getIsInJosh } from "src/utils/joshUtils";

const messages = defineMessages({
  title: {
    id: "broadcast.share.title",
    defaultMessage: "{streamerName} is LIVE on Tango!",
  },
  description: {
    id: "broadcast.share.description",
    defaultMessage: "View the live stream now!",
  },
});

interface StreamShareButtonProps {
  streamId: string;
  streamerId: string;
  variant:
    | ButtonVariant.ICON_ONLY
    | ButtonVariant.SYSTEM_DARK
    | ButtonVariant.SYSTEM_LIGHT;
  className?: string;
}

const StreamShareButton: React.FC<StreamShareButtonProps> = ({
  streamId,
  streamerId,
  variant,
  className,
}) => {
  const intl = useIntl();
  const { basicProfile } = useProfileById(streamerId);
  const isIconOnly = variant === ButtonVariant.ICON_ONLY;

  const btnConfig = useMemo(
    () =>
      isIconOnly
        ? ({
            size: ButtonSize.MEDIUM_32,
            variant,
          } as const)
        : ({
            size: ButtonSize.CIRCLE_BIG_48,
            variant,
          } as const),
    [isIconOnly, variant]
  );

  const streamerName = useMemo(
    () => formatDisplayName({ intl, basicProfile }),
    [intl, basicProfile]
  );

  const formattedTitle = useMemo(
    () => intl.formatMessage(messages.title, { streamerName }),
    [intl, streamerName]
  );

  const emitLinkSharedEvent = useEmitLinkSharedEvent({
    linkType: "stream",
    linkTypeParam: streamId,
    peerId: streamerId,
    sessionId: streamId,
  });

  const link = createStreamViralityLink({
    streamId,
    streamerId,
    alias: basicProfile?.aliases?.[0],
  });

  const afterShare = useCallback(() => {
    emitLinkSharedEvent(link, RESULT_SHARED);
    getJoshApi()?.shareUrl(link, formattedTitle);
  }, [emitLinkSharedEvent, link, formattedTitle]);

  const afterLinkCopied = useCallback(
    () => emitLinkSharedEvent(link),
    [emitLinkSharedEvent, link]
  );

  const onUnableToShare = useCallback(
    () => emitLinkSharedEvent(link, RESULT_CANCELLED),
    [emitLinkSharedEvent, link]
  );

  return (
    <ShareButton
      {...btnConfig}
      className={className}
      title={formattedTitle}
      link={link}
      afterShare={afterShare}
      afterLinkCopied={afterLinkCopied}
      onUnableToShare={onUnableToShare}
      emitOnly={getIsInJosh()}
    >
      {isIconOnly ? <SmallShareIcon /> : <LargeShareIcon />}
    </ShareButton>
  );
};

export default memo(StreamShareButton);
