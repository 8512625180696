import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getBroadcasterId,
  giftsCacheSelectors,
  settingsSelectors,
  userSelectors,
} from "state/selectors";
import { actionCreators as settingsActionCreators } from "state/tree/settings";

const selector = (state) => ({
  currentUserId: userSelectors.getMyAccountId(state),
  broadcasterId: getBroadcasterId(state),
  isGiftsDrawerVisible: settingsSelectors.isGiftsDrawerVisible(state),
  giftsDrawerTabId: giftsCacheSelectors.getGiftsDrawerTabId(state),
  giftIdToConfirm: giftsCacheSelectors.getGiftIdToConfirm(state),
  giftsDrawerConfig: settingsSelectors.getGiftsDrawerConfig(state),
});

export default () => {
  const dispatch = useDispatch();
  const {
    currentUserId,
    broadcasterId,
    isGiftsDrawerVisible,
    giftsDrawerTabId,
    giftIdToConfirm,
    giftsDrawerConfig,
  } = useSelector(selector, shallowEqual);
  const toggleGiftDrawerVisibility = useCallback(() => {
    dispatch(settingsActionCreators.toggleGiftsDrawerVisibility());
  }, []);
  return {
    toggleGiftDrawerVisibility,
    isGiftsDrawerVisible,
    giftsDrawerTabId,
    canShowGiftsDrawer: currentUserId !== broadcasterId,
    giftIdToConfirm,
    giftsDrawerConfig,
  };
};
