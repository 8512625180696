import React, { useCallback } from "react";
import PropTypes from "prop-types";
import giftCardBannerImg from "img/banners/gift-card-banner.png";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { openRedeemGiftCardModalView } from "state/actionCreators/modal";
import useUiAction from "ui/hooks/useUiAction";
import emptyFunction from "fbjs/lib/emptyFunction";
import styles from "./GiftCardBanner.scss";
import { themeLockClassnames } from "ui/Theme";

const GiftCardBanner = ({ className, purchaseSource, isCashier, ...rest }) => {
  const dispatch = useDispatch();
  const onClick = useUiAction({
    target: "gift_card_banner",
    callback: useCallback(() => {
      dispatch(openRedeemGiftCardModalView({ purchaseSource }));
    }, [purchaseSource]),
  });
  return (
    <div className={classnames(styles.wrapper, className)}>
      <div
        className={classnames(styles.root, {
          [styles.cashier]: isCashier,
          [themeLockClassnames.dark]: !isCashier,
        })}
        {...rest}
        onClick={onClick}
        data-testid="gift-card-banner"
        role="button"
        tabIndex={0}
        onTouchStart={emptyFunction}
      >
        <img src={giftCardBannerImg} className={styles.icon} />
        <div className={styles.container}>
          <div className={styles.title}>
            <FormattedMessage
              id="buy-coins.gift-card.banner.cashier-title"
              defaultMessage="Use prepaid cards"
            />
          </div>
          <div className={styles.subTitle}>
            <FormattedMessage
              id="buy-coins.gift-card.banner.cashier-subTitle"
              defaultMessage="to refill coins on Tango"
            />
          </div>
          <div className={styles.button}>
            <FormattedMessage
              id="buy-coins.gift-card.banner.cashier-cta"
              defaultMessage="Use Card"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

GiftCardBanner.propTypes = {
  purchaseSource: PropTypes.number.isRequired,
  className: PropTypes.string,
  isCashier: PropTypes.bool,
};

export default GiftCardBanner;
