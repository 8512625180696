import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { ModalType } from "src/enums";
import { openLoginView } from "src/features/signin/state/flows/openLoginView";
import { RootState } from "state/delegate";
import { loginSelectors, modalSelectors } from "state/selectors";
import { defaultRoute } from "ui/navigation/links";

const fallbackSelector = (state: RootState) => ({
  isLoggedIn: loginSelectors.isLoggedIn(state),
  loginModalDismissed:
    modalSelectors.getLastDismissedModalType(state) === ModalType.LOGIN,
});

export const LoggedOutFallback = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, loginModalDismissed } = useSelector(
    fallbackSelector,
    shallowEqual
  );

  useEffect(() => {
    dispatch(openLoginView());
  }, [dispatch]);

  if (!isLoggedIn && loginModalDismissed) {
    return <Redirect to={defaultRoute} />;
  }

  return null;
};
