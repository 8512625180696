import React, { memo, useCallback, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  EventFields,
  SUBSCRIBE_TARGET,
  UNSUBSCRIBE_TARGET,
} from "@analytics/enums";
import { SubscriptionStatus } from "src/enums";
import { BIAdditionalParams, VoidCallback } from "src/types/common";
import { PaymentType } from "src/types/payment";
import { loginSelectors, userSelectors } from "state/selectors";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import useSubscribeOnStreamer from "ui/hooks/useSubscribeOnStreamer";
import useUiAction from "ui/hooks/useUiAction";
import { getBecomeFanText, getFanName } from "ui/utils/fanUtils";
import { ReactComponent as StarIconSolid } from "img/ic_become_a_fan_star.svg";
import { ReactComponent as MaxFanIcon } from "img/ic_fan_star_done.svg";
import { ReactComponent as MaxFanIconSolid } from "img/ic_fan_star_done_solid.svg";
import { ReactComponent as UpgradeIcon } from "img/ic_upgrade.svg";
import { ReactComponent as UpgradeIconSolid } from "img/ic_upgrade_flow_solid.svg";
import { ReactComponent as StarIcon } from "img/user_menu_icons/subscriptions.svg";

const subscribeCTAMessages = defineMessages({
  renew: {
    id: "subscriptions.streamer.renew",
    defaultMessage: "Renew",
  },
  becomeAFan: {
    id: "tcnn.local.subscribe.cta",
    defaultMessage: "Become a Fan",
  },
});

interface SubscribeCTAProps {
  accountId: string;
  additionalParams?: BIAdditionalParams;
  className?: string;
  isSecondary?: boolean;
  isShaderTheme?: boolean;
  modalScope?: string;
  onClick?: VoidCallback;
  premiumEntrance?: boolean;
  size?:
    | ButtonSize.BIG_48
    | ButtonSize.CIRCLE_BIG_48
    | ButtonSize.CIRCLE_MEDIUM_40;
  subscribeMessage?: string;
  target: SUBSCRIBE_TARGET | UNSUBSCRIBE_TARGET;
}

const SubscribeCTA: React.FC<SubscribeCTAProps> = ({
  onClick,
  className,
  accountId,
  subscribeMessage,
  target,
  isShaderTheme = false,
  isSecondary = false,
  size = ButtonSize.BIG_48,
  modalScope,
  additionalParams,
}) => {
  const { formatMessage } = useIntl();
  const isMe = useSelector(
    (state) =>
      loginSelectors.isLoggedIn(state) &&
      userSelectors.getMyAccountId(state) === accountId
  );

  const {
    availableSubscriptions,
    currentSubscription,
    isUpgradeAvailable,
    onSubscribe,
  } = useSubscribeOnStreamer({
    accountId,
    isShaderTheme,
    modalScope,
    additionalParams,
    target,
  });

  const hasActiveSubscription =
    currentSubscription &&
    (currentSubscription.status === SubscriptionStatus.ACTIVE ||
      currentSubscription.status === SubscriptionStatus.PENDING);

  const isCompact =
    size === ButtonSize.CIRCLE_MEDIUM_40 || size === ButtonSize.CIRCLE_BIG_48;
  const active = hasActiveSubscription && !isUpgradeAvailable;

  const Icon = useMemo(() => {
    const isCompact =
      size === ButtonSize.CIRCLE_MEDIUM_40 || size === ButtonSize.CIRCLE_BIG_48;
    if (
      currentSubscription?.status === SubscriptionStatus.ACTIVE &&
      !isUpgradeAvailable
    ) {
      return isCompact ? MaxFanIcon : MaxFanIconSolid;
    } else if (
      currentSubscription?.status === SubscriptionStatus.ACTIVE &&
      isUpgradeAvailable
    ) {
      return isCompact ? UpgradeIcon : UpgradeIconSolid;
    }

    return isCompact ? StarIcon : StarIconSolid;
  }, [currentSubscription, isUpgradeAvailable, isCompact]);

  const message = useMemo(() => {
    if (subscribeMessage) {
      return subscribeMessage;
    }
    if (hasActiveSubscription && isUpgradeAvailable) {
      return formatMessage(
        getBecomeFanText(+availableSubscriptions[0]?.plan.level)
      );
    } else if (hasActiveSubscription && !isUpgradeAvailable) {
      return formatMessage(
        getFanName(+currentSubscription.details.streamer.level)
      );
    } else if (
      (currentSubscription &&
        currentSubscription.status === SubscriptionStatus.CANCELLED) ||
      (currentSubscription &&
        currentSubscription.status === SubscriptionStatus.GRACE_PERIOD)
    ) {
      return formatMessage(subscribeCTAMessages.renew);
    }

    return formatMessage(subscribeCTAMessages.becomeAFan);
  }, [
    isUpgradeAvailable,
    currentSubscription,
    subscribeMessage,
    availableSubscriptions,
    hasActiveSubscription,
  ]);

  const buttonConfig = useMemo(() => {
    const variant = isShaderTheme
      ? ButtonVariant[
          isSecondary
            ? "SUBSCRIPTION_SECONDARY_ON_SHADER"
            : "SUBSCRIPTION_PRIMARY_ON_SHADER"
        ]
      : ButtonVariant[
          isSecondary ? "SUBSCRIPTION_SECONDARY" : "SUBSCRIPTION_PRIMARY"
        ];
    switch (size) {
      case ButtonSize.CIRCLE_MEDIUM_40:
      case ButtonSize.CIRCLE_BIG_48: {
        return {
          variant,
          size,
          children: <Icon />,
        } as const;
      }

      case ButtonSize.BIG_48: {
        return {
          variant,
          leftIcon: Icon,
          size,
          children: message,
        } as const;
      }
    }
  }, [isShaderTheme, size, Icon, message, isSecondary]);

  const handleSubscribe = useCallback(
    (e) => {
      e.stopPropagation();
      (currentSubscription?.status === SubscriptionStatus.CANCELLED ||
        currentSubscription?.status === SubscriptionStatus.GRACE_PERIOD) &&
      !isCompact
        ? onSubscribe(currentSubscription)
        : (onClick || onSubscribe)();
    },
    [currentSubscription, onClick, onSubscribe, isCompact]
  );

  const onSubscribeClick = useUiAction({
    target,
    callback: handleSubscribe,
    additionalParams: {
      [EventFields.PEER_ID]: accountId,
      [EventFields.PAYMENT_TYPE]: PaymentType.COINS,
    },
  });

  if (isMe) {
    return null;
  }

  return (
    <Button
      data-testid="subscribe-btn"
      onClick={onSubscribeClick}
      className={className}
      active={active}
      {...buttonConfig}
    />
  );
};

export default memo(SubscribeCTA);
