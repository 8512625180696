import React from "react";
import useFitText from "use-fit-text";
import FormattedPrice from "ui/common/intl/FormattedPrice";
import Typography, {
  TYPOGRAPHY_TYPE,
  TypographyProps,
} from "ui/common/typography/Typography";
import { useValueAndCurrencyForOffer } from "ui/hooks/useCurrencies";
import { ShopOfferCurrencyPriceBase } from "ui/scenes/landingPage/types";
import estimatePriceWithoutBonus from "ui/utils/estimatePriceWithoutBonus";
import styles from "./PriceWithDiscount.scss";

type PriceWithDiscountProps = {
  hideOriginalPrice?: boolean;
  offer: ShopOfferCurrencyPriceBase;
  typographyOriginalPriceType?: TypographyProps["type"];
  typographyPriceType?: TypographyProps["type"];
};

const PriceWithDiscount: React.FC<PriceWithDiscountProps> = ({
  offer,
  typographyPriceType = TYPOGRAPHY_TYPE.HEADLINE5,
  typographyOriginalPriceType = TYPOGRAPHY_TYPE.HEADLINE6,
  hideOriginalPrice,
}) => {
  const priceProps = useValueAndCurrencyForOffer(offer);
  const { fontSize, ref } = useFitText({ maxFontSize: 100 });

  return (
    <div className={styles.root}>
      <div ref={ref} style={{ fontSize }}>
        <Typography
          type={typographyPriceType}
          className={styles.price}
          data-testid="price-with-bonus"
        >
          <span>
            <FormattedPrice {...priceProps} />
          </span>
        </Typography>
        {!hideOriginalPrice && (
          <Typography
            type={typographyOriginalPriceType}
            className={styles.originalPrice}
            lineThrough
            data-testid="price-without-bonus"
          >
            <FormattedPrice
              value={
                offer.originalPrice ||
                estimatePriceWithoutBonus(
                  priceProps.value,
                  offer.discountPercent
                )
              }
              currency={priceProps.currency}
            />
          </Typography>
        )}
      </div>
    </div>
  );
};

export default PriceWithDiscount;
