import React from "react";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { Breakpoints } from "src/enums";
import { ButtonSize } from "ui/common/button/types";
import StartBroadcastButton from "ui/common/buttons/StartBroadcastButton";
import UserHeader from "ui/common/dropdownMenu/menuList/components/UserHeader";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import styles from "ui/common/dropdownMenu/menuList/components/ProfileWithBroadcastButton.scss";

const ProfileWithBroadcastButton = () => {
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  return !isDesktop ? (
    <div className={styles.profileStartBroadcastContainer}>
      <UserHeader
        closeDropdown={emptyFunction}
        className={classnames(styles.userWithBroadcastButton, {
          [styles.userWithBroadcastButtonTablet]:
            breakpoint === Breakpoints.TABLET,
        })}
      />
      <StartBroadcastButton
        className={classnames(styles.startBroadcastButton, {
          [styles.startBroadcastButtonTablet]:
            breakpoint === Breakpoints.TABLET,
        })}
        size={
          breakpoint === Breakpoints.TABLET
            ? ButtonSize.CIRCLE_BIG_48
            : ButtonSize.CIRCLE_MEDIUM_40
        }
        forcePrimary
      />
    </div>
  ) : (
    <UserHeader closeDropdown={emptyFunction} className={styles.user} />
  );
};

export default ProfileWithBroadcastButton;
