import { defineMessages } from "react-intl";

export const deleteAccountReasonMessages = defineMessages({
  header: {
    id: "deleteAccount.reasonModal.title",
    defaultMessage: "Why did you choose to leave Tango?",
  },
  submitButton: {
    id: "deleteAccount.button.label",
    defaultMessage: "Delete Account",
  },
  anotherAccountReason: {
    id: "deleteAccount.reasonModal.anotherAccountReason",
    defaultMessage: "I want to use another account",
  },
  abuseReason: {
    id: "deleteAccount.reasonModal.abuseReason",
    defaultMessage: "I met inappropriate or abusive users",
  },
  appBehaviorReason: {
    id: "deleteAccount.reasonModal.appBehaviorReason",
    defaultMessage: "Not satisfied with the app behavior",
  },
  notInterestedReason: {
    id: "deleteAccount.reasonModal.notInterestedReason",
    defaultMessage: "I no longer have an interest in using Tango",
  },
  otherReason: {
    id: "deleteAccount.reasonModal.otherReason",
    defaultMessage: "Other reason",
  },
  otherInputPlaceholder: {
    id: "deleteAccount.reasonModal.inputPlaceholder",
    defaultMessage: "Describe the reason",
  },
});

export const deleteAccountConfirmationMessages = defineMessages({
  header: {
    id: "deleteAccount.confirmationModal.title",
    defaultMessage: "Delete this Account?",
  },
  info: {
    id: "deleteAccount.confirmationModal.info",
    defaultMessage:
      "Your account will be deleted in 30 days. If you login before, your account will be reactivated automatically. It may take 1 additional day for Tango to remove all data related to your account.",
  },
  confirmationText: {
    id: "deleteAccount.confirmationModal.confirmationText",
    defaultMessage: "All personal data will be removed.",
  },
  submitButton: {
    id: "deleteAccount.confirmationModal.submitButton",
    defaultMessage: "Confirm Account Deletion",
  },
});
