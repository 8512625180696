import React, { FC, useCallback, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { RegistrationSource } from "@analytics/enums";
import { GetTheAppSource } from "@analytics/getTheApp";
import { createLocationStateForLiveStreamFeed } from "@analytics/livePlay/streamLocationState";
import { Breakpoints, CombinedFeedType, PersonalFeedType } from "src/enums";
import { BannerType } from "src/types/banner";
import { BasicStreamInfo } from "src/types/richFragment/Stream";
import GridBanners from "src/ui/common/banner/GridBanners";
import { times } from "src/utils/miniLodash";
import { loadMore, refresh } from "state/flows/loadLiveStreamsFeeds";
import { loginSelectors } from "state/selectors";
import JoinTangoBanner from "ui/common/banner/JoinTangoBanner";
import NoOneLiveBanner from "ui/common/banner/NoOneLiveBanner";
import InfiniteScroller from "ui/common/infiniteScroller/InfiniteScroller";
import sharedMessages from "ui/common/intl/sharedMessages";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useLoadStreams from "ui/hooks/useLoadStreams";
import Tile, { Placeholder } from "./components/Tile";
import styles from "./StreamsGrid.scss";
import liveStyles from "ui/scenes/live/desktop/Live.scss";

const breakpointToPlaceholdersCount = {
  [Breakpoints.SMALL_MOBILE]: 8,
  [Breakpoints.MOBILE]: 8,
  [Breakpoints.TABLET]: 12,
  [Breakpoints.DESKTOP]: 12,
};

const Placeholders: FC<{
  breakpoint: Breakpoints;
  count?: number;
}> = ({ breakpoint, count }) => {
  const items = useMemo(
    () =>
      times(count || breakpointToPlaceholdersCount[breakpoint], (x) => (
        <Placeholder key={x} />
      )),
    [breakpoint]
  );

  return (
    <div
      className={classnames(
        styles.root,
        styles[breakpoint],
        styles.placeholder
      )}
    >
      {items}
    </div>
  );
};

const EmptyView = ({ activeMode }: { activeMode: CombinedFeedType }) => {
  const isGuest = !useSelector(loginSelectors.isLoggedIn);
  const isFollowing = activeMode === PersonalFeedType.FOLLOWING;

  return (
    <div
      className={classnames(styles.emptyPlaceholder, {
        [styles.guest]: isGuest,
        [styles.following]: !isGuest && isFollowing,
      })}
      data-testid="empty-streams-feed"
    >
      {isGuest ? (
        <JoinTangoBanner
          title={sharedMessages.joinTangoCommunity}
          screenSource={GetTheAppSource.JOIN_BUTTON}
          registrationSource={
            isFollowing
              ? RegistrationSource.FOLLOWING_TAB
              : RegistrationSource.UNKNOWN
          }
        />
      ) : (
        <>
          {isFollowing ? (
            <div className={styles.emptyFollowing}>
              <NoOneLiveBanner />
            </div>
          ) : (
            <FormattedMessage
              id="live-feed.empty.default"
              defaultMessage="Welcome to the brand new Live!"
            />
          )}
        </>
      )}
    </div>
  );
};

type StreamsGridProps = {
  activeMode: CombinedFeedType;
  className?: string;
  isBannerShown?: boolean;
  liveStreamsFeed?: CombinedFeedType;
  utmParams?: string;
};

const StreamsGrid: React.FC<StreamsGridProps> = ({
  activeMode,
  liveStreamsFeed = activeMode,
  className,
  utmParams = "",
  isBannerShown = false,
  ...rest
}) => {
  const {
    items: streams,
    isLoading,
    hasLoadError,
    canLoadMore,
  } = useLoadStreams([activeMode, utmParams]);

  const breakpoint = useBreakpointPrecise();
  const isMobile = breakpoint !== Breakpoints.DESKTOP;

  const dispatch = useDispatch();

  const dispatchRefresh = useCallback(() => {
    dispatch(refresh(activeMode));
  }, [dispatch, activeMode]);

  const dispatchLoadMore = useCallback(() => {
    dispatch(loadMore(activeMode));
  }, [dispatch, activeMode]);

  const rootRef = useRef<HTMLDivElement>(null);

  const intersectionObserverProps = useMemo(
    () => ({
      root: rootRef.current,
      rootMargin: isMobile ? "300px 0px 300px 0px" : "300px 0px 600px 0px",
    }),
    [isMobile]
  );

  const renderStreams = useCallback(
    (streams: BasicStreamInfo[]) =>
      streams.map((stream, index: number) => (
        <Tile
          key={stream.id}
          stream={stream}
          linkState={createLocationStateForLiveStreamFeed({
            mode: liveStreamsFeed,
            rank: index,
            shouldScrollToCurrentStream: true,
          })}
        />
      )),
    [liveStreamsFeed]
  );

  const renderStreamsWithBanner = useMemo(() => {
    if (
      isLoading &&
      streams.length === 0 &&
      isBannerShown &&
      activeMode === PersonalFeedType.FOLLOWING
    ) {
      return (
        <>
          <Placeholders breakpoint={breakpoint} count={4} />
          <div className={styles.bannersContainer}>
            <GridBanners
              className={liveStyles.followingBanners}
              type={BannerType.FOLLOWING}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className={classnames(styles.root, styles[breakpoint])}>
          {renderStreams(streams)}
        </div>
        {isBannerShown && (
          <div className={styles.bannersContainer}>
            <GridBanners
              className={liveStyles.followingBanners}
              type={BannerType.FOLLOWING}
            />
          </div>
        )}
      </>
    );
  }, [streams, isLoading, renderStreams]);

  if (!streams.length && !isLoading && !hasLoadError) {
    return (
      <>
        <EmptyView activeMode={activeMode} />
        {isBannerShown && (
          <div className={styles.bannersContainer}>
            <GridBanners
              className={liveStyles.followingBanners}
              type={BannerType.FOLLOWING}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <InfiniteScroller
      data-testid={`content-${activeMode}`}
      refresh={dispatchRefresh}
      loadMore={dispatchLoadMore}
      canLoadMore={canLoadMore}
      hasLoadError={hasLoadError}
      isLoading={isLoading}
      className={classnames(
        className,
        isLoading && !streams.length && styles.overlay
      )}
      placeholders={<Placeholders breakpoint={breakpoint} />}
      ref={rootRef}
      bottomMarkerIntersectionObserverProps={intersectionObserverProps}
      {...rest}
    >
      {renderStreamsWithBanner}
    </InfiniteScroller>
  );
};

export default StreamsGrid;
