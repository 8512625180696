import React, { memo } from "react";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import styles from "./CardsSkeleton.scss";

interface CardsSkeletonProps {
  noPulse?: boolean;
}

const CardsSkeleton = ({ noPulse }: CardsSkeletonProps) => {
  const breakpoint = useBreakpoint();

  return (
    <div
      className={classnames(styles.root, styles[breakpoint], {
        [styles.noPulse]: noPulse,
      })}
    >
      <span className={styles.title} />
      <div className={styles.lots}>
        <div className={styles.lot} />
        {breakpoint === Breakpoints.DESKTOP && <div className={styles.lot} />}
      </div>
      <span className={styles.title} />
      <div className={styles.ownedCards}>
        <div className={styles.card} />
        <div className={styles.card} />
        <div className={styles.card} />
        <div className={styles.card} />
        <div className={styles.card} />
        <div className={styles.card} />
      </div>
    </div>
  );
};

export default memo(CardsSkeleton);
