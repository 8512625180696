import React, { memo } from "react";
import { LiveFeedType, PersonalFeedType } from "src/enums";
import { ComponentWithClassName } from "src/types/common";
import StreamersCarousel from "ui/common/streamTiles/components/StreamersCarousel";
import StreamsHeader from "ui/common/streamTiles/components/StreamsHeader";
import useLoadStreams from "ui/hooks/useLoadStreams";
import styles from "./TopStreamers.scss";

const Skeleton = memo<{
  className?: string;
  isFollowingMode: boolean;
}>(({ className, isFollowingMode }) => (
  <div className={className}>
    <StreamsHeader isFollowingMode={isFollowingMode} />
    <div className={styles.container}>
      <div className={styles.overlay} />
      {Array.from({ length: 6 }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={styles.tile}>
          <div className={styles.avatar} />
        </div>
      ))}
    </div>
  </div>
));

Skeleton.displayName = "TopStreamersSkeleton";

interface TopStreamersProps {
  activeMode: LiveFeedType.CREATORS | PersonalFeedType.OLD_FOLLOWING;
}

const TopStreamers: ComponentWithClassName<TopStreamersProps> = ({
  className,
  activeMode,
}) => {
  const { items: streams, isLoading } = useLoadStreams([activeMode], true);
  const isFollowingMode = activeMode === PersonalFeedType.OLD_FOLLOWING;

  if (streams.length === 0) {
    return isLoading ? (
      <Skeleton isFollowingMode={isFollowingMode} className={className} />
    ) : null;
  }

  return <StreamersCarousel activeMode={activeMode} streams={streams} />;
};

export default memo(TopStreamers);
