import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { EventFields, RegistrationSource, SCREEN_NAME } from "@analytics/enums";
import {
  GET_THE_APP_SOURCE_BOTTOM_SCREEN_TYPE,
  GetTheAppSource,
  GetTheAppTarget,
} from "@analytics/getTheApp";
import { ScreenViewReporter } from "@analytics/screenView/ScreenViewReportingContext";
import { NEW_SIGN_UP_PROMOTION_TYPE_OTHER } from "enums/newSignUpPromotionType";
import { Breakpoints, LoginModalTitleType } from "src/enums";
import { openLoginView } from "src/features/signin/exports/state/flows";
import { ComponentWithClassName } from "src/features/signin/imports/types";
import Button from "ui/common/button/Button";
import { ButtonSize, ButtonVariant } from "ui/common/button/types";
import { menuMessages } from "ui/common/dropdownMenu/menuList/messages";
import Benefits from "ui/common/getTheApp/benefits/Benefits";
import Buttons from "ui/common/getTheApp/buttons/Buttons";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { ReactComponent as StartBroadcastEmptyIcon } from "img/nav/start_broadcast.svg";
import styles from "./JoinTangoBanner.scss";

const UnauthorisedJoinTangoBanner: ComponentWithClassName = ({ className }) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();
  const screenSource = GetTheAppSource.BURGER_MENU;

  const onLoginClick = useCallback(() => {
    dispatch(
      openLoginView({
        registrationSource: RegistrationSource.SELF_PROFILE_ICON,
        newSignUpPromotionType: NEW_SIGN_UP_PROMOTION_TYPE_OTHER,
        title: LoginModalTitleType.REGULAR,
        screenType: GET_THE_APP_SOURCE_BOTTOM_SCREEN_TYPE[screenSource],
      })
    );
  }, [dispatch]);

  const onBroadcastClick = useCallback(() => {
    dispatch(
      openLoginView({
        registrationSource: RegistrationSource.START_STREAM,
        title: LoginModalTitleType.BROADCAST,
        screenType: GET_THE_APP_SOURCE_BOTTOM_SCREEN_TYPE[screenSource],
      })
    );
  }, [dispatch]);

  const isMobile = breakpoint !== Breakpoints.DESKTOP;

  return (
    <div
      className={classnames(styles.root, styles[breakpoint], className, {
        [styles.horizontal]: breakpoint === Breakpoints.TABLET,
        [styles.vertical]: breakpoint !== Breakpoints.TABLET,
      })}
      data-testid="join-tango-banner"
    >
      <ScreenViewReporter
        name={SCREEN_NAME.INSTALL_TANGO_APP}
        nested={false}
        additionalParams={{ [EventFields.SOURCE]: screenSource }}
      />
      <Typography
        className={styles.title}
        type={isMobile ? TYPOGRAPHY_TYPE.HEADLINE1 : TYPOGRAPHY_TYPE.HERO4}
      >
        <FormattedMessage {...menuMessages.joinTangoCommunity} />
      </Typography>
      <Benefits className={styles.benefits} />
      <div>
        <Buttons
          className={styles.buttons}
          onPrimaryClick={onLoginClick}
          primaryLabel={menuMessages.login}
          primaryTarget={GetTheAppTarget.LOG_IN}
          screenSource={screenSource}
        />
        <Button
          className={styles.buttons}
          variant={ButtonVariant.SECONDARY}
          onClick={onBroadcastClick}
          size={ButtonSize.BIG_48}
          leftIcon={StartBroadcastEmptyIcon}
          data-testid="join-tango-banner-broadcast"
        >
          <FormattedMessage {...menuMessages.startBroadcast} />
        </Button>
      </div>
    </div>
  );
};

export default UnauthorisedJoinTangoBanner;
