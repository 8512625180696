import React, { FC, memo, useCallback } from "react";
import { emitUiAction } from "@analytics/emitUiAction";
import {
  EventFields,
  EventReasons,
  SIGNOUT_COMMENT,
  SIGNOUT_TARGET,
} from "@analytics/enums";
import { VoidCallback } from "src/types/common";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import messages from "ui/common/intl/sharedMessages";
import useLogout from "ui/hooks/useLogout";
import { ReactComponent as LogoutIcon } from "img/new-ui/ic-logout-32.svg";

interface LogoutProps {
  onClick: VoidCallback;
}

const Logout: FC<LogoutProps> = ({ onClick }) => {
  const onLogout = useLogout({
    onClose: onClick,
    reason: EventReasons.SELF_LOGOUT,
  });
  const handleLogout = useCallback(() => {
    emitUiAction({
      target: SIGNOUT_TARGET.SIGNOUT,
      additionalParams: {
        [EventFields.COMMENT]: SIGNOUT_COMMENT.SETTINGS,
      },
    });
    onLogout();
  }, [onLogout]);

  return (
    <DropdownListItem
      titleMessage={messages.logout}
      Icon={LogoutIcon}
      onClick={handleLogout}
      data-testid="logout"
    />
  );
};

export default memo(Logout);
