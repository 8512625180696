import React, { MouseEventHandler } from "react";
import { FormattedMessage } from "react-intl";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import styles from "./removeNFTCard.scss";
type RemoveNFTCardProps = {
  confirm?: boolean;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
  onRemove: MouseEventHandler<HTMLButtonElement>;
};
const RemoveNFTCard: React.FC<RemoveNFTCardProps> = ({
  confirm,
  onCancel,
  onConfirm,
  onRemove,
}) => (
  <div className={styles.root}>
    <h2 className={styles.title}>
      {confirm ? (
        <FormattedMessage id="Confirm" defaultMessage="Confirm" />
      ) : (
        <FormattedMessage
          id="tango_cards_settings_cta"
          defaultMessage="Remove my Card from Auction"
        />
      )}
    </h2>

    {confirm ? (
      <p className={styles.confirm}>
        <FormattedMessage
          id="remove.card.from.auction.modal.description"
          defaultMessage="This action can't be undone and will permanently delete your Tango Card from the Auction."
        />
      </p>
    ) : (
      <p className={styles.description}>
        <FormattedMessage
          id="tango_cards_settings_remove_confirmation_1"
          defaultMessage="Are you sure you want to remove your Tango Card from the Auction?"
        />
      </p>
    )}
    <div className={styles.actions}>
      <Button
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.PRIMARY}
        className={styles.button}
        onClick={onCancel}
      >
        <FormattedMessage id="cancel" defaultMessage="Cancel" />
      </Button>
      <Button
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.SECONDARY}
        className={styles.button}
        onClick={confirm ? onConfirm : onRemove}
      >
        {confirm ? (
          <FormattedMessage id="Confirm" defaultMessage="Confirm" />
        ) : (
          <FormattedMessage id="Remove" defaultMessage="Remove" />
        )}
      </Button>
    </div>
  </div>
);
export default RemoveNFTCard;
