import { DeleteAccountReasonType } from "ui/common/deleteAccountView/enums";
import { deleteAccountReasonMessages } from "ui/common/deleteAccountView/messages";
import { DeleteAccountReasonOptionConfig } from "ui/common/deleteAccountView/types";

export const deleteAccountReasonDescriptionMaxLength = 254;

export const deleteAccountReasonOptions: DeleteAccountReasonOptionConfig[] = [
  {
    type: DeleteAccountReasonType.ANOTHER_ACCOUNT,
    message: deleteAccountReasonMessages.anotherAccountReason,
  },
  {
    type: DeleteAccountReasonType.ABUSE,
    message: deleteAccountReasonMessages.abuseReason,
  },
  {
    type: DeleteAccountReasonType.APP_BEHAVIOR,
    message: deleteAccountReasonMessages.appBehaviorReason,
  },
  {
    type: DeleteAccountReasonType.NOT_INTERESTED,
    message: deleteAccountReasonMessages.notInterestedReason,
  },
  {
    type: DeleteAccountReasonType.OTHER,
    message: deleteAccountReasonMessages.otherReason,
  },
];
