import React, { memo, useCallback, useMemo } from "react";
import {
  FormattedMessage,
  IntlShape,
  MessageDescriptor,
  defineMessages,
} from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import { SubscriptionProfile } from "api/subscriptionToStreamerList";
import { BasicProfile } from "src/types/profile/profile";
import { RootState } from "state/delegate";
import { profilesCacheSelectors } from "state/selectors";
import EmojiSpan from "./emoji/EmojiSpan";
import { ReactComponent as VerifiedIcon } from "img/verified.svg";
import styles from "./DisplayName.scss";

const messages = defineMessages({
  defaultName: {
    id: "displayname.default",
    defaultMessage: "Tango Member",
  },
  you: {
    id: "displayname.you",
    defaultMessage: "You",
  },
});

export const EMPTY_NAME_SET_BY_MOD = "\u200b"; // zero-width space, set by internal supporter when deleting a user's name

interface FormatNameParams {
  basicProfile?: BasicProfile | SubscriptionProfile;
  firstName?: string;
  isMyself?: boolean;
  lastName?: string;
  short?: boolean;
}

interface FormatDisplayNameParams extends FormatNameParams {
  intl: IntlShape;
  postProcessName?: (name: string) => string;
}

interface DisplayNameProps extends FormatNameParams {
  className?: string;
  showVerified?: boolean;
}

interface ConnectedDisplayNameProps
  extends Omit<DisplayNameProps, "basicProfile"> {
  accountId: string;
}

const isMessageDescriptor = (
  name: MessageDescriptor | string
): name is MessageDescriptor => typeof name !== "string";

const formatName = ({
  basicProfile,
  firstName,
  lastName,
  short,
  isMyself,
}: FormatNameParams): MessageDescriptor | string => {
  const resultingLastName = basicProfile?.lastName || lastName;
  const resultingFirstName = basicProfile?.firstName || firstName;

  if (isMyself) {
    return messages.you;
  }

  if (resultingFirstName || resultingLastName) {
    const nameComponents = [resultingFirstName, resultingLastName].reduce(
      (acc: string[], next) => {
        if (!next) {
          return acc;
        }

        const trimmed = next.trim();
        if (trimmed && trimmed !== EMPTY_NAME_SET_BY_MOD) {
          acc.push(trimmed);
        }

        return acc;
      },
      []
    );

    return (
      (short ? nameComponents[0] : nameComponents.join(" ")) ||
      messages.defaultName
    );
  }

  return messages.defaultName;
};

export const formatDisplayName = ({
  intl: { formatMessage },
  postProcessName,
  ...rest
}: FormatDisplayNameParams): string => {
  const name = formatName(rest);
  if (isMessageDescriptor(name)) {
    return formatMessage(name);
  }

  return postProcessName?.(name) || name;
};

const isBasicProfileWithVerified = (
  basicProfile: BasicProfile | SubscriptionProfile
): basicProfile is BasicProfile => "verified" in basicProfile;

const DisplayName: React.FC<DisplayNameProps> = ({
  className,
  basicProfile,
  firstName,
  lastName,
  short,
  isMyself,
  showVerified,
}) => {
  const preparedName = useMemo(
    () =>
      formatName({
        basicProfile,
        firstName,
        lastName,
        short,
        isMyself,
      }),
    [
      basicProfile?.firstName,
      basicProfile?.lastName,
      firstName,
      lastName,
      short,
      isMyself,
    ]
  );

  return isMessageDescriptor(preparedName) ? (
    <span className={className}>
      <FormattedMessage {...preparedName} />
    </span>
  ) : (
    <>
      {showVerified &&
      basicProfile != null &&
      isBasicProfileWithVerified(basicProfile) &&
      basicProfile.verified ? (
        <div className={classnames(styles.root, className)}>
          <EmojiSpan className={styles.name}>{preparedName}</EmojiSpan>
          <VerifiedIcon
            className={styles.verified}
            data-testid="verified-icon"
          />
        </div>
      ) : (
        <EmojiSpan className={className}>{preparedName}</EmojiSpan>
      )}
    </>
  );
};

export const ConnectedDisplayName: React.FC<ConnectedDisplayNameProps> = ({
  accountId,
  ...rest
}) => {
  const selector = useCallback(
    (state: RootState) =>
      profilesCacheSelectors.getBasicProfile(state, accountId),
    [accountId]
  );
  const basicProfile = useSelector(selector, shallowEqual);

  return <DisplayName basicProfile={basicProfile} {...rest} />;
};

export default memo(DisplayName);
