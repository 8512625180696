import React, { FC } from "react";
import classnames from "classnames";
import Linkify from "linkify-react";
import styles from "./LinkifyText.scss";

const getOptions = (isMyMessage: boolean) => ({
  className: classnames(styles.link, {
    [styles.myMessage]: isMyMessage,
  }),
  rel: "noreferrer noopener",
  defaultProtocol: "https",
  target: "_blank",
});

interface LinkifyTextProps {
  isMyMessage?: boolean;
}

const LinkifyText: FC<LinkifyTextProps> = ({
  children,
  isMyMessage = false,
}) => <Linkify options={getOptions(isMyMessage)}>{children}</Linkify>;

export default LinkifyText;
