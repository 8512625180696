import type { BannerWebImageConfiguration } from "src/types/banner";
import { Breakpoints } from "src/enums";

export const bannerWebBreakpointArtworkMapping: Record<
  Breakpoints,
  keyof BannerWebImageConfiguration
> = {
  [Breakpoints.DESKTOP]: "imageUrlDesktop",
  [Breakpoints.TABLET]: "imageUrlTablet",
  [Breakpoints.MOBILE]: "imageUrlMobile",
  [Breakpoints.SMALL_MOBILE]: "imageUrlMobile",
};
