import React, { useCallback, useContext } from "react";
import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";
import { ThemeContext } from "ui/Theme";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import sharedMessages from "ui/common/intl/sharedMessages";
import Switch from "ui/common/switch/Switch";
import useExclusiveClickHandler from "ui/hooks/useExclusiveClickHandler";
import { ReactComponent as DarkModeIcon } from "img/new-ui/burger_menu/dark-mode.svg";
import { ReactComponent as LightModeIcon } from "img/new-ui/burger_menu/light-mode.svg";
import styles from "ui/common/dropdownMenu/menuList/components/ExploreNavigationDarkModeMenuItem.scss";

const ExploreNavigationDarkModeMenuItem = () => {
  const { dark, setDark } = useContext(ThemeContext);

  const switchTheme = useCallback(() => {
    const nextDark = !dark;
    setDark(nextDark);
    emitEvent(EventNames.DARK_THEME, {
      [EventFields.DARK_MODE_ENABLED]: +nextDark,
    });
  }, [dark, setDark]);

  const handleClick = useExclusiveClickHandler(switchTheme);

  return (
    <DropdownListItem
      Icon={dark ? LightModeIcon : DarkModeIcon}
      onClick={handleClick}
      messageClassName={styles.container}
      data-testid="theme-menu-item"
      titleMessage={sharedMessages.darkTheme}
    >
      <Switch checked={dark} onClick={switchTheme} />
    </DropdownListItem>
  );
};

export default ExploreNavigationDarkModeMenuItem;
