import React, { memo } from "react";
import classnames from "classnames";
import { Nullable } from "src/types/common";
import { FormattedNumber } from "ui/common/Formatted";
import Typography, {
  TYPOGRAPHY_TYPE,
  TypographyProps,
} from "ui/common/typography/Typography";
import { ReactComponent as Coin } from "img/ic_coin.svg";
import styles from "./CoinsAmount.scss";

interface CoinsAmountProps {
  amount: Nullable<number | undefined>;
  className?: string;
  type?: TypographyProps["type"];
}

const CoinsAmount: React.FC<CoinsAmountProps> = ({
  className,
  amount,
  type = TYPOGRAPHY_TYPE.HEADLINE6,
}) => (
  <div
    className={classnames(styles.root, className)}
    data-testid="coins-amount"
  >
    <Typography type={type} className={styles.amount}>
      <Coin className={styles.coin} />
      <span>
        <FormattedNumber value={amount || 0} />
      </span>
    </Typography>
  </div>
);
export default memo(CoinsAmount);
