import { PURCHASE_ORIGIN } from "@analytics/enums";
import { Nullable } from "src/types/common";
import { PricePoint } from "src/types/personalOffer";
import { BannerOffer, ShopOffer } from "ui/scenes/landingPage/types";

export const assertIsPromoOffer = (
  offer?: Nullable<BannerOffer | PricePoint>
): offer is BannerOffer => !!(offer && offer.promoConfig);

export const assertIsShopOffer = (
  offer?: Nullable<BannerOffer | PricePoint | ShopOffer>
): offer is ShopOffer => !!(offer && offer.offerId);

export const getPurchaseOrigin = (
  origin: PURCHASE_ORIGIN | undefined,
  isCashier: boolean
) => {
  if (origin) {
    return origin;
  }

  return isCashier ? PURCHASE_ORIGIN.WEB_CASHIER : PURCHASE_ORIGIN.WEB_REFILL;
};
