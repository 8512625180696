import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { VoidCallback } from "src/types/common";
import { dropdownMenuSelectors, loginSelectors } from "state/selectors";
import {
  setDropdownAvatarState,
  setDropdownMenuState,
} from "state/tree/dropdownMenu";
import Avatar from "ui/common/avatar/Avatar";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import MenuList from "ui/common/dropdownMenu/menuList/MenuList";
import UnauthorisedMenuList from "ui/common/dropdownMenu/menuList/UnauthorisedMenuList";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useClickOutside from "ui/hooks/useClickOutside";
import { useHistoryChange } from "ui/hooks/useHistoryChange";
import useIntersectionObserver from "ui/hooks/useIntersectionObserver";
import useMyProfile from "ui/hooks/useMyProfile";
import usePortalElement from "ui/hooks/usePortalElement";
import useVipInfo from "ui/hooks/useVipInfo";
import { useUnmount } from "utils/miniReactUse";
import { ReactComponent as BurgerIcon } from "img/burger.svg";
import styles from "./DropdownMenu.scss";
import transition from "ui/transitions/FadeTransition.scss";

interface MyAvatarProps {
  isFilled: boolean;
  onClick: VoidCallback;
}

const MyAvatar = memo<MyAvatarProps>(({ isFilled, onClick }) => {
  const { basicProfile, liveStats } = useMyProfile(true);
  const { vipLabel } = useVipInfo({
    vipLevel: liveStats?.vipStatus,
  });

  return (
    <div
      className={classnames(
        styles.avatarContainer,
        isFilled && styles.filledAvatar
      )}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <Avatar
        className={styles.avatar}
        basicProfile={basicProfile}
        vipLabel={vipLabel}
        lazy={false}
      />
    </div>
  );
});

MyAvatar.displayName = "MyAvatar";

interface BurgerMenuProps {
  className?: string;
  isFilled?: boolean;
  type?: "burger";
}

interface UserAvatarMenuProps {
  className?: string;
  isFilled?: undefined;
  type?: "userAvatar";
}

const DropdownMenu: React.FC<BurgerMenuProps | UserAvatarMenuProps> = ({
  className,
  isFilled,
  type = "burger",
}) => {
  const dispatch = useDispatch();
  const {
    isBurgerMenuVisible,
    isAvatarMenuVisible = false,
    shouldHideInstantly,
  } = useSelector(dropdownMenuSelectors.getDropdownMenuState);
  const rootRef = useRef<HTMLDivElement>(null);
  const isInView = useIntersectionObserver(rootRef, {
    rootMargin: "0px 0px 0px 0px",
  });
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const isBurgerMenu = type === "burger";
  const el = usePortalElement(!isDesktop);
  const buttonSize = isDesktop ? ButtonSize.MEDIUM_32 : ButtonSize.SMALL_24;
  const isMenuShown =
    (isBurgerMenu && isBurgerMenuVisible) ||
    (!isBurgerMenu && isAvatarMenuVisible);

  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const shouldShowUnauthorisedMenu = !isLoggedIn && !isBurgerMenu;

  const toggleMenu = useCallback(
    () =>
      dispatch(
        isBurgerMenu
          ? setDropdownMenuState({ isBurgerMenuVisible: !isBurgerMenuVisible })
          : setDropdownAvatarState({
              isAvatarMenuVisible: !isAvatarMenuVisible,
            })
      ),
    [dispatch, isAvatarMenuVisible, isBurgerMenu, isBurgerMenuVisible]
  );

  const closeMenu = useCallback(
    (shouldHideInstantly = false) =>
      dispatch(
        isBurgerMenu
          ? setDropdownMenuState({
              isBurgerMenuVisible: false,
              shouldHideInstantly,
            })
          : setDropdownAvatarState({
              isAvatarMenuVisible: false,
              shouldHideInstantly,
            })
      ),
    [dispatch, isBurgerMenu]
  );

  useClickOutside(rootRef, closeMenu, isDesktop);

  useLayoutEffect(() => {
    if (isBurgerMenuVisible && !isDesktop) {
      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.removeProperty("overflow");
      };
    }
  }, [isBurgerMenuVisible, isDesktop]);

  useEffect(() => {
    if (isDesktop && !isInView && isBurgerMenuVisible) {
      closeMenu();
    }
  }, [isInView, isBurgerMenuVisible, isDesktop, closeMenu]);

  useEffect(() => {
    if (!isBurgerMenuVisible && shouldHideInstantly && !isDesktop) {
      dispatch(
        setDropdownMenuState({
          isBurgerMenuVisible,
          isAvatarMenuVisible,
          shouldHideInstantly: false,
        })
      );
    }
  }, [
    dispatch,
    isAvatarMenuVisible,
    isBurgerMenuVisible,
    isDesktop,
    shouldHideInstantly,
  ]);

  useHistoryChange(() => {
    closeMenu(true);
  });

  useUnmount(() => {
    closeMenu();
  });

  const menu = useMemo(() => {
    if (!isBurgerMenuVisible && shouldHideInstantly && !isDesktop) {
      return null;
    }

    return (
      <CSSTransition
        in={isMenuShown}
        classNames={transition}
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        {shouldShowUnauthorisedMenu ? (
          <UnauthorisedMenuList breakpoint={breakpoint} closeMenu={closeMenu} />
        ) : (
          <MenuList
            breakpoint={breakpoint}
            closeMenu={closeMenu}
            isBurgerMenu={isBurgerMenu}
          />
        )}
      </CSSTransition>
    );
  }, [
    isBurgerMenuVisible,
    shouldHideInstantly,
    isDesktop,
    isMenuShown,
    breakpoint,
    closeMenu,
    isBurgerMenu,
    shouldShowUnauthorisedMenu,
  ]);

  return (
    <div
      className={classnames(
        styles.root,
        styles[breakpoint],
        {
          [styles.userAvatar]: !isBurgerMenu,
        },
        className
      )}
      ref={rootRef}
    >
      {isBurgerMenu ? (
        <Button
          className={classnames(styles.btn, {
            [styles.active]: isBurgerMenuVisible,
            [styles.filled]: isFilled,
          })}
          size={buttonSize}
          variant={ButtonVariant.ICON_ONLY}
          onClick={toggleMenu}
          dataTestId="burger-menu-button"
        >
          <BurgerIcon />
        </Button>
      ) : (
        <MyAvatar
          isFilled={isDesktop && isAvatarMenuVisible}
          onClick={toggleMenu}
        />
      )}
      {isDesktop ? menu : createPortal(menu, el)}
    </div>
  );
};

DropdownMenu.displayName = "DropdownMenu";

export default memo(DropdownMenu);
