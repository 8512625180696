import React, { useLayoutEffect, useState, useCallback } from "react";
import { CSSTransition } from "react-transition-group";
import defaultTransition from "./DropdownTransition.scss";
import { VoidCallback } from "src/types/common";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";

interface DropdownProps {
  isVisible?: boolean;
  onClose?: VoidCallback;
  transition?: CSSTransitionProps["classNames"];
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  isVisible,
  onClose,
  transition,
  children,
  mountOnEnter = true,
  unmountOnExit = true,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const onEntered = useCallback(() => setShowDropdown(true), []);
  const onExiting = useCallback(() => setShowDropdown(false), []);

  useLayoutEffect(() => {
    if (!(isVisible && showDropdown)) {
      return;
    }

    const listener = () => {
      onExiting();
      onClose?.();
    };

    window.addEventListener("click", listener);

    return () => {
      window.removeEventListener("click", listener);
    };
  }, [isVisible, showDropdown]);

  return (
    <CSSTransition
      in={isVisible}
      classNames={transition || defaultTransition}
      timeout={300}
      unmountOnExit={unmountOnExit}
      mountOnEnter={mountOnEnter}
      onEntered={onEntered}
      onExiting={onExiting}
    >
      {children}
    </CSSTransition>
  );
};

export default Dropdown;
