import React, { FC, memo, useCallback } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { EventFields, RegistrationSource, SCREEN_NAME } from "@analytics/enums";
import {
  GET_THE_APP_SOURCE_BOTTOM_SCREEN_TYPE,
  GET_THE_APP_SOURCE_TO_LOGIN_MODAL_TITLE_TYPE,
  GetTheAppSource,
  GetTheAppTarget,
} from "@analytics/getTheApp";
import { ScreenViewReporter } from "@analytics/screenView/ScreenViewReportingContext";
import {
  NEW_SIGN_UP_PROMOTION_TYPE_CHAT,
  NEW_SIGN_UP_PROMOTION_TYPE_OTHER,
} from "enums/newSignUpPromotionType";
import { Breakpoints } from "src/enums";
import { openLoginView } from "src/features/signin/exports/state/flows";
import Benefits from "ui/common/getTheApp/benefits/Benefits";
import Buttons from "ui/common/getTheApp/buttons/Buttons";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import styles from "./JoinTangoBanner.scss";

interface JoinTangoBannerProps {
  className?: string;
  layout?: "horizontal" | "vertical";
  registrationSource?: RegistrationSource;
  screenSource: GetTheAppSource;
  title: MessageDescriptor;
}

const JoinTangoBanner: FC<JoinTangoBannerProps> = ({
  className,
  layout = "vertical",
  registrationSource = RegistrationSource.UNKNOWN,
  screenSource,
  title,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();

  const onLoginClick = useCallback(() => {
    dispatch(
      openLoginView({
        registrationSource,
        newSignUpPromotionType:
          registrationSource === RegistrationSource.CHAT_TAB
            ? NEW_SIGN_UP_PROMOTION_TYPE_CHAT
            : NEW_SIGN_UP_PROMOTION_TYPE_OTHER,
        title: GET_THE_APP_SOURCE_TO_LOGIN_MODAL_TITLE_TYPE[screenSource],
        screenType: GET_THE_APP_SOURCE_BOTTOM_SCREEN_TYPE[screenSource],
      })
    );
  }, [dispatch, registrationSource, screenSource]);

  const isMobile = breakpoint !== Breakpoints.DESKTOP;

  return (
    <div
      className={classnames(
        styles.root,
        styles[breakpoint],
        styles[layout],
        className
      )}
    >
      <ScreenViewReporter
        name={SCREEN_NAME.INSTALL_TANGO_APP}
        nested={false}
        additionalParams={{ [EventFields.SOURCE]: screenSource }}
      />
      <Typography
        className={styles.title}
        type={TYPOGRAPHY_TYPE[isMobile ? "HEADLINE1" : "HERO4"]}
      >
        <FormattedMessage {...title} />
      </Typography>
      <Benefits className={styles.benefits} />
      <Buttons
        className={styles.buttons}
        onPrimaryClick={onLoginClick}
        primaryLabel={sharedMessages.login}
        primaryTarget={GetTheAppTarget.LOG_IN}
        screenSource={screenSource}
      />
    </div>
  );
};

export default memo(JoinTangoBanner);
