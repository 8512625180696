import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import classnames from "classnames";
import Typography from "ui/common/typography/Typography";
import { TYPOGRAPHY_TYPE } from "ui/common/typography/types";
import styles from "./TabsSwitch.scss";

export type Tab = { label: MessageDescriptor; value: string };

interface TabsSwitchProps {
  activeTab: Tab;
  onChange: (tab: Tab) => void;
  tabs: Tab[];
}

const TabsSwitch = ({ tabs, activeTab, onChange }: TabsSwitchProps) => {
  const [sliderStyle, setSliderStyle] = useState({});
  const tabRefs = useRef<Record<string, HTMLButtonElement>>({});

  const handleClick = (tab: Tab) => onChange(tab);

  const handleRef = (el: HTMLButtonElement | null, key: string) =>
    el && (tabRefs.current[key] = el);

  useEffect(() => {
    const el = tabRefs.current[activeTab.value];

    setSliderStyle({
      left: `${el.offsetLeft}px`,
      width: `${el.offsetWidth}px`,
    });
  }, [activeTab, tabs]);

  return (
    <div className={styles.toggleContainer}>
      {tabs.map((option) => (
        <button
          key={option.value}
          ref={(el) => handleRef(el, option.value)}
          className={classnames(styles.toggleButton, {
            [styles.active]: option.value === activeTab.value,
          })}
          onClick={() => handleClick(option)}
        >
          <Typography type={TYPOGRAPHY_TYPE.HEADLINE4}>
            <FormattedMessage {...option.label} />
          </Typography>
        </button>
      ))}

      <div className={styles.toggleSlider} style={sliderStyle} />
    </div>
  );
};

export default TabsSwitch;
