import React, {
  MouseEvent,
  MutableRefObject,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { EventFields } from "@analytics/enums";
import { Breakpoints, LiveFeedType } from "src/enums";
import { Nullable } from "src/types/common";
import useIsRecommended from "src/ui/navigation/useIsRecommended";
import ExploreTab from "ui/common/tabs/ExploreRadioChipTabs/ExploreTab";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useScroll, { AXIS } from "ui/hooks/useScroll";
import useUiAction from "ui/hooks/useUiAction";
import NearbyCountriesPicker from "ui/scenes/live/shared/nearbyCountries/NearbyCountriesPicker";
import useExploreLiveTabConfigs from "ui/scenes/live/shared/useExploreLiveTabConfigs";
import { useIsNavigationLinkLeadToBase } from "ui/scenes/live/shared/useIsNavigationLinkLeadToBase";
import styles from "ui/common/tabs/ExploreRadioChipTabs/ExploreRadioChipsTabs.scss";

interface ExploreRadioChipsTabsProps {
  activeTabId?: string;
  className?: string;
  dataTestId?: string;
}

const ExploreRadioChipsTabs: React.FC<ExploreRadioChipsTabsProps> = ({
  className,
  activeTabId,
  dataTestId = "",
}) => {
  const exploreTabsConfig = useExploreLiveTabConfigs();
  const tabPanelRef = useRef<HTMLDivElement>(null);
  const breakpoint = useBreakpointPrecise();
  const [scrollTarget, setScrollTarget] =
    useState<MutableRefObject<Nullable<HTMLAnchorElement>>>();
  const isBasePath = useIsNavigationLinkLeadToBase();
  const isRecommendedPath = useIsRecommended();
  const scroll = useScroll(tabPanelRef);

  const isEmptyTabs = isBasePath || exploreTabsConfig.length <= 1;
  const baseOrRecommendedPath = isBasePath || isRecommendedPath;
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  const isRecommendedAndDesktop = baseOrRecommendedPath && isDesktop;
  const isRecommendedAndMobile = baseOrRecommendedPath && !isDesktop;

  useEffect(() => {
    if (!scrollTarget?.current) {
      return;
    }

    scroll(scrollTarget, AXIS.X);
  }, [scrollTarget, scroll]);

  const emitChipClick = useUiAction<[MouseEvent<HTMLAnchorElement>]>({
    action: "click",
    target: "live_mode_change",
    additionalParams: (event) => ({
      [EventFields.TARGET]: `${event.currentTarget.id}_sub_tab`,
    }),
  });

  if (isRecommendedAndDesktop) {
    return (
      <div className={styles.titleRoot}>
        <Typography type={TYPOGRAPHY_TYPE.HEADLINE1}>
          <FormattedMessage
            id="explore.forYou.title"
            defaultMessage="Recommended for you"
          />
        </Typography>
      </div>
    );
  }

  if (isEmptyTabs || isRecommendedAndMobile) {
    return null;
  }

  return (
    <div
      data-testid={dataTestId}
      className={classnames(className, styles.root, styles[breakpoint])}
    >
      <div className={styles.content} ref={tabPanelRef}>
        {exploreTabsConfig.map((cfg) => (
          <ExploreTab
            key={cfg.to}
            config={cfg}
            isActive={activeTabId === cfg.id}
            onActive={setScrollTarget}
            onClick={emitChipClick}
            isMobile={
              breakpoint === Breakpoints.MOBILE ||
              breakpoint === Breakpoints.SMALL_MOBILE
            }
          />
        ))}
      </div>
      {breakpoint === Breakpoints.DESKTOP &&
        activeTabId === LiveFeedType.NEARBY && (
          <NearbyCountriesPicker
            className={styles.countryPicker}
            editorClassName={styles.countryEditor}
          />
        )}
    </div>
  );
};

export default memo(ExploreRadioChipsTabs);
