import React, { memo, useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { createLocationStateForLiveStreamFeed } from "@analytics/livePlay/streamLocationState";
import { LiveFeedType, PersonalFeedType } from "src/enums";
import { ComponentWithClassName } from "src/types/common";
import { BasicStreamInfo } from "src/types/richFragment/Stream";
import CarouselButton from "ui/common/buttons/CarouselButton";
import StreamsHeader from "ui/common/streamTiles/components/StreamsHeader";
import Tile from "ui/common/streamTiles/components/Tile";
import styles from "./StreamersCarousel.scss";

const emblaCfg = {
  align: "start",
  slidesToScroll: 1,
  containScroll: "trimSnaps",
  watchDrag: false,
  duration: 28,
} as const;

interface TopStreamersCarouselProps {
  activeMode: LiveFeedType.CREATORS | PersonalFeedType.OLD_FOLLOWING;
  streams: BasicStreamInfo[];
}

const StreamersCarousel: ComponentWithClassName<TopStreamersCarouselProps> = ({
  streams,
  activeMode,
  className,
}) => {
  const [emblaRef, embla] = useEmblaCarousel(emblaCfg);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  useEffect(() => {
    if (!embla) return;

    const onSelect = () => {
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    };

    embla.on("select", onSelect);
    embla.on("reInit", onSelect);
    onSelect();

    return () => {
      embla.off("select", onSelect);
      embla.off("reInit", onSelect);
    };
  }, [embla]);

  useEffect(() => {
    embla?.reInit(emblaCfg);
  }, [streams.length, embla]);

  return (
    <div className={className} id="top-streamers">
      <StreamsHeader
        isFollowingMode={activeMode === PersonalFeedType.OLD_FOLLOWING}
      />
      <div className={styles.emblaWrapper}>
        <div ref={emblaRef} className={styles.embla}>
          <div className={styles.emblaContainer}>
            {streams.map((stream, index) => (
              <Tile
                key={`top-${stream.id}`}
                className={styles.tile}
                stream={stream}
                linkState={createLocationStateForLiveStreamFeed({
                  mode: activeMode,
                  rank: index,
                })}
                showStreamerInfo={false}
              />
            ))}
          </div>
        </div>
        {prevBtnEnabled && (
          <CarouselButton
            type="prev"
            className={styles.btnPrev}
            onClick={scrollPrev}
          />
        )}
        {nextBtnEnabled && (
          <CarouselButton
            type="next"
            className={styles.btnNext}
            onClick={scrollNext}
          />
        )}
      </div>
    </div>
  );
};

export default memo(StreamersCarousel);
