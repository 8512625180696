import { shallowEqual, useSelector } from "react-redux";
import { CurrencyPricePoint } from "src/enums";
import { getSalesTaxEnabled } from "state/abTests";
import { taxSelectors } from "state/selectors";
import { useLoadTaxesV2 } from "ui/hooks/useLoadTaxes";
import getPrice from "ui/modal/modalViews/buyCoins/utils/getPrice";

export type TaxPropsType = {
  marketOfferId: string;
  priceParams?: CurrencyPricePoint;
  v2?: boolean;
};

const useTaxPrice = ({
  marketOfferId,
  priceParams,
  v2,
}: TaxPropsType): number[] => {
  const isSalesTaxEnabled = useSelector(getSalesTaxEnabled);
  const taxPercentage = useSelector(taxSelectors.data, shallowEqual);

  const taxPercentageV2 = useLoadTaxesV2({
    marketOfferId,
    enabled: v2,
    currency: priceParams?.currency,
  });

  if (!priceParams) {
    return [0, 0];
  }

  if (
    !(isSalesTaxEnabled && (v2 ? taxPercentageV2 !== 0 : taxPercentage !== 0))
  ) {
    return [0, priceParams.value];
  }

  return getPrice(priceParams.value, v2 ? taxPercentageV2 : taxPercentage);
};

export default useTaxPrice;
