import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import { GetTheAppMenuItem } from "src/features/getTheApp/exports/components";
import { ComponentWithClassName, VoidCallback } from "src/types/common";
import { loginSelectors } from "state/selectors";
import UnauthorisedJoinTangoBanner from "ui/common/banner/UnauthorisedJoinTangoBanner";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Divider from "ui/common/divider/Divider";
import CustomerSupportItem from "ui/common/dropdownMenu/menuList/components/CustomerSupportItem";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import ExploreNavigationDarkModeMenuItem from "ui/common/dropdownMenu/menuList/components/ExploreNavigationDarkModeMenuItem";
import LegalLinks from "ui/common/dropdownMenu/menuList/components/LegalLinks";
import Login from "ui/common/dropdownMenu/menuList/components/Login";
import LanguageMenuList from "ui/common/dropdownMenu/menuList/components/languageSwitcher/LanguageMenuList";
import { menuMessages } from "ui/common/dropdownMenu/menuList/messages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import useExclusiveClickHandler from "ui/hooks/useExclusiveClickHandler";
import { linkToBasePath } from "ui/navigation/links";
import { ReactComponent as BackIcon } from "img/new-ui/burger_menu/ic-burger-mune-back.svg";
import { ReactComponent as ChevronRightIcon } from "img/user_menu_icons/chevron-right.svg";
import { ReactComponent as HomeIcon } from "img/user_menu_icons/home.svg";
import { ReactComponent as LanguageIcon } from "img/user_menu_icons/language.svg";
import styles from "ui/common/dropdownMenu/menuList/MenuList.scss";

interface UnauthorisedMenuListProps {
  breakpoint: Breakpoints;
  closeMenu: VoidCallback;
}

const UnauthorisedMenuList: ComponentWithClassName<
  UnauthorisedMenuListProps
> = ({ closeMenu, className, breakpoint }) => {
  const [isLanguageActive, setIsLanguageActive] = useState(false);
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const isAuthorized = useSelector(loginSelectors.isAuthorized);
  const intercept = useExclusiveClickHandler();
  const handleClose = useExclusiveClickHandler(closeMenu);

  const openLanguageMenu = useExclusiveClickHandler(() =>
    setIsLanguageActive(true)
  );

  if (isLanguageActive) {
    return (
      <LanguageMenuList
        breakpoint={breakpoint}
        closeMenu={closeMenu}
        setIsLanguageActive={setIsLanguageActive}
      />
    );
  }

  return (
    <Typography
      type={isDesktop ? TYPOGRAPHY_TYPE.PARAGRAPH2 : TYPOGRAPHY_TYPE.PARAGRAPH1}
      onClick={isDesktop ? closeMenu : undefined}
      className={classnames(
        styles.root,
        styles[breakpoint],
        styles.right,
        className
      )}
      as="div"
      data-testid="explore-unathorized-menu"
    >
      <div className={styles.container}>
        {!isDesktop && (
          <div className={styles.header} onClick={intercept}>
            <Button
              className={styles.back}
              size={ButtonSize.MEDIUM_32}
              variant={ButtonVariant.ICON_ONLY}
              onClick={handleClose}
              dataTestId="unathorized-menu-back"
            >
              <BackIcon />
            </Button>
          </div>
        )}

        {!isDesktop && (
          <>
            <UnauthorisedJoinTangoBanner className={styles.banner} />
            <Divider />
            <GetTheAppMenuItem withDivider />
          </>
        )}
        {!isDesktop && (
          <DropdownListItem
            to={linkToBasePath}
            Icon={HomeIcon}
            data-testid="unathorized-menu-homePage-link"
            titleMessage={menuMessages.home}
          />
        )}

        {!isDesktop && <Divider />}

        <ExploreNavigationDarkModeMenuItem />
        {isAuthorized && (
          <DropdownListItem
            onClick={openLanguageMenu}
            Icon={LanguageIcon}
            data-testid="language"
            titleMessage={menuMessages.language}
          >
            <div className={styles.forward}>
              <ChevronRightIcon />
            </div>
          </DropdownListItem>
        )}

        <CustomerSupportItem withDivider />

        {isDesktop && (
          <>
            <GetTheAppMenuItem />
            <Divider />
            <Login onClick={closeMenu} />
          </>
        )}
        {!isDesktop && <LegalLinks />}
      </div>
    </Typography>
  );
};

export default memo(UnauthorisedMenuList);
