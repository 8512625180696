import React, {
  CSSProperties,
  MouseEvent,
  MutableRefObject,
  memo,
  useEffect,
  useRef,
} from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { Nullable } from "src/types/common";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { TabToRenderConfig } from "ui/scenes/live/shared/types";
import styles from "ui/common/tabs/ExploreRadioChipTabs/ExploreTab.scss";

interface ExploreTabProps {
  className?: string;
  config: TabToRenderConfig;
  isActive: boolean;
  isMobile?: boolean;
  onActive: (ref: MutableRefObject<Nullable<HTMLAnchorElement>>) => void;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const ExploreTab = memo<ExploreTabProps>(
  ({ className, config, isActive, onClick, onActive, isMobile }) => {
    const tabRef = useRef<Nullable<HTMLAnchorElement>>(null);

    const { id, icon, to, title, dataTestId } = config;

    useEffect(() => {
      if (isActive) {
        onActive(tabRef);
      }
    }, [isActive]);

    return (
      <NavLink
        ref={tabRef}
        className={classnames(styles.link, className, {
          [styles.active]: isActive,
          [styles.iconPadding]: !!icon && isActive,
        })}
        to={to}
        onClick={onClick}
        data-testid={dataTestId}
        id={id}
        onTouchStart={emptyFunction}
      >
        <Typography
          className={styles.container}
          type={
            isMobile ? TYPOGRAPHY_TYPE.HEADLINE5 : TYPOGRAPHY_TYPE.HEADLINE4
          }
        >
          <div
            className={styles.icon}
            style={
              { "--mask-url": `url(${icon}) center/contain` } as CSSProperties
            }
          />

          {title}
        </Typography>
      </NavLink>
    );
  }
);

ExploreTab.displayName = "ChipTab";

export default ExploreTab;
