import React, { memo } from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { EventReasons } from "@analytics/enums";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useLogout from "ui/hooks/useLogout";
import sharedMessages from "../intl/sharedMessages";
import { ErrorViewTypes } from "./enum";
import { ReactComponent as OopsIcon } from "img/ic-404.svg";
import { ReactComponent as SuspendedIcon } from "img/suspended.svg";
import styles from "./ErrorView.scss";

const reload = () => window.location.reload();

const config = {
  [ErrorViewTypes.COMMON_ERROR]: {
    messages: defineMessages({
      body: {
        id: "error-boundary.bodyText",
        defaultMessage: "Something went wrong. We will be back shortly",
      },
      button: {
        id: "error-boundary.reload",
        defaultMessage: "Reload",
      },
    }),
    icon: OopsIcon,
  },
  [ErrorViewTypes.SUSPENDED_ACCOUNT_ERROR]: {
    messages: defineMessages({
      body: {
        id: "suspended.bodyText",
        defaultMessage:
          "Your account was suspended. Please contact our support for more information",
      },
      button: {
        id: "suspended.cta",
        defaultMessage: "Support",
      },
    }),
    icon: SuspendedIcon,
  },
};

interface ErrorViewProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  testId: string;
  type?: ErrorViewTypes;
}

const ErrorView: React.FC<ErrorViewProps> = ({
  testId,
  type = ErrorViewTypes.COMMON_ERROR,
  onClick = reload,
}) => {
  const { icon: Icon, messages } = config[type];
  const onLogout = useLogout({
    onClose: emptyFunction,
    reason: EventReasons.SELF_LOGOUT,
  });
  const breakpoint = useBreakpoint();

  return (
    <div className={styles.root} data-testid={testId}>
      <div className={styles.container}>
        <Icon className={styles.icon} />
        <div className={styles.title}>
          <FormattedMessage {...sharedMessages.oops} />
        </div>
        <div className={styles.body}>
          <FormattedMessage {...messages.body} />
        </div>
        <div className={classnames(styles.buttons, styles[breakpoint])}>
          <Button
            className={styles.button}
            onClick={onClick}
            size={ButtonSize.BIG_48}
            variant={ButtonVariant.PRIMARY}
          >
            <FormattedMessage {...messages.button} />
          </Button>
          {type === ErrorViewTypes.SUSPENDED_ACCOUNT_ERROR && (
            <Button
              className={styles.button}
              onClick={onLogout}
              size={ButtonSize.BIG_48}
              variant={ButtonVariant.SECONDARY}
            >
              <FormattedMessage id="sign-out" defaultMessage="Sign out" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ErrorView);
