import React, { memo, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { LandingPageDismissType } from "src/enums";
import CurrencyPicker from "ui/common/currencyPicker/CurrencyPicker";
import Divider from "ui/common/divider/Divider";
import sharedMessages from "ui/common/intl/sharedMessages";
import { PersonalOfferBannersDesktop } from "ui/common/personalOffer/banners/PersonalOfferBannersDesktop";
import getPromotionMessage from "ui/utils/getPromotionMessage";
import styles from "./OffersHeading.scss";

type OffersHeadingPropsTye = {
  isCashier: boolean;
  isDarkTheme: boolean;
  promotionType?: LandingPageDismissType;
};

const OffersHeading: React.FC<OffersHeadingPropsTye> = ({
  isDarkTheme,
  promotionType,
  isCashier,
}) => {
  const promotionTypeMessage = getPromotionMessage(promotionType);
  const [hasBanners, setHasBanners] = useState(false);

  const onBannersRender = useCallback(() => {
    setHasBanners(true);
  }, []);

  return (
    <div
      className={classnames(styles.root, {
        [styles.dark]: isDarkTheme,
      })}
    >
      {promotionTypeMessage && (
        <>
          <div className={classnames(styles.container, styles.fullWidth)}>
            <span className={styles.title} data-testid="offers-title">
              <FormattedMessage {...promotionTypeMessage} />
            </span>
            <span
              className={styles.description}
              data-testid="offers-description"
            >
              <FormattedMessage {...sharedMessages.enjoyMore} />
            </span>
          </div>
          <Divider className={styles.divider} />
        </>
      )}
      <div
        className={classnames(styles.parent, {
          [styles.emptyBanners]: !hasBanners,
        })}
      >
        <div className={classnames(styles.container, styles.info)}>
          <span
            key="get-coins"
            className={styles.title}
            data-testid="offers-get-coins-title"
          >
            <FormattedMessage
              id="cashier.get.coins.label"
              defaultMessage="Get Coins"
            />
          </span>
          <span
            className={styles.description}
            data-testid="offers-get-coins-description"
          >
            <FormattedMessage
              id="cashier.get.coins.description"
              defaultMessage="to support broadcasters"
            />
          </span>
        </div>
        <div className={styles.bannersRoot}>
          <PersonalOfferBannersDesktop
            isCashier={isCashier}
            onBannersReady={onBannersRender}
          />
        </div>
        <div className={styles.currencyPickerWrapper}>
          <CurrencyPicker
            className={styles.currencyPicker}
            padding={12}
            borderRadius={8}
            isDarkTheme={isDarkTheme}
            isShowLabel={false}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(OffersHeading);
