import React, { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { shouldUseNonNativeEmojis } from "state/abTests";
import emptyFunction from "fbjs/lib/emptyFunction";
import { deviceInfoSelectors } from "state/selectors";
import styles from "./Emoji.scss";

const identity = emptyFunction.thatReturnsArgument;

type ConvertEmojis = <T extends string | HTMLElement>(what: T) => T;

const EmojiContext = React.createContext<{
  shouldConvertEmojis: boolean;
  convertEmojis: ConvertEmojis;
  removeEmojisIfUnsupported: (str: string) => string;
}>({
  shouldConvertEmojis: false,
  convertEmojis: identity,
  removeEmojisIfUnsupported: identity,
});

const loadTwemoji = () =>
  import(
    /* webpackChunkName: "twemoji" */
    "twemoji"
  ).then(({ default: twemoji }) => twemoji);

const { Provider } = EmojiContext;

const twemojiConfig = {
  folder: "svg",
  ext: ".svg",
  className: styles.twemoji,
};

export const EmojiProvider: React.FC = ({ children }) => {
  const [twemojiApi, setTwemojiApi] = useState<null | Twemoji>(null);
  const shouldOverrideNativeEmojis = useSelector(shouldUseNonNativeEmojis);
  const os = useSelector(deviceInfoSelectors.getDeviceOS);

  const providerValue = useMemo(() => {
    const supportsNativeEmojis = window.Modernizr.emoji && os !== "Windows"; // windows doesn't work well, force twemojis there
    return {
      shouldConvertEmojis: !supportsNativeEmojis || shouldOverrideNativeEmojis,
      convertEmojis:
        shouldOverrideNativeEmojis && twemojiApi?.parse
          ? <T extends string | HTMLElement>(node: T) =>
              twemojiApi.parse(node, twemojiConfig)
          : identity,
      removeEmojisIfUnsupported:
        // @ts-ignore twemojiApi.replace is missing in types but actually exists
        shouldOverrideNativeEmojis && twemojiApi?.replace
          ? (str: string) =>
              // @ts-ignore
              twemojiApi.replace(str, () => "\u200b").replace(/\s+/g, " ")
          : identity,
    };
  }, [shouldOverrideNativeEmojis, os, twemojiApi]);

  useEffect(() => {
    if (providerValue.shouldConvertEmojis) {
      loadTwemoji().then(setTwemojiApi);
    }
  }, [providerValue.shouldConvertEmojis]);

  return <Provider value={providerValue}>{children}</Provider>;
};

export default EmojiContext;
