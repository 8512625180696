import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import isNonLinkProps from "src/utils/isNonLinkProps";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { ButtonProps, ButtonSize, ButtonVariant } from "./types";
import styles from "./Button.scss";

export { ButtonSize, ButtonVariant };

const sizeToTypographyType = {
  [ButtonSize.EXTRABIG_64]: TYPOGRAPHY_TYPE.HEADLINE1,
  [ButtonSize.BIG_48]: TYPOGRAPHY_TYPE.HEADLINE4,
  [ButtonSize.MEDIUM_32]: TYPOGRAPHY_TYPE.HEADLINE5,
  [ButtonSize.SMALL_24]: TYPOGRAPHY_TYPE.MINI,
  [ButtonSize.EXTRASMALL_16]: null,
  [ButtonSize.CIRCLE_EXTRABIG_56]: null,
  [ButtonSize.CIRCLE_BIG_48]: null,
  [ButtonSize.CIRCLE_MEDIUM_40]: null,
  [ButtonSize.CIRCLE_SMALL_32]: null,
  [ButtonSize.CIRCLE_EXTRASMALL_24]: null,
};

const Button: React.FC<ButtonProps> = ({
  size,
  variant,
  active,
  disabled,
  className,
  children,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  uppercase,
  external,
  dataTestId,
  isSingleLineDisabled,
  ...rest
}) => {
  const { locale } = useIntl();
  const typographyType = sizeToTypographyType[size];
  const classNames = useMemo(
    () =>
      classnames(
        styles.root,
        styles[locale],
        styles[size],
        {
          [styles[variant]]: variant,
          [styles.circle]:
            variant !== ButtonVariant.ICON_ONLY && !typographyType,
          [styles.disabled]: disabled,
          [styles.active]: active,
          [styles.uppercase]: uppercase,
          [styles.withLeftIcon]: !!LeftIcon,
          [styles.withRightIcon]: !!RightIcon,
          [styles.singleLine]: !isSingleLineDisabled,
        },
        className
      ),
    [
      locale,
      size,
      active,
      disabled,
      className,
      variant,
      typographyType,
      uppercase,
      LeftIcon,
      RightIcon,
    ]
  );

  const content =
    variant !== ButtonVariant.ICON_ONLY && typographyType ? (
      <Typography className={styles.wrapper} type={typographyType}>
        {LeftIcon && <LeftIcon className={styles.leftIcon} />}
        {!!children && <span className={styles.container}>{children}</span>}
        {RightIcon && <RightIcon className={styles.rightIcon} />}
      </Typography>
    ) : (
      <span className={styles.wrapper}>{children}</span>
    );

  if (isNonLinkProps(rest)) {
    const { innerRef: buttonInnerRef, ...buttonOtherProps } = rest;

    return (
      <button
        key={variant}
        className={classNames}
        onTouchStart={emptyFunction}
        disabled={disabled}
        ref={buttonInnerRef}
        data-testid={dataTestId}
        {...buttonOtherProps}
      >
        {content}
      </button>
    );
  }

  const { innerRef: linkInnerRef, to, ...linkOtherProps } = rest;

  return external && typeof to === "string" ? (
    <a
      key={variant}
      className={classNames}
      onTouchStart={emptyFunction}
      href={to}
      ref={linkInnerRef}
      data-testid={dataTestId}
      {...linkOtherProps}
    >
      {content}
    </a>
  ) : (
    <Link
      key={variant}
      className={classNames}
      onTouchStart={emptyFunction}
      ref={linkInnerRef}
      to={to}
      data-testid={dataTestId}
      {...linkOtherProps}
    >
      {content}
    </Link>
  );
};

export default Button;
