import React, {
  useState,
  useRef,
  memo,
  ImgHTMLAttributes,
  ReactElement,
} from "react";
import { useLazyLoadImage } from "ui/hooks/useLazyLoadImage";
import { ReactComponent as GiftPlaceholder } from "img/gift-placeholder.svg";
import style from "./LazyImage.scss";

const LazyImage = (
  props: ImgHTMLAttributes<HTMLImageElement>
): ReactElement => {
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef<HTMLDivElement>(null);
  useLazyLoadImage(imgRef, () => {
    setIsInView(true);
  });

  return (
    <div ref={imgRef}>
      {isInView ? (
        <img {...props} />
      ) : (
        <GiftPlaceholder className={style.icon} />
      )}
    </div>
  );
};

export default memo(LazyImage);
