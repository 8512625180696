import React, { CSSProperties, ReactNode, memo, useMemo } from "react";
import classnames from "classnames";
import { ComponentWithClassName } from "src/types/common";
import styles from "./Skeleton.scss";

export interface SkeletonProps {
  children?: ReactNode;
  height?: number;
  style?: CSSProperties;
  variant?: string;
  width?: number;
}

const Skeleton: ComponentWithClassName<SkeletonProps> = ({
  className,
  width,
  height,
  style,
  variant = "rect",
  children,
}) => {
  const skeletonStyles = useMemo(
    () => ({ width, height, ...style }),
    [height, style, width]
  );

  return (
    <span
      className={classnames(styles.skeleton, styles[variant], className)}
      style={skeletonStyles}
    >
      {children}
    </span>
  );
};

export default memo(Skeleton);
