import React, { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import PropTypes from "prop-types";
import { EventFields, RegistrationSource } from "@analytics/enums";
import { emitOpenProfileEvent } from "@analytics/profile/emitOpenProfileEvent";
import { useProfileEvent } from "@analytics/profile/useProfileEvent";
import { STREAM_SCOPE } from "enums/modalScope";
import {
  FollowSource,
  MiniProfileEntranceSource,
  ProfileType,
} from "src/enums";
import { getIsInJosh } from "src/utils/joshUtils";
import {
  getBroadcasterId,
  profilesCacheSelectors,
  subscriptionPerStreamerListSelectors,
  userSelectors,
  viewerSessionSelectors,
} from "state/selectors";
import DisplayName from "ui/common/DisplayName";
import { FormattedNumber } from "ui/common/Formatted";
import Avatar from "ui/common/avatar/Avatar";
import { ButtonSize } from "ui/common/button/types";
import FollowCTA from "ui/common/buttons/FollowCTA";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import useOpenMiniProfileOnClick from "ui/hooks/useOpenMiniProfileOnClick";
import useVipInfo from "ui/hooks/useVipInfo";
import coinIcon from "img/ic_coin.svg?url";
import styles from "./TopGiftersListItem.scss";

const selectorFactory = (accountId) => (state) => ({
  basicProfile: profilesCacheSelectors.getBasicProfile(state, accountId),
  liveStats: profilesCacheSelectors.getLiveStats(state, accountId),
  creditsInStream:
    viewerSessionSelectors.getGiftedAmount(state, accountId) || 0,
  isMyself: userSelectors.getMyAccountId(state) === accountId,
  vipStatus:
    (profilesCacheSelectors.getLiveStats(state, accountId) || {}).vipStatus ||
    null,
  isSubscribed:
    subscriptionPerStreamerListSelectors.getIsUserSubscribedOnStreamer(
      state,
      accountId
    ),
  streamerBasicProfile: profilesCacheSelectors.getBasicProfile(
    state,
    getBroadcasterId(state)
  ),
});

const TopGiftersListItem = ({ accountId, viewer, compactCta, mobile }) => {
  const selector = useMemo(() => selectorFactory(accountId), [accountId]);
  const getProfileBIEvent = useProfileEvent(
    MiniProfileEntranceSource.STREAM_TOP_GIFTERS,
    ProfileType.PROFILE
  );
  const { basicProfile, creditsInStream, isMyself, vipStatus } = useSelector(
    selector,
    shallowEqual
  );

  const openMiniProfile = useOpenMiniProfileOnClick({
    accountId,
    followSource: FollowSource.STREAM_GIFTER_LIST,
    miniProfileEntranceSource: MiniProfileEntranceSource.STREAM_TOP_GIFTERS,
    showPhotos: true,
    modalScope: STREAM_SCOPE,
  });

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();

      if (getIsInJosh()) {
        return;
      }

      emitOpenProfileEvent(
        getProfileBIEvent(accountId, {
          [EventFields.FROM_MINI_PROFILE]: 0,
        })
      );
      openMiniProfile();
    },
    [accountId, getProfileBIEvent, openMiniProfile]
  );

  const { vipLabel } = useVipInfo({ vipLevel: vipStatus });

  return (
    <div
      className={classnames(styles.row, {
        [styles.mobile]: mobile,
        [styles.myself]: isMyself,
        [styles.compact]: compactCta,
      })}
      data-testid={accountId}
      onClick={onClick}
      onTouchStart={emptyFunction}
    >
      <div className={styles.avatar} data-testid="top-gifters-avatar">
        <Avatar
          className={styles.avatarContainer}
          basicProfile={basicProfile}
          vipLabel={vipLabel}
          avatarImageClassName={styles.noBorder}
        />
      </div>
      <Typography
        type={TYPOGRAPHY_TYPE.PARAGRAPH2}
        className={classnames(styles.name, viewer && styles.viewerName)}
        data-testid="name"
        as="div"
      >
        <DisplayName basicProfile={basicProfile} />
      </Typography>
      {!viewer && (
        <Typography
          type={TYPOGRAPHY_TYPE.HEADLINE6}
          className={styles.coin}
          data-testid="gifted-coins-number"
        >
          <img src={coinIcon} alt="" />
          <FormattedNumber value={creditsInStream} />
        </Typography>
      )}
      <FollowCTA
        accountId={accountId}
        source={FollowSource.STREAM_GIFTER_LIST}
        registrationSource={RegistrationSource.FOLLOW_FROM_GIFTERS}
        className={styles.follow}
        size={ButtonSize.CIRCLE_SMALL_32}
        isShaderTheme
      />
    </div>
  );
};

TopGiftersListItem.propTypes = {
  accountId: PropTypes.string.isRequired,
  compactCta: PropTypes.bool,
  mobile: PropTypes.bool,
  viewer: PropTypes.bool,
};

export default TopGiftersListItem;
