import React, { memo } from "react";
import { TYPOGRAPHY_TYPE } from "ui/common/typography/types";
import { defineMessage, FormattedMessage } from "react-intl";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography from "ui/common/typography/Typography";
import styles from "./StreamsHeader.scss";

const message = defineMessage({
  id: "top-streamers.title",
  defaultMessage: "Top Artists",
});

interface StreamsHeaderProps {
  isFollowingMode: boolean;
}

const StreamsHeader: React.FC<StreamsHeaderProps> = ({ isFollowingMode }) => (
  <Typography type={TYPOGRAPHY_TYPE.HEADLINE1} className={styles.root}>
    <FormattedMessage
      {...(isFollowingMode ? sharedMessages.youFollow : message)}
    />
    {isFollowingMode && (
      <Typography type={TYPOGRAPHY_TYPE.MICRO} className={styles.live}>
        <FormattedMessage {...sharedMessages.live} />
      </Typography>
    )}
  </Typography>
);

export default memo(StreamsHeader);
