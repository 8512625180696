import React, { SyntheticEvent, memo, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { EventFields, RegistrationSource } from "@analytics/enums";
import tangoAnalytics from "src/core/analytics";
import {
  EventCategory,
  EventDenominations,
  EventNames,
  EventValues,
  EventFields as TangoEventFields,
} from "src/core/analytics/enums";
import {
  FollowSource,
  LoginModalTitleType,
  LoginPromotionType,
} from "src/enums";
import { openLoginView } from "src/features/signin/exports/state/flows";
import { ReactComponent as FollowIcon } from "src/img/add_32.svg";
import { guestCanFollowSelector } from "state/abTests";
import { RootState } from "state/delegate";
import { follow, unfollow } from "state/flows/followingList";
import {
  blockedUsersSelectors,
  followingListSelectors,
  loginSelectors,
  profilesCacheSelectors,
  userSelectors,
} from "state/selectors";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import sharedMessages from "ui/common/intl/sharedMessages";
import useUiAction from "ui/hooks/useUiAction";
import { ReactComponent as CheckIcon } from "img/ic_followed.svg";

interface useFollowButtonStateAndHandlerProps {
  accountId: string;
  extendedTarget?: string;
  isUnfollowAvailable?: boolean;
  registrationSource: RegistrationSource;
  source: FollowSource;
  vipStatus?: number;
}

export const useFollowButtonStateAndHandler = ({
  accountId,
  registrationSource,
  extendedTarget,
  source,
  isUnfollowAvailable = false,
}: useFollowButtonStateAndHandlerProps) => {
  const state = useSelector(
    useCallback(
      (state: RootState) => ({
        isLoggedIn: loginSelectors.isLoggedIn(state),
        isMe:
          loginSelectors.isLoggedIn(state) &&
          userSelectors.getMyAccountId(state) === accountId,
        isFollowed: followingListSelectors.getIsFollowingByAccountId(
          state,
          accountId
        ),
        basicProfile: profilesCacheSelectors.getBasicProfile(state, accountId),
        guestCanFollow: guestCanFollowSelector(state),
        isBlocked: blockedUsersSelectors.isBlockedUser(state, accountId),
      }),
      [accountId]
    ),
    shallowEqual
  );
  const { isLoggedIn, guestCanFollow, isMe, isFollowed, isBlocked } = state;
  const dispatch = useDispatch();
  const callback = useCallback(
    (event) => {
      event && event.stopPropagation();
      if (isMe || isBlocked) {
        return;
      }
      if (!isLoggedIn && !guestCanFollow && !isFollowed) {
        dispatch(
          openLoginView({
            promotionType: LoginPromotionType.FOLLOW,
            accountId,
            registrationSource,
            title: LoginModalTitleType.DO_MORE_WITH_TANGO,
          })
        );

        return;
      }
      if (!isFollowed) {
        dispatch(follow(accountId, source));
      } else if (isUnfollowAvailable) {
        dispatch(unfollow(accountId, source));
      }
    },
    [
      isMe,
      isBlocked,
      isLoggedIn,
      guestCanFollow,
      isFollowed,
      isUnfollowAvailable,
      accountId,
      registrationSource,
      source,
    ]
  );
  const onClick = useUiAction({
    target: extendedTarget || (isFollowed ? "unfollow" : "follow"),
    callback,
    additionalParams: {
      [EventFields.PEER_ID]: accountId,
    },
  });

  const handleClick = (event: SyntheticEvent) => {
    tangoAnalytics.then((analytics) => {
      analytics.track(EventNames.EVENT, {
        [TangoEventFields.EVENT_NAME]: EventDenominations.FOLLOW_STREAMER,
        [TangoEventFields.EVENT_CATEGORY]: EventCategory.STREAM_EVENTS,
        [TangoEventFields.EVENT_DETAIL]: EventValues.NA,
      });
    });

    return onClick(event);
  };

  return { followCtaState: state, onClick: handleClick };
};

interface FollowCTAProps {
  accountId: string;
  className?: string;
  extendedTarget?: string;
  isSecondary?: boolean;
  isShaderTheme?: boolean;
  isUnfollowAvailable?: boolean;
  registrationSource: number;
  size?:
    | ButtonSize.BIG_48
    | ButtonSize.CIRCLE_BIG_48
    | ButtonSize.CIRCLE_MEDIUM_40
    | ButtonSize.CIRCLE_SMALL_32
    | ButtonSize.MEDIUM_32;
  source: number;
}

const FollowCTA = ({
  accountId,
  source,
  registrationSource,
  size = ButtonSize.BIG_48,
  className,
  extendedTarget = "",
  isShaderTheme = false,
  isUnfollowAvailable = false,
  isSecondary = false,
}: FollowCTAProps) => {
  const { followCtaState, onClick } = useFollowButtonStateAndHandler({
    accountId,
    source,
    registrationSource,
    extendedTarget,
    isUnfollowAvailable,
  });
  const { isMe, isFollowed, isBlocked } = followCtaState;

  const buttonConfig = useMemo(() => {
    const Icon = isFollowed ? CheckIcon : FollowIcon;

    let variant = ButtonVariant.PRIMARY;

    if (isShaderTheme) {
      variant = ButtonVariant.PRIMARY_ACCENT;
    }

    if ((isUnfollowAvailable && isFollowed) || isSecondary) {
      variant = ButtonVariant.SECONDARY;
    }

    switch (size) {
      case ButtonSize.CIRCLE_BIG_48:
      case ButtonSize.CIRCLE_MEDIUM_40:
      case ButtonSize.CIRCLE_SMALL_32: {
        return {
          variant,
          size,
          children: <Icon />,
        } as const;
      }
      case ButtonSize.MEDIUM_32:
      case ButtonSize.BIG_48: {
        return {
          variant,
          leftIcon: isUnfollowAvailable ? undefined : Icon,
          size,
          children: isFollowed ? (
            <>
              {isUnfollowAvailable ? (
                <FormattedMessage
                  id="modal.unfollow.title"
                  defaultMessage="Unfollow"
                />
              ) : (
                <FormattedMessage {...sharedMessages.following} />
              )}
            </>
          ) : (
            <FormattedMessage id="follow.cta.follow" defaultMessage="Follow" />
          ),
        } as const;
      }
    }
  }, [isShaderTheme, isSecondary, size, isFollowed, isUnfollowAvailable]);

  if (isMe || isBlocked) {
    return null;
  }

  const dataTestId = isFollowed ? "unfollow" : "follow";

  return (
    <Button
      {...buttonConfig}
      onClick={onClick}
      data-testid={dataTestId}
      className={className}
      active={isFollowed && !isUnfollowAvailable}
    />
  );
};

export default memo(FollowCTA);
