import React, { FC, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { emitUiAction } from "@analytics/emitUiAction";
import { EventFields, RegistrationSource } from "@analytics/enums";
import {
  GET_THE_APP_SOURCE_BOTTOM_SCREEN_TYPE,
  GET_THE_APP_SOURCE_TO_LOGIN_MODAL_TITLE_TYPE,
  GetTheAppSource,
  GetTheAppTarget,
} from "@analytics/getTheApp";
import ScreenViewReportingContext from "@analytics/screenView/ScreenViewReportingContext";
import { NEW_SIGN_UP_PROMOTION_TYPE_OTHER } from "enums/newSignUpPromotionType";
import { openLoginView } from "src/features/signin/exports/state/flows";
import { VoidCallback } from "src/types/common";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import messages from "ui/common/intl/sharedMessages";
import { ReactComponent as SignInIcon } from "img/login/login_32.svg";

interface LoginProps {
  onClick: VoidCallback;
}

const Login: FC<LoginProps> = ({ onClick }) => {
  const dispatch = useDispatch();
  const screenSource = GetTheAppSource.BURGER_MENU;
  const { getStack } = useContext(ScreenViewReportingContext);

  const onLoginClick = useCallback(() => {
    const [screen]: { name: string }[] = getStack();

    emitUiAction({
      target: GetTheAppTarget.LOG_IN,
      tango_screen: {
        reportedName: screen?.name,
      },
      additionalParams: {
        [EventFields.SOURCE]: GetTheAppSource.BURGER_MENU,
      },
    });

    dispatch(
      openLoginView({
        registrationSource: RegistrationSource.SELF_PROFILE_ICON,
        newSignUpPromotionType: NEW_SIGN_UP_PROMOTION_TYPE_OTHER,
        title: GET_THE_APP_SOURCE_TO_LOGIN_MODAL_TITLE_TYPE[screenSource],
        screenType: GET_THE_APP_SOURCE_BOTTOM_SCREEN_TYPE[screenSource],
      })
    );
    onClick();
  }, [getStack, dispatch, screenSource, onClick]);

  return (
    <DropdownListItem
      titleMessage={messages.login}
      Icon={SignInIcon}
      onClick={onLoginClick}
      data-testid="login"
    />
  );
};

export default Login;
