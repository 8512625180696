import React, { memo } from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Breakpoints, ModalType } from "src/enums";
import { ComponentWithClassName } from "src/types/common";
import { modalSelectors } from "state/selectors";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { ReactComponent as NoPostsIcon } from "img/no_posts.svg";
import styles from "./NoContent.scss";

interface NoContentProps {
  type?: "cards" | "moments" | "posts";
}

const messages = defineMessages({
  posts: {
    id: "no.posts",
    defaultMessage: "No Posts",
  },
  cards: {
    id: "no.cards",
    defaultMessage: "No Cards",
  },
  moments: {
    id: "no.moments",
    defaultMessage: "No Moments",
  },
});

const NoContent: ComponentWithClassName<NoContentProps> = ({
  className,
  type = "posts",
}) => {
  const breakpoint = useBreakpointPrecise();
  const topModalType = useSelector(modalSelectors.topModalType);

  return (
    <div className={classnames(styles.root, styles[breakpoint], className)}>
      <NoPostsIcon className={styles.icon} />
      <Typography
        type={
          breakpoint === Breakpoints.MOBILE ||
          breakpoint === Breakpoints.SMALL_MOBILE ||
          topModalType === ModalType.MINI_PROFILE_MODAL
            ? TYPOGRAPHY_TYPE.PARAGRAPH3
            : TYPOGRAPHY_TYPE.PARAGRAPH2
        }
      >
        <FormattedMessage {...messages[type]} />
      </Typography>
    </div>
  );
};

export default memo(NoContent);
