import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { FormattedNumber } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { SUBSCRIBE_TARGET } from "@analytics/enums";
import { SubscriptionStatus } from "src/enums";
import { VoidCallback } from "src/types/common";
import { RootState } from "state/delegate";
import {
  settingsSelectors,
  subscriptionPerStreamerListSelectors,
} from "state/selectors";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import sharedMessages from "ui/common/intl/sharedMessages";
import useUiAction from "ui/hooks/useUiAction";
import { linkToSubscribers } from "ui/navigation/links";
import { ReactComponent as SubscribersIcon } from "img/user_menu_icons/subscribers.svg";
import styles from "./common.scss";

const selector = (state: RootState) =>
  settingsSelectors.getPreference(
    state,
    SubscriptionStatus.MY_SUBSCRIBERS_LAST_VISIT
  );

interface MyFansItemProps {
  onClick?: VoidCallback;
}

const MyFansItem: FC<MyFansItemProps> = ({ onClick }) => {
  const [newAmount, setNewAmount] = useState(0);
  const subscribersCount = useSelector(
    subscriptionPerStreamerListSelectors.getUserSubscribersIds,
    shallowEqual
  ).length;
  const lastVisited = useSelector(selector);
  const subscribersMap = useSelector(
    subscriptionPerStreamerListSelectors.getUserSubscribersMap,
    shallowEqual
  );
  const reportGoSubscribersUiAction = useUiAction({
    target: SUBSCRIBE_TARGET.SUBSCRIBERS_TARGET,
    callback: onClick,
  });

  const newSubscribers = useMemo(() => {
    if (!subscribersMap) return 0;
    const subscribers = Object.values(subscribersMap);
    return subscribers.filter((item) => !!item && item.startedAt > lastVisited)
      .length;
  }, [lastVisited, subscribersMap]);

  useEffect(() => {
    setNewAmount(newSubscribers);
  }, [newSubscribers]);

  return (
    <DropdownListItem
      to={linkToSubscribers}
      data-testid="subscribers"
      onClick={reportGoSubscribersUiAction}
      Icon={SubscribersIcon}
      titleMessage={sharedMessages.subscribers}
      isIconBadgeVisible={newAmount > 0}
    >
      {subscribersCount > 0 && (
        <span className={styles.count}>
          <FormattedNumber value={subscribersCount} />
        </span>
      )}
    </DropdownListItem>
  );
};

export default memo(MyFansItem);
