import React from "react";
import classnames from "classnames";
import { ComponentWithClassName } from "src/types/common";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import styles from "./BadgeBase.scss";

export interface BadgeBaseProps {
  withBorder?: boolean;
}

export const BadgeBase: ComponentWithClassName<BadgeBaseProps> = ({
  children,
  className,
  withBorder,
  ...rest
}) => (
  <Typography
    {...rest}
    as="div"
    className={classnames(className, styles.root, {
      [styles.withBorder]: !!withBorder,
    })}
    type={TYPOGRAPHY_TYPE.MINI}
  >
    {children}
  </Typography>
);
