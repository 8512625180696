import React, { memo, useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { EventFields } from "@analytics/enums";
import { Breakpoints } from "src/enums";
import { BannerConfig } from "src/types/banner";
import { ComponentWithClassName, WithClasses } from "src/types/common";
import ConfiguredBanner, {
  ConfiguredBannerClasses,
} from "ui/common/banner/ConfiguredBanner";
import CarouselButton from "ui/common/buttons/CarouselButton";
import styles from "./BannersCarousel.scss";

export const Skeleton = memo<{
  breakpoint: Breakpoints;
  className?: string;
  viewportClassName?: string;
}>(({ breakpoint, className, viewportClassName }) => (
  <div className={classnames(styles.root, styles[breakpoint], className)}>
    <div className={styles.overlay} />
    <div className={classnames(styles.viewport, viewportClassName)}>
      <div className={styles.container}>
        <div className={styles.slide}>
          <div className={styles.placeholder} />
        </div>
        <div className={styles.slide}>
          <div className={styles.placeholder} />
        </div>
        {breakpoint === Breakpoints.DESKTOP && (
          <div className={styles.slide}>
            <div className={styles.placeholder} />
          </div>
        )}
      </div>
    </div>
  </div>
));

Skeleton.displayName = "BannersSkeleton";

export interface BannersCarouselClasses {
  ConfiguredBanner: ConfiguredBannerClasses;
  btnNext?: string;
  btnPrev?: string;
  singleSlide: string;
  skeletonRoot: string;
  skeletonViewport: string;
  viewport?: string;
}

interface BannersCarouselProps {
  breakpoint: Breakpoints;
  data: BannerConfig[];
}

const BannersCarousel: ComponentWithClassName<
  WithClasses<BannersCarouselProps, BannersCarouselClasses>
> = ({ className, data, breakpoint, classes }) => {
  const isMobile = breakpoint !== Breakpoints.DESKTOP;

  const [viewportRef, embla] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    duration: 22,
    watchDrag: isMobile,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  useEffect(() => {
    if (!embla) {
      return;
    }

    const onSelect = () => {
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    };

    embla.on("select", onSelect);

    return () => {
      embla.off("select", onSelect);
    };
  }, [embla]);

  useEffect(() => {
    if (embla) {
      embla.reInit();

      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    }
  }, [data, embla]);

  return (
    <div
      className={classnames(styles.root, styles[breakpoint], className)}
      data-testid="banners-carousel-root"
    >
      <div
        className={classnames(styles.viewport, classes?.viewport)}
        ref={viewportRef}
      >
        <div className={styles.container}>
          {data.map((bannerConfig, index) => (
            <div
              className={classnames(styles.slide, {
                [classes?.singleSlide || ""]: data.length === 1,
              })}
              key={bannerConfig.id}
            >
              <ConfiguredBanner
                bannerConfig={bannerConfig}
                classes={classes?.ConfiguredBanner}
                additionalAnalyticsParams={{
                  [EventFields.POSITION]: index + 1,
                  [EventFields.SIZE]: data.length === 1 ? null : data.length,
                }}
              />
            </div>
          ))}
        </div>
      </div>
      {!isMobile && (
        <>
          {prevBtnEnabled && (
            <CarouselButton
              type="prev"
              onClick={scrollPrev}
              className={classnames(styles.btnPrev, classes?.btnPrev)}
            />
          )}
          {nextBtnEnabled && (
            <CarouselButton
              type="next"
              onClick={scrollNext}
              className={classnames(styles.btnNext, classes?.btnNext)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default memo(BannersCarousel);
