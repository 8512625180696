import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./GenericSummary.scss";
import Spinner from "../Spinner";

const GenericSummary = ({
  buttons,
  title,
  Icon,
  showSpinner,
  iconClassName,
  buttonsClassName,
  titleClassName,
  isDark = true,
  className,
  ...rest
}) => (
  <div
    className={classnames(styles.root, !isDark && styles.light, className)}
    {...rest}
  >
    {showSpinner && <Spinner className={styles.spinner} />}
    {Boolean(Icon) && (
      <Icon className={classnames(styles.icon, iconClassName)} />
    )}
    {Boolean(title) && (
      <div className={classnames(styles.title, titleClassName)}>{title}</div>
    )}
    {Boolean(buttons) && (
      <div className={classnames(styles.buttonsContainer, buttonsClassName)}>
        {buttons}
      </div>
    )}
  </div>
);

GenericSummary.propTypes = {
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  Icon: PropTypes.elementType,
  showSpinner: PropTypes.bool,
  iconClassName: PropTypes.string,
  buttonsClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  className: PropTypes.string,
  isDark: PropTypes.bool,
};

export default GenericSummary;
