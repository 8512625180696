import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { ComponentWithClassName } from "src/types/common";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import { ReactComponent as FollowingIcon } from "img/emptyState/following_64.svg";
import styles from "./NoOneLiveBanner.scss";

const NoOneLiveBanner: ComponentWithClassName = ({ className }) => (
  <div className={classnames(styles.root, styles[useBreakpoint()], className)}>
    <FollowingIcon className={styles.icon} />
    <div className={styles.text}>
      <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3}>
        <FormattedMessage
          id="banner.follow.caption"
          defaultMessage="No one you’re following is Live"
        />
      </Typography>
    </div>
  </div>
);

export default NoOneLiveBanner;
