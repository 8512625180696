import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import {
  emitPlayError,
  emitPlayLoop,
  emitPlayReady,
  emitPlayStart,
  emitPlayStop,
} from "@analytics/happyMoments/happyMomentsEvents";
import { SECOND } from "src/constants";
import { Nullable } from "src/types/common";
import { Story, StoryType } from "src/types/happyMoments";
import { useUnmount } from "src/utils/miniReactUse";
import { resetGiftAnimation } from "state/actionCreators/stories";
import useCallbackRef from "ui/hooks/useCallbackRef";
import MuteContext from "ui/scenes/stream/MuteContext";
import { useOnPlaybackStartError } from "ui/scenes/stream/streamPlayer/useOnPlaybackStartError";
import styles from "./MomentPlayer.scss";

interface MomentPlayerProps {
  className?: string;
  moment: Story;
  onPlaying?: (id: string) => void;
  shouldEmitPlayLoop?: boolean;
  type: StoryType;
}

const MomentPlayer: React.FC<MomentPlayerProps> = ({
  moment,
  className,
  onPlaying,
  shouldEmitPlayLoop = true,
  type,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const timeoutRef = useRef<Nullable<number>>(null);
  const statsRefs = useRef({
    playingCallbackCounterRef: 0,
    videoDurationRef: 0,
    playbackStartTsRef: 0,
    currentTimeRef: 0,
  }).current;
  const [isVideoReady, setVideoIsReady] = useState(false);
  const { muted, setMuted } = useContext(MuteContext);
  const dispatch = useDispatch();
  const onPlaybackStartError = useCallbackRef(
    useOnPlaybackStartError(muted, setMuted)
  ).current;

  const { videoLink, storyId, watched, streamerId } = moment;

  useLayoutEffect(() => {
    if (!videoLink) {
      statsRefs.playingCallbackCounterRef = 0;
      statsRefs.videoDurationRef = 0;
      statsRefs.playbackStartTsRef = 0;
      statsRefs.currentTimeRef = 0;

      return;
    }
    emitPlayStart({
      momentId: storyId,
      watched,
      streamerId,
      type,
    });

    return () => {
      const duration = statsRefs.playbackStartTsRef
        ? Date.now() - statsRefs.playbackStartTsRef
        : 0;
      emitPlayStop({
        momentId: storyId,
        videoDuration: Math.floor(statsRefs.videoDurationRef),
        duration: Math.floor(duration / SECOND),
        streamerId,
        type,
      });
    };
  }, [storyId, videoLink, streamerId, watched, type]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(() => {
        emitPlayError({ momentId: storyId });
        onPlaybackStartError(videoRef.current);
      });
    }
  }, [storyId]);

  const handlePlaying = useCallback(
    (e) => {
      if (!isVideoReady && !timeoutRef.current) {
        timeoutRef.current = window.setTimeout(() => {
          setVideoIsReady(true);
        }, 250);
      }
      statsRefs.videoDurationRef = e.target.duration;
      if (statsRefs.playingCallbackCounterRef === 0) {
        statsRefs.playbackStartTsRef = Date.now();
        emitPlayReady({
          momentId: storyId,
          videoWidth: e.target.videoWidth,
          videoHeight: e.target.videoHeight,
        });
        !watched && onPlaying?.(storyId);
      }
      statsRefs.playingCallbackCounterRef =
        statsRefs.playingCallbackCounterRef + 1;
    },
    [storyId, watched, onPlaying, isVideoReady]
  );

  const onTimeUpdate = useCallback(
    (e) => {
      const currentTime = e.target.currentTime;
      if (currentTime < statsRefs.currentTimeRef && shouldEmitPlayLoop) {
        emitPlayLoop({ momentId: storyId });
        dispatch(resetGiftAnimation(true));
      }
      statsRefs.currentTimeRef = currentTime;
    },
    [storyId, shouldEmitPlayLoop]
  );

  useUnmount(() => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
  });

  return (
    <video
      ref={videoRef}
      className={classnames(
        styles.root,
        isVideoReady && styles.visible,
        className
      )}
      src={videoLink}
      controls={false}
      onPlaying={handlePlaying}
      playsInline
      loop
      muted={muted}
      autoPlay
      onTimeUpdate={onTimeUpdate}
      disablePictureInPicture
      data-testid={`moment-${storyId}`}
    />
  );
};

export default memo(MomentPlayer);
