import React, { ReactNode, memo } from "react";
import { ComponentWithClassName } from "src/types/common";
import Skeleton, { SkeletonProps } from "ui/common/skeleton/Skeleton";

interface SkeletonWrapperProps extends SkeletonProps {
  children?: ReactNode;
  loading?: boolean;
}

const SkeletonWrapper: ComponentWithClassName<SkeletonWrapperProps> = ({
  className,
  loading,
  width,
  height,
  style,
  variant = "rect",
  children,
}) =>
  loading ? (
    <Skeleton
      className={className}
      width={width}
      height={height}
      style={style}
      variant={variant}
    >
      {children}
    </Skeleton>
  ) : (
    <>{children}</>
  );

export default memo(SkeletonWrapper);
