import React from "react";
import { PURCHASE_SOURCE } from "@analytics/enums";
import { PaymentProvider } from "src/types/payment";
import WebviewOffersView from "./WebviewOffersView";

const WebviewSafechargeOffers: React.FC = () => (
  <WebviewOffersView
    suggestedProvider={PaymentProvider.SAFECHARGE}
    purchaseSource={PURCHASE_SOURCE.WEBVIEW_SAFECHARGE}
  />
);

export default WebviewSafechargeOffers;
