import React, { FC } from "react";
import { Watermark as WatermarkType } from "ui/modal/modalViews/buyCoins/offers/enums";
import { OfferCardSizes } from "ui/common/personalOffer/enums";
import RegularHotWatermark from "ui/common/personalOffer/Watermark/views/RegularHotWatermark";
import RegularPopularWatermark from "ui/common/personalOffer/Watermark/views/RegularPopularWatermark";

export interface WatermarkComponentProps {
  size: OfferCardSizes;
  className?: string;
}

export interface WatermarkProps {
  type: WatermarkType;
  size: OfferCardSizes;
  className?: string;
}

const COMPONENTS_MAP: Record<WatermarkType, FC<WatermarkComponentProps>> = {
  [WatermarkType.TOP]: RegularHotWatermark,
  [WatermarkType.POPULAR]: RegularPopularWatermark,
};

const Watermark: FC<WatermarkProps> = ({ type, size, className }) => {
  const Component = COMPONENTS_MAP[type];

  if (!Component) {
    return null;
  }

  return <Component size={size} className={className} />;
};

export default Watermark;
