import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AvatarSize } from "src/enums";
import BasicProfileShape from "state/shapes/BasicProfileShape";
import DisplayName from "ui/common/DisplayName";
import { RoundedNumber } from "ui/common/Formatted";
import Avatar from "ui/common/avatar/Avatar";
import { ButtonSize } from "ui/common/button/types";
import FollowCTA from "ui/common/buttons/FollowCTA";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { makeLinkToProfile } from "ui/navigation/links";
import { ReactComponent as DiamondIcon16 } from "img/ic_diamond_16.svg";
import { ReactComponent as UsersIcon16 } from "img/ic_users_16.svg";
import styles from "./ProfileTile.scss";

const ProfileTile = ({
  accountId,
  basicProfile,
  stats,
  followSource,
  registrationSource,
}) => (
  <div className={styles.root} data-testid={`search-result-item-${accountId}`}>
    <Link
      to={makeLinkToProfile(accountId, basicProfile)}
      className={styles.link}
    >
      <div className={styles.avatar}>
        <Avatar size={AvatarSize.LARGE} basicProfile={basicProfile} />
      </div>
      <Typography
        type={TYPOGRAPHY_TYPE.HEADLINE4}
        className={styles.name}
        as="div"
      >
        <DisplayName basicProfile={basicProfile} showVerified />
      </Typography>
    </Link>
    <Typography type={TYPOGRAPHY_TYPE.MINI} className={styles.stats} as="div">
      <span className={styles.stat} data-testid="points">
        <DiamondIcon16 className={styles.icon} />
        <RoundedNumber value={stats.live ? stats.live.points : 0} />
      </span>
      <span className={styles.stat} data-testid="followers">
        <UsersIcon16 className={styles.icon} />
        <RoundedNumber value={stats.follow ? stats.follow.followersCount : 0} />
      </span>
    </Typography>
    <FollowCTA
      className={styles.follow}
      accountId={accountId}
      source={followSource}
      registrationSource={registrationSource}
      size={ButtonSize.MEDIUM_32}
      isUnfollowAvailable
    />
  </div>
);

ProfileTile.propTypes = {
  accountId: PropTypes.string.isRequired,
  basicProfile: BasicProfileShape,
  followSource: PropTypes.number.isRequired,
  registrationSource: PropTypes.number,
  stats: PropTypes.shape({
    live: PropTypes.shape({
      points: PropTypes.number,
      vipStatus: PropTypes.number,
    }),
    follow: PropTypes.shape({
      followingCount: PropTypes.number,
      followersCount: PropTypes.number,
    }),
  }).isRequired,
};

export default ProfileTile;
