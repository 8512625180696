import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { getCurrenciesWithSeparateTaxFromAmount } from "environment";
import { EventFields as TangoEventFields } from "src/core/analytics/enums";
import { analyticsPersonalOfferEcommerce } from "src/core/analytics/utils/analyticsPersonalOffers";
import { CurrencyPricePoint } from "src/enums";
import { ComponentWithClassName } from "src/types/common";
import FormattedPrice from "ui/common/intl/FormattedPrice";
import useTaxPrice from "ui/common/tax/useTaxPrice";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { ShopOffer } from "ui/scenes/landingPage/types";
import { ReactComponent as AttentionIcon } from "img/ic-attention.svg";
import styles from "./Tax.scss";

type TaxPropsType = {
  marketOfferId: ShopOffer["marketOfferId"];
  priceParams: CurrencyPricePoint;
  v2?: boolean;
};
const Tax: ComponentWithClassName<TaxPropsType> = ({
  className,
  marketOfferId,
  v2,
  priceParams,
}) => {
  const [taxPrice] = useTaxPrice({
    priceParams,
    marketOfferId,
    v2,
  });

  useEffect(() => {
    analyticsPersonalOfferEcommerce({
      [TangoEventFields.TAX]: Number(taxPrice.toFixed(2)),
    });
  }, [taxPrice]);

  const isTaxIncluded = useMemo(
    () =>
      !getCurrenciesWithSeparateTaxFromAmount().includes(
        priceParams.currency.toLowerCase()
      ),
    [priceParams.currency]
  );

  const [isShowTax, setIsShowTax] = useState(isTaxIncluded);
  const onShowTax = useCallback(() => setIsShowTax(true), []);

  if (!taxPrice) {
    return null;
  }

  const renderTaxMessage = () => {
    if (!isShowTax) {
      return (
        <FormattedMessage
          id="wof.tax"
          defaultMessage="Taxes / Fees {icon}"
          values={{
            icon: <AttentionIcon className={styles.icon} />,
          }}
        />
      );
    }

    return isTaxIncluded ? (
      <FormattedMessage
        id="wof.tax.included"
        defaultMessage="Regional Taxes & Fees Included"
      />
    ) : (
      <FormattedMessage
        id="wof.tax.amount"
        defaultMessage="Regional Taxes & Fees: {amount}"
        values={{
          amount: (
            <FormattedPrice value={taxPrice} currency={priceParams.currency} />
          ),
        }}
      />
    );
  };

  return (
    <div
      className={classnames(
        styles.root,
        {
          [styles.hidden]: !isShowTax,
        },
        className
      )}
      onClick={onShowTax}
    >
      <Typography
        type={
          isShowTax ? TYPOGRAPHY_TYPE.PARAGRAPH3 : TYPOGRAPHY_TYPE.HEADLINE5
        }
        className={styles.information}
      >
        {renderTaxMessage()}
      </Typography>
    </div>
  );
};

export default Tax;
