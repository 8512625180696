import React, { memo } from "react";
import classnames from "classnames";
import { ComponentWithClassName } from "src/types/common";
import styles from "./Divider.scss";

const Divider: ComponentWithClassName = ({ className, ...rest }) => (
  <div className={classnames(styles.root, className)} {...rest} />
);

export default memo(Divider);
