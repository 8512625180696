import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { EventFields } from "@analytics/enums";
import { BottomScreenType, Breakpoints, ModalType } from "src/enums";
import { ComponentWithClassName } from "src/types/common";
import { showBottomScreen } from "state/actionCreators/bottomScreen";
import { openModal } from "state/actionCreators/modal";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useUiAction from "ui/hooks/useUiAction";
import BidInfo from "ui/nft/bidInfo/BidInfo";
import CardVideo from "ui/nft/cardVideo/CardVideo";
import { Card } from "ui/nft/types";
import styles from "./Card.scss";

interface CardProps {
  data: Card;
  forceMobileLayout?: boolean;
  onShader?: boolean;
  screenName?: string;
}

const Card: ComponentWithClassName<CardProps> = ({
  className,
  forceMobileLayout,
  onShader,
  data,
  screenName,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();

  const openCardModal = useCallback(() => {
    dispatch(
      breakpoint === Breakpoints.MOBILE ||
        breakpoint === Breakpoints.SMALL_MOBILE
        ? // @ts-ignore
          showBottomScreen({
            screenType: BottomScreenType.NFT_CARD_BOTTOM_SCREEN,
            screenData: data,
            onDismiss: undefined,
            redirectOnDismiss: undefined,
          })
        : openModal({
            modalType: ModalType.NFT_CARD_MODAL,
            modalData: data,
          })
    );
  }, [data, breakpoint]);

  const handleCardClick = useUiAction({
    callback: openCardModal,
    target: "nft_card_full_view",
    additionalParams: {
      [EventFields.ITEM_ID]: data.details.nft.id,
      [EventFields.SCREEN_NAME]: screenName,
    },
  });

  const isMobileLayout =
    breakpoint !== Breakpoints.DESKTOP || forceMobileLayout;

  return (
    <div
      className={classnames(
        styles.root,
        isMobileLayout && styles.mobileLayout,
        onShader && styles.onShader,
        className
      )}
      data-testid="auction-card-root"
    >
      <CardVideo
        className={styles.card}
        media={data.details.nft.videos[0]}
        resolutionRate={0}
        onClick={handleCardClick}
      />
      <BidInfo
        layout="vertical"
        amount={data.amount}
        accountInfo={data.owner}
        onShader={onShader}
        cardId={data.details.nft.id}
        forceMobileLayout={forceMobileLayout}
      />
    </div>
  );
};

export default memo(Card);
