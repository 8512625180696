import React, { FC, memo, useCallback, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { PricePoint, SpecialOfferType } from "src/types/personalOffer";
import { ThemeContext } from "src/ui/Theme";
import { percentageToString } from "src/utils/numberToPercentage";
import { actionCreators } from "state/tree/specialOffers";
import { FormattedNumber } from "ui/common/Formatted";
import PersonalPriceWithDiscount from "ui/common/PersonalPriceWithDiscount";
import { DEFAULT_DISCOUNT_PERCENT } from "ui/common/personalOffer/constants";
import PromoTimer from "ui/common/promoTimer/PromoTimer";
import Typography from "ui/common/typography/Typography";
import { TYPOGRAPHY_TYPE } from "ui/common/typography/types";
import { ReactComponent as Coin } from "img/ic_coin.svg";
import styles from "./SpecialOffer.scss";

interface SpecialOfferProps {
  className?: string;
  disabled?: boolean;
  isCashier?: boolean;
  isMobile?: boolean;
  offer: SpecialOfferType;
  onClick: (offer: PricePoint) => void;
}

const SpecialOffer: FC<SpecialOfferProps> = ({
  isCashier,
  offer,
  onClick,
  disabled,
  isMobile,
  className,
}) => {
  const dispatch = useDispatch();
  const {
    imageUrl,
    offers: [{ coins, bonus, currencyPrice }],
    ts,
    campaignId,
    price,
    purchaseLimit,
  } = offer;

  const { dark } = useContext(ThemeContext);

  const handleClick = useCallback(
    () =>
      onClick({
        ...offer,
        isSpecialOffer: true,
      }),
    [offer, onClick]
  );

  const handleExpire = useCallback(() => {
    dispatch(actionCreators.removeSpecialOffer(campaignId));
  }, [campaignId]);

  useEffect(() => {
    if (purchaseLimit < 1) {
      handleExpire();
    }
  }, [handleExpire, purchaseLimit]);

  return (
    <div
      className={classnames(
        styles.wrapper,
        className,
        disabled && styles.disabled,
        isMobile && styles.mobile
      )}
    >
      <div
        className={classnames(styles.root, {
          [styles.cashier]: isCashier,
          [styles.dark]: dark,
        })}
        onClick={handleClick}
        role="button"
        onTouchStart={emptyFunction}
      >
        <div className={styles.ribbon}>
          <div className={styles.sale}>
            <Typography type={TYPOGRAPHY_TYPE.HEADLINE4}>
              {percentageToString(bonus || 0)}
            </Typography>
            <Typography
              type={TYPOGRAPHY_TYPE.PARAGRAPH5}
              className={styles.off}
            >
              <FormattedMessage
                id="cashier.promo.offer.sale"
                defaultMessage="off"
              />
            </Typography>
          </div>
        </div>
        <PromoTimer
          className={styles.timer}
          ts={ts}
          duration="0"
          freeze="2"
          onEnd={handleExpire}
        />
        <div className={styles.body}>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          />
          <div className={classnames(styles.info, isMobile && styles.mobile)}>
            <div className={styles.credits}>
              <Coin width={30} height={30} />
              <Typography type={TYPOGRAPHY_TYPE.HERO4}>
                <FormattedNumber value={coins || 0} />
              </Typography>
            </div>
            <PersonalPriceWithDiscount
              offer={{
                currencyPrice,
                discountPercent: bonus || DEFAULT_DISCOUNT_PERCENT,
                usdPrice: price,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SpecialOffer);
