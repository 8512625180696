export enum HeroTypographyType {
  HERO1 = "hero_1",
  HERO2 = "hero_2",
  HERO3 = "hero_3",
  HERO4 = "hero_4",
}

export enum HeadlineTypographyType {
  HEADLINE1 = "headline_1",
  HEADLINE2 = "headline_2",
  HEADLINE3 = "headline_3",
  HEADLINE4 = "headline_4",
  HEADLINE5 = "headline_5",
  HEADLINE6 = "headline_6",
  HEADLINE7 = "headline_7",
}

export enum ParagraphTypographyType {
  PARAGRAPH0 = "paragraph_0",
  PARAGRAPH1 = "paragraph_1",
  PARAGRAPH2 = "paragraph_2",
  PARAGRAPH3 = "paragraph_3",
  PARAGRAPH4 = "paragraph_4",
  PARAGRAPH5 = "paragraph_5",
}

export enum CustomTypographyType {
  ALL_CAPS = "all_caps",
  MICRO = "micro",
  MINI = "mini",
}

export enum BangoTypographyType {
  BANGO_HEADLINE5 = "bango_headline_5",
  BANGO_HEADLINE6 = "bango_headline_6",
  BANGO_HERO3 = "bango_hero_3",
  BANGO_HERO4 = "bango_hero_4",
  BANGO_MICRO = "bango_micro",
  BANGO_MINI = "bango_mini",
  BANGO_NANO = "bango_nano",
}

export type AllTypographyTypes =
  | BangoTypographyType
  | CustomTypographyType
  | HeadlineTypographyType
  | HeroTypographyType
  | ParagraphTypographyType;

export type TypographyProps = {
  className?: string;
  italic?: boolean;
  lineThrough?: boolean;
  type: AllTypographyTypes;
  underline?: boolean;
};

export const TYPOGRAPHY_TYPE = {
  ...HeroTypographyType,
  ...HeadlineTypographyType,
  ...ParagraphTypographyType,
  ...CustomTypographyType,
  ...BangoTypographyType,
} as const;
