import React, { memo } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { benefits } from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { AllTypographyTypes } from "ui/common/typography/types";
import { ReactComponent as StarIcon } from "img/ic_star_16.svg";
import styles from "./Benefits.scss";

interface BenefitsProps {
  className?: string;
  typographyType?: AllTypographyTypes;
}

const Benefits: React.FC<BenefitsProps> = ({
  className,
  typographyType = TYPOGRAPHY_TYPE.PARAGRAPH3,
}) => (
  <ul className={classnames(styles.benefits, className)}>
    {benefits.map((benefit) => (
      <li className={styles.benefit} key={benefit.id}>
        <StarIcon width={20} height={20} className={styles.star} />
        <Typography type={typographyType}>
          <FormattedMessage {...benefit} />
        </Typography>
      </li>
    ))}
  </ul>
);

export default memo(Benefits);
