import React, { memo, useMemo } from "react";
import classnames from "classnames";
import type { ComponentWithClassName } from "src/types/common";
import { BannerLiveGridTabType, BannerType } from "src/types/banner";
import { useBreakpointPrecise } from "src/ui/hooks/useBreakpoint";
import BannerByType from "ui/common/banner/BannersByType";
import styles from "./GridBanners.scss";

interface GridBannersProps {
  liveGridTabType?: BannerLiveGridTabType;
  type?: BannerType;
}

const GridBanners: ComponentWithClassName<GridBannersProps> = ({
  className,
  type = BannerType.WEB_BANNER,
  liveGridTabType,
}) => {
  const breakpoint = useBreakpointPrecise();

  const classes = useMemo(
    () => ({
      singleSlide: classnames(styles.singleSlide, styles[breakpoint]),
    }),
    []
  );

  return (
    <BannerByType
      className={className}
      type={type}
      liveGridTabType={liveGridTabType}
      classes={classes}
    />
  );
};

export default memo(GridBanners);
