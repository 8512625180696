import React from "react";
import classnames from "classnames";
import { ComponentWithClassName } from "src/types/common";
import { BadgeBase, BadgeBaseProps } from "ui/common/badge/BadgeBase";
import styles from "./BadgeNew.scss";

const nonTranslatableText = "NEW";

export const BadgeNew: ComponentWithClassName<BadgeBaseProps> = ({
  className,
  ...rest
}) => (
  <BadgeBase {...rest} className={classnames(className, styles.root)}>
    {nonTranslatableText}
  </BadgeBase>
);
