import React, { memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { Breakpoints } from "src/enums";
import { VoidCallback } from "src/types/common";
import { subscriptionPerStreamerListSelectors } from "state/selectors";
import DisplayName from "ui/common/DisplayName";
import { FormattedNumber } from "ui/common/Formatted";
import Avatar from "ui/common/avatar/Avatar";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useMyProfile from "ui/hooks/useMyProfile";
import useVipInfo from "ui/hooks/useVipInfo";
import { makeLinkToProfile } from "ui/navigation/links";
import { ReactComponent as DiamondIcon } from "img/ic_diamond_16.svg";
import { ReactComponent as StarIcon } from "img/ic_star_16.svg";
import { ReactComponent as UsersIcon } from "img/ic_users_16.svg";
import { ReactComponent as ArrowIcon } from "img/new-ui/burger_menu/ic-arrow.svg";
import styles from "./UserHeader.scss";

interface UserHeaderProps {
  className?: string;
  closeDropdown?: VoidCallback;
}

const breakpointToNameTypography = {
  [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.HEADLINE3,
  [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.HEADLINE3,
  [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.HEADLINE2,
  [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.HEADLINE5,
};

const breakpointToInfoTypography = {
  [Breakpoints.SMALL_MOBILE]: TYPOGRAPHY_TYPE.HEADLINE6,
  [Breakpoints.MOBILE]: TYPOGRAPHY_TYPE.HEADLINE6,
  [Breakpoints.TABLET]: TYPOGRAPHY_TYPE.HEADLINE5,
  [Breakpoints.DESKTOP]: TYPOGRAPHY_TYPE.HEADLINE6,
};

const UserHeader: React.FC<UserHeaderProps> = ({
  className,
  closeDropdown,
}) => {
  const { accountId, basicProfile, liveStats, followStats } =
    useMyProfile(true);
  const breakpoint = useBreakpointPrecise();
  const subscribersCount = useSelector(
    subscriptionPerStreamerListSelectors.getUserSubscribersIds,
    shallowEqual
  ).length;
  const { vipClassName, vipLabel } = useVipInfo({
    vipLevel: liveStats?.vipStatus,
  });

  return (
    <Link
      data-testid="userinfo"
      className={classnames(styles.root, styles[breakpoint], className)}
      to={makeLinkToProfile(accountId, basicProfile)}
      onClick={closeDropdown}
      onTouchStart={emptyFunction}
    >
      <div className={classnames(styles.avatarContainer, styles[vipClassName])}>
        <Avatar
          className={styles.avatar}
          basicProfile={basicProfile}
          data-testid="avatar"
          vipLabel={vipLabel}
        />
      </div>
      <div className={styles.container}>
        <Typography
          type={breakpointToNameTypography[breakpoint]}
          className={styles.name}
          data-testid="name"
          as="div"
        >
          <DisplayName basicProfile={basicProfile} showVerified />
        </Typography>
        <Typography
          type={breakpointToInfoTypography[breakpoint]}
          as="div"
          className={styles.info}
        >
          <DiamondIcon className={styles.icon} />
          <FormattedNumber value={liveStats?.points || 0} />
          <UsersIcon className={styles.icon} />
          <FormattedNumber value={followStats?.followersCount || 0} />
          <StarIcon className={styles.icon} />
          <FormattedNumber value={subscribersCount || 0} />
        </Typography>
      </div>
      <ArrowIcon className={styles.arrow} />
    </Link>
  );
};

export default memo(UserHeader);
