import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import type { DeleteAccountReasonOptionConfig } from "ui/common/deleteAccountView/types";
import { ComponentWithClassName } from "src/types/common";
import Checkbox from "ui/common/checkbox/Checkbox";
import { DeleteAccountReasonType } from "ui/common/deleteAccountView/enums";

interface DeleteAccountReasonOptionProps {
  checked: boolean;
  onChange: (type: DeleteAccountReasonType) => void;
  option: DeleteAccountReasonOptionConfig;
}

const DeleteAccountReasonOption: ComponentWithClassName<
  DeleteAccountReasonOptionProps
> = (props) => {
  const { option, checked, className, onChange } = props;

  const { formatMessage } = useIntl();

  const handleChange = useCallback(
    () => onChange(option.type),
    [option.type, onChange]
  );

  return (
    <Checkbox
      key={option.type}
      radio
      checked={checked}
      label={formatMessage(option.message)}
      className={className}
      onChange={handleChange}
    />
  );
};

export default DeleteAccountReasonOption;
