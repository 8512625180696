import React, { FC } from "react";
import {
  IntlProvider,
  FormattedNumber as OriginalFormattedNumber,
} from "react-intl";
import { useSelector } from "react-redux";
import { Nullable } from "src/types/common";
import { deviceInfoSelectors } from "state/selectors";
import numberToRoundedString, {
  RoundedNumberOptions,
} from "ui/utils/numberToRoundedString";

const useGetLocale = (localeOverride: string) => {
  const locale: string = useSelector(deviceInfoSelectors.getDeviceLocale);
  if (locale === "ar") {
    // wrong format date/time for AR locale
    return localeOverride;
  }

  return locale;
};

export const FormattedNumber: typeof OriginalFormattedNumber = ({
  value,
  ...rest
}) => (
  <IntlProvider locale={useGetLocale("ar-EG")}>
    <OriginalFormattedNumber value={value} {...rest} />
  </IntlProvider>
);

export const useNumToRoundedString = (
  number: Nullable<number | undefined>,
  options = {}
) => {
  const locale = useGetLocale("ar-EG");

  return numberToRoundedString(number, { locale, ...options });
};

interface RoundedNumberProps {
  options?: RoundedNumberOptions;
  value: Nullable<number | undefined>;
}

export const RoundedNumber: FC<RoundedNumberProps> = ({
  value,
  options = {} as RoundedNumberOptions,
}) => {
  const roundedString = useNumToRoundedString(value, options);

  return <span>{roundedString}</span>;
};
